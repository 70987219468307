const ExtLogger = {};

const Global = require("./Config/Global");
const Config = require("./Config/Config");
const Object = require("es6-polyfills/lib/polyfills/object");

const SERVICE_URL = "https://www.googleapis.com/bigquery/v2/projects/client-side-events/datasets/DATASET_ID/tables/TABLE_ID/insertAll";
const REFRESH_URL = "https://www.googleapis.com/oauth2/v3/token?client_id=1088527147109-6q1o2vtihn34292pjt4ckhmhck0rk0o7.apps.googleusercontent.com&client_secret=nlZyrcPLg6oEwO9f9Wfn29Wh&refresh_token=1/xzt4kwzE1H7W9VnKB8cAaCx6zb4Es4nKEoqaYHdTD15IgOrJDtdun6zK6XiATCKT&grant_type=refresh_token";

const INSERT_SCHEMA = {
  kind: "bigquery#tableDataInsertAllRequest",
  skipInvalidRows: false,
  ignoreUnknownValues: false,
  rows: [{
    insertId: "",
    json: {},
  }],
};

const VIEWER_EVENTS_SCHEMA = {
  event: "",
  display_id: "",
  viewer_version: "",
  event_details: "",
  ts: 0
};

const APPS_EVENTS_SCHEMA = {
  event: "",
  event_details: "",
  event_value: 0,
  host: "",
  ts: 0,
  user_id: "",
  company_id: ""
};

const PLAYER_CONFIG_SCHEMA = {
  machine_id: "",
  display_id: "",
  os_description: "",
  player_name: "",
  installer_version: "",
  player_version: "",
  java_version: "",
  cache_version: "",
  viewer_version: "",
  browser_name: "",
  browser_version: "",
  width: 0,
  height: 0,
  local_ip: "",
  ts: 0,
  mac: "",
  serial_number: ""
};

const SHARED_SCHEDULE_ERROR_SCHEMA = {
  instance_id: "",
  schedule_id: "",
  details: "",
  error: "",
  viewer_url: "",
  ts: 0
};

let refreshDate = 0;
let refreshToken = "";
let insertSuccess = false;

const _refreshToken = (cb) => {
  if (new Date() - refreshDate < (60 * 60 - 20) * 1000) {
    return cb({});
  }

  const xhr = new XMLHttpRequest();
  xhr.open("POST", REFRESH_URL, true);
  xhr.onloadend = function () {
    let resp = {};

    try {
      resp = JSON.parse(xhr.response);
    } catch (e) {
      window.RiseVision.Viewer.Logger.log("Failed external logger token refresh");
    }

    refreshDate = new Date() || refreshDate;
    refreshToken = resp.access_token || refreshToken;

    cb();
  };
  xhr.send();
};

const _insertData = (serviceUrl, insertObject, consoleMessage, cb) => {
  const insertData = JSON.parse(JSON.stringify(INSERT_SCHEMA));
  insertData.rows[0].insertId = Math.random().toString(36).substr(2).toUpperCase();
  insertData.rows[0].json = insertObject;

  if (consoleMessage && insertData.rows[0]) {
    window.RiseVision.Viewer.Logger.log(consoleMessage, insertData.rows[0]);
  }

  if (Config.DISABLE_BQ_LOGGING) { return cb && cb(true); }

  if (window.enableViewerLogFromPlayer) {
    window.postToPlayer({
      message: "viewer-log",
      insertData: insertObject,
      serviceUrl
    });

    return;
  }

  const _insertWithToken = () => {
    const xhr = new XMLHttpRequest();
    if (cb && typeof cb === "function") {
      xhr.onloadend = _reportResult.bind(null, xhr, cb);
    }

    xhr.open("POST", serviceUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + refreshToken);
    xhr.send(JSON.stringify(insertData));
  };

  _refreshToken(_insertWithToken);
};

const _reportResult = (xhr, cb) => {
  let resp = {};

  try {
    resp = JSON.parse(xhr.response);
  } catch (e) {
    window.RiseVision.Viewer.Logger.log("Failed external logger insert");
  }

  insertSuccess = resp.kind && !resp.insertErrors;
  cb(insertSuccess);
};

const _getSuffix = () => {
  const date = new Date();
  const year = date.getUTCFullYear();
  let month = date.getUTCMonth() + 1;
  let day = date.getUTCDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  return "" + year + month + day;
};

ExtLogger.canInsert = () => {
  return insertSuccess;
};

ExtLogger.logViewerEvent = (eventName, eventDetails, noConsoleLog) => {
  if (!eventName) { return; }
  if (Config.DISABLE_VIEWER_LEGACY_LOGGING) { return; }
  const displayId = window.RiseVision.Viewer.ConfigurationFactory.getDisplayId();
  const version = Global.VIEWER_VERSION;
  const consoleMessage = noConsoleLog ? undefined : "Log Viewer Event";

  const insertObject = JSON.parse(JSON.stringify(VIEWER_EVENTS_SCHEMA));
  const serviceUrl = SERVICE_URL
    .replace("DATASET_ID", "Viewer_Events")
    .replace("TABLE_ID", "events" + _getSuffix());

  insertObject.event = eventName;
  insertObject.display_id = displayId;
  insertObject.viewer_version = version;
  if (eventDetails) {
    insertObject.event_details = eventDetails;
  }
  insertObject.ts = new Date().toISOString();

  _insertData(serviceUrl, insertObject, consoleMessage);
};

ExtLogger.logAppsEvent = (eventName) => {
  if (!eventName) { return; }

  const displayId = window.RiseVision.Viewer.ConfigurationFactory.getDisplayId();
  const companyId = window.RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId();

  const insertObject = JSON.parse(JSON.stringify(APPS_EVENTS_SCHEMA));
  const serviceUrl = SERVICE_URL
    .replace("DATASET_ID", "Apps_Events")
    .replace("TABLE_ID", "apps_events" + _getSuffix());

  insertObject.event = eventName;
  insertObject.event_details = displayId || "";
  insertObject.company_id = companyId || "";
  insertObject.ts = new Date().toISOString();

  _insertData(serviceUrl, insertObject, "Log Apps Event");
};

ExtLogger.logPlayerConfigData = (config, cb) => {
  if (!config) { return cb(false, "configuration object was not provided"); }

  const isDisplay = window.RiseVision.Viewer.ConfigurationFactory.isDisplay();
  const isWebPlayer = window.RiseVision.Viewer.ConfigurationFactory.isWebPlayer();
  if (!isDisplay && !isWebPlayer) { return cb(false, "this is not a player type"); }

  const displayId = window.RiseVision.Viewer.ConfigurationFactory.getDisplayId();
  if (!displayId) { return cb(false, "display id not available"); }

  const serviceUrl = SERVICE_URL
    .replace("DATASET_ID", "Player_Data")
    .replace("TABLE_ID", "configuration");

  const insertObject = Object.assign({}, PLAYER_CONFIG_SCHEMA, config, {
    display_id: displayId,
    ts: new Date().toISOString()
  });

  _insertData(serviceUrl, insertObject, "Log Player Config Data", cb);
};

ExtLogger.logSharedScheduleError = (errorMsg, details) => {
  if (Config.DISABLE_VIEWER_LEGACY_LOGGING) { return; }
  const isSharedschedule = window.RiseVision.Viewer.ConfigurationFactory.isSharedschedule();
  if (!isSharedschedule) { return; }

  const instanceId = window.RiseVision.Viewer.Utils.getUniqueId();
  if (!instanceId) { return; }

  const scheduleId = window.RiseVision.Viewer.ConfigurationFactory.getId();
  if (!scheduleId) { return; }

  if (!details) { return; }

  const insertObject = JSON.parse(JSON.stringify(SHARED_SCHEDULE_ERROR_SCHEMA));

  const serviceUrl = SERVICE_URL
    .replace("DATASET_ID", "Shared_Schedule_Events")
    .replace("TABLE_ID", "errors");

  insertObject.instance_id = instanceId;
  insertObject.schedule_id = scheduleId;
  insertObject.details = details;
  insertObject.error = errorMsg;
  insertObject.viewer_url = window.location.href;
  insertObject.ts = new Date().toISOString();

  _insertData(serviceUrl, insertObject, "Log Shared Schedule Error");
};

module.exports = ExtLogger;
