"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.init = void 0;

var _bqHelper = require("./bq-helper.js");

var _token = require("./token.js");

var _logLevel = require("./log-level.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var heartbeatIntervalMS = 300000;
var levels = ["DEBUG", "INFO", "WARNING", "ERROR"];
var mandatoryFields = {
  "INIT": ["endpointId", "endpointType", "endpointUrl", "scheduleId"],
  "DEBUG": ["eventApp", "eventDetails"],
  "INFO": ["eventApp", "eventDetails"],
  "WARNING": ["eventApp", "eventDetails"],
  "ERROR": ["eventApp", "eventErrorCode"],
  "IMPORTANT": ["eventApp", "eventDetails"],
  "HEARTBEAT": ["eventApp", "eventAppVersion"]
};
var gcsFileRequestParameters = ["endpointId", "endpointType", "scheduleId"];

var fieldsError = function fieldsError(type) {
  return "Expected ".concat(type, " parameters ").concat(mandatoryFields[type]);
};

var recordLogType = function recordLogType() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "INFO";
  var config = arguments.length > 1 ? arguments[1] : undefined;

  if (_typeof(config) !== "object") {
    return Promise.reject(Error(fieldsError(type)));
  }

  if (!mandatoryFields[type].every(function (field) {
    return config[field];
  })) {
    return Promise.reject(Error(fieldsError(type)));
  }

  return (0, _logLevel.getLogLevel)().then(function (level) {
    return type === "IMPORTANT" || levels.indexOf(type) >= levels.indexOf(level) ? (0, _bqHelper.streamEventsTableEntry)(_objectSpread({
      eventSeverity: type
    }, config)) : Promise.resolve();
  });
};

var init = function init() {
  var initConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (!mandatoryFields.INIT.every(function (field) {
    return typeof initConfig[field] !== "undefined";
  })) {
    throw Error(fieldsError("INIT"));
  }

  (0, _token.resetToken)();
  (0, _logLevel.setEndpointId)(initConfig.endpointId);
  return {
    getHeartbeatIntervalMS: function getHeartbeatIntervalMS() {
      return heartbeatIntervalMS;
    },
    uptimeHeartbeat: function uptimeHeartbeat() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (_typeof(config) !== "object") {
        return Promise.reject(Error(fieldsError("HEARTBEAT")));
      }

      if (!mandatoryFields.HEARTBEAT.every(function (field) {
        return config[field];
      })) {
        return Promise.reject(Error(fieldsError("HEARTBEAT")));
      }

      return (0, _bqHelper.streamHeartbeatTableEntry)(_objectSpread({
        endpointId: initConfig.endpointId,
        endpointType: initConfig.endpointType,
        scheduleId: initConfig.scheduleId
      }, config));
    },
    info: function info() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return recordLogType("INFO", _objectSpread(_objectSpread({}, initConfig), config));
    },
    error: function error() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return recordLogType("ERROR", _objectSpread(_objectSpread({}, initConfig), config));
    },
    warning: function warning() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return recordLogType("WARNING", _objectSpread(_objectSpread({}, initConfig), config));
    },
    debug: function debug() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return recordLogType("DEBUG", _objectSpread(_objectSpread({}, initConfig), config));
    },
    important: function important() {
      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return recordLogType("IMPORTANT", _objectSpread(_objectSpread({}, initConfig), config));
    },
    getRiseFileRequestParameters: function getRiseFileRequestParameters() {
      return gcsFileRequestParameters;
    },
    getLogLevel: _logLevel.getLogLevel
  };
};

exports.init = init;