/* eslint-disable class-methods-use-this */

class NotificationType {
  constructor(message) {
    this.message = message;
  }

  getMessage() {
    return this.message;
  }
}

NotificationType.NO_DISPLAY_LICENSE = new NotificationType("Missing Display License. Please login to review your account.");
NotificationType.NO_SCHEDULE = new NotificationType("A Schedule has not been assigned to this Display.");
NotificationType.NO_PRESENTATION_SCHEDULED = new NotificationType("A Presentation has not been assigned to this Schedule.");
NotificationType.NO_TEMPLATE_SCHEDULED = new NotificationType("There are no updates to show right now.<br/>Have a great day!");

NotificationType.SCREEN_SHARING_LOBBY = new NotificationType("Join the Room");
NotificationType.SCREEN_SHARING_PAUSED = new NotificationType("The stream is paused");

module.exports = NotificationType;
