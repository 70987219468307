const BrowserCompatibilityService = require("./BrowserCompatibilityService");
const FloatingNotificationsController = require("./UI/FloatingNotificationsController");

const errorCode = "E000000217";
const UI_SHOWTIME_MS = 10000;
const NET_TIMEOUT_MS = 15000;
const RETRY_DELAY_MS = 50;
const siteList = [
  "https://widgets.risevision.com/widget-image/0.1.1/dist/widget.html",
  "https://services.risevision.com/messaging/ping",
  "https://storage.googleapis.com/install-versions.risevision.com/display-modules-manifest.json",
  "https://www.googleapis.com/storage/v1/b/install-versions.risevision.com/o/installer-win-64.exe?fields=kind",
  "https://services2.risevision.com/proxy/",
  "https://services2.risevision.com/feedparser/"
];

const failedSites = [];
let forcedFailure = false;

const logger = require("./Logger");

function isForcedFailure() {
  const forceFail = "forcenetworkfailure=true";

  return location && location.href && location.href.includes(forceFail);
}

let result = null;
let timedOut = false;
let checksCompleted = false;

function domainAndPort(url) {
  const urlObj = new URL(url);
  const host = urlObj.host || urlObj.hostname;
  const port = urlObj.protocol === "https:" ? "443" : "80";

  return `${host} - port: ${port}`;
}

function getNotificationMarkup() {
  const items = failedSites.map( domainAndPort );

  return `
    <p>Content might not show because the following URLs cannot be reached</p>
    <ul>
      ${ items.map( item => `<li>${ item }</li>` ).join('')  }
    </ul>
  `;
}

function checkSite(site, retries = 2) {
  return fetch(site).then(resp=>{
    return resp.ok && !forcedFailure ? "" : Promise.reject(Error(site))
  })
  .catch(err=>{
    if (retries > 0) {
      logger.viewerInfo(`Network check for ${site} failed with ${err.message} - retrying`);

      return new Promise(res=>setTimeout(res, RETRY_DELAY_MS))
      .then(()=>checkSite(site, retries - 1))
    }

    logger.viewerError(errorCode, "network connection error", JSON.stringify({site, error: err.stack || err.message}));
    failedSites.push(site);
  });
}

function checkSites() {
  const checks = Promise.all(siteList.map(site=>checkSite(site)))
  .then(()=>{
    checksCompleted = true;
    if (failedSites.length && !timedOut) {
      const notification = getNotificationMarkup();

      FloatingNotificationsController.addNotification(notification, UI_SHOWTIME_MS);
    }
  });

  const race = [
    checks,
    new Promise(res=>setTimeout(()=>{
      timedOut = true;

      if (!checksCompleted) {
        logger.viewerInfo("network checks timed out");
      }

      res();
    }, NET_TIMEOUT_MS))
  ];

  result = Promise.race(race);

  return result;
}

const clear = () => {
  failedSites.length = 0;
  forcedFailure = false;
  result = null;
  timedOut = false;
  checksCompleted = false;
}

module.exports = {
  checkNetworkConnections() {
    forcedFailure = isForcedFailure();

    const needsCheck = BrowserCompatibilityService.needsCompatibilityCheck() || forcedFailure;

    if (!needsCheck) {return Promise.resolve();}

    return checkSites();
  },
  isForcedFailure,
  clear
}
