const Global = require("../Config/Global");
const Logger = require("../Logger");

const MODE_REGISTER = "REGISTER";
const MODE_VALIDATE = "VALIDATE";

const service = {};

service.registerDisplay = (claimId, displayName, cb) => {
  // API: /v2/viewer/display/{claimId}/register?callback={callbackName}&name={displayName}
  //      callbackName is mandatory, displayName is optional.
  const viewerWidth = window.innerWidth;
  const viewerHeight = window.innerHeight;

  let url = Global.DATA_SERVER_URL
            .replace("{0}", "display")
            .replace("{1}", claimId);
  url += `/register?width=${viewerWidth}&height=${viewerHeight}`;
  if (displayName !== null && displayName) {
    url += `&name=${encodeURIComponent(displayName)}`;
  }
  url += "&callback=?";
  _callUrlNative(url, MODE_REGISTER, cb);
};

service.validateDisplayId = (displayId, cb) => {
  // reuse Viewer API "display" call
  let url = Global.DATA_SERVER_URL.replace("{0}", "display");
  url = url.replace("{1}", displayId);
  url += "?callback=?";
  _callUrlNative(url, MODE_VALIDATE, cb);
};


function _callUrlNative(url, mode, cb) {
  if (!jQuery || !jQuery.getJSON) { return; }

  Logger.log("Register Display request - start");

  $.getJSON(url, { format: "json" }, (result) => {
    try {
      Logger.log("Register Display response - active");
      _processResponse(result, mode, cb);
    } catch (err) {
      Logger.logException("DisplayRegister.validateDisplayId._callUrlNative", err, url);
    }
  })
  .fail((jqXHR, textStatus) => {
    Logger.logException("DisplayRegister.validateDisplayId._callUrlNative.fail", err, url, textStatus);
  });
}

function _processResponse(obj, mode, cb) {
  let errorCode;
  let newDisplayId;

  const status = obj.status;

  if (status !== null && mode !== null) {
    const statusMessage = status.message;
    errorCode = status.code;
    if (mode === MODE_REGISTER) {
      newDisplayId = obj.displayId || "";
      cb(errorCode, statusMessage, newDisplayId);
    } else if (mode === MODE_VALIDATE) {
      cb(errorCode, statusMessage);
    }
  }
}

module.exports = service;
