const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const queryString = require("query-string");

const service = {};

service.LOW_PERFORMANCE_DEVICE_FLAG = 'low-performance-device-flag';

const LOW_PERFORMANCE_PRODUCT_CODES = [
  "AFTSS", // FireOS Lite
  "AFTSSS", // FireOS 3rd Gen
  "AFTMM", // FireOS 4k
  "AFTKA", // FireOS 4k Max
  "Chromecast",
  "Chromecast HD",
  "W55PNC-CS1386A-G", // ClearTouch NIO 5510
  "X10-Mini", // Minix X10 recommended device
];

let started = false;
let lowPerformanceDevice = null;

service.start = ()=>{
  // apply performance detection to players only
  if (!(ConfigurationFactory.isDisplay() || ConfigurationFactory.isWebPlayer())) {
    return;
  }
  if (started || ConfigurationFactory.isEmbed()) {
    return;
  }

  const flag = localStorage.getItem(service.LOW_PERFORMANCE_DEVICE_FLAG);
  lowPerformanceDevice = flag ? flag === 'true' : null;

  // if flag value has already been defined as either 'true' or 'false'; don't rewrite it
  if (lowPerformanceDevice !== null) {
    return;
  }

  if (service.isLowPerformanceDevice()) {
    service.setLowPerformanceDeviceFlag(true);
    return;
  }

  // some additional checks may be performed sometime later, after performance data is gathered.
};

service.isLowPerformanceDevice = () => {
  // Airtame devices set as low performance as an action for https://github.com/Rise-Vision/electron-player/issues/81
  return service.isLowPerformanceProductCode() || ConfigurationFactory.isAirtame()
}

service.isLowPerformanceProductCode = ()=>{
  const sysInfo = ConfigurationFactory.getSysInfo();

  if (!sysInfo) {
    return false;
  }

  try {
    const info = queryString.parse(decodeURIComponent(sysInfo));
    const productName = info.product_name || "";

    return LOW_PERFORMANCE_PRODUCT_CODES.includes(productName);
  } catch(error) {
    return false;
  }
};

service.setLowPerformanceDeviceFlag = (value = true)=>{
  if (lowPerformanceDevice === value) {
    return;
  }

  localStorage.setItem(service.LOW_PERFORMANCE_DEVICE_FLAG, value);
  lowPerformanceDevice = value;

  // maybe we can move to debug level later, once performance efforts are stable
  Logger.viewerImportant( "low performance device flag updated", String(value) );
}

service.isDeviceMarkedAsLowPerformance = ()=>{
  return lowPerformanceDevice === true;
}

// For testing purposes
service._reset = ()=>{
  started = false;
  lowPerformanceDevice = null;
}

module.exports = service;
