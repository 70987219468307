"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var __assign = Object.assign;
var __commonJS = function __commonJS(callback, module2) {
  return function () {
    if (!module2) {
      module2 = {
        exports: {}
      };
      callback(module2.exports, module2);
    }
    return module2.exports;
  };
};

// src/heartbeat-monitor.js
var require_heartbeat_monitor = __commonJS(function (exports2, module2) {
  var version2 = false ? "" : "2.11.0";
  var countdownTimer = null;
  var heartbeatLogFn = null;
  var heartbeatInterval = null;
  var log2 = null;
  var _startCountdown = function startCountdown() {
    countdownTimer = setTimeout(function () {
      log2({
        severity: "error",
        eventApp: "content-sentinel",
        eventErrorCode: "E999999999",
        eventDetails: "Missing Content Sentinel heartbeat"
      });
      _startCountdown();
    }, heartbeatInterval * 1.1);
  };
  module2.exports = {
    init: function init(hbLogFn, logFn, interval) {
      module2.exports.setHeartbeatLogFn(hbLogFn);
      module2.exports.setLogFn(logFn);
      module2.exports.startMonitoring(interval);
    },
    startMonitoring: function startMonitoring(interval) {
      heartbeatInterval = interval;
      _startCountdown();
    },
    setHeartbeatLogFn: function setHeartbeatLogFn(fn) {
      heartbeatLogFn = fn;
    },
    setLogFn: function setLogFn(fn) {
      log2 = fn;
    },
    heartbeatReceived: function heartbeatReceived() {
      heartbeatLogFn({
        eventApp: "content-sentinel",
        eventAppVersion: version2
      });
      clearTimeout(countdownTimer);
      _startCountdown();
    },
    stopMonitoring: function stopMonitoring() {
      clearInterval(countdownTimer);
    }
  };
});

// src/dispatcher.js
var heartbeatMonitor = require_heartbeat_monitor();
var SERVICE_WORKER_ACTIVE_POLL_INTERVAL = 100;
var SERVICE_WORKER_ACTIVE_RETRY_ATTEMPTS = 50;
var version = false ? "" : "2.11.0";
var fetcherPath = version ? "./content-sentinel/fetcher-".concat(version, ".js") : "fetcher.js";
var fetcherScope = fetcherPath.split("/").slice(0, -1).join("/");
var controllerBridgePath = version ? "./content-sentinel/controller-bridge-".concat(version, ".html") : "controller-bridge.html";
var controllerPath = version ? "./controller-".concat(version, ".js") : "controller.js";
var controllerBridge = null;
var logger = null;
var defaultLogData = {
  eventAppVersion: version,
  eventApp: "content-sentinel",
  severity: "DEBUG",
  eventDetails: ""
};
var log = function log(data) {
  return logger && logger(__assign(__assign({}, defaultLogData), data));
};
var receivers = null;
var playerMessageHandler = null;
var duplicateIdHandler = null;
var handleMessageError = function handleMessageError(_ref) {
  var origin = _ref.origin,
    data = _ref.data;
  log({
    severity: "error",
    eventErrorCode: "E999999999",
    eventDetails: "message error",
    debugInfo: JSON.stringify({
      origin: origin,
      data: data
    })
  });
};
var isPotentialSafariCorsIssue = function isPotentialSafariCorsIssue(msg) {
  if (!msg) {
    return false;
  }
  return msg === "cannot read file before sending file update message" || msg.indexOf("not allowed by Access-Control-Allow-Origin") > 0;
};
var handleContentSentinelMessage = function handleContentSentinelMessage(event) {
  if (isPotentialSafariCorsIssue(event.data.msg)) {
    var willReload = window.RiseVision.Viewer.EntryPoint.reloadPageIfCorsIssueBrowserMatches(event.data.msg);
    if (willReload) {
      return;
    }
  }
  receivers.forEach(function (receiver) {
    if (!receiver || !receiver.postMessage) {
      return;
    }
    receiver.postMessage(event.data, location.origin);
  });
};
var checkServiceWorkerRegistrationAndRequestReloadIfNull = function checkServiceWorkerRegistrationAndRequestReloadIfNull(event) {
  if (event.data.msg && event.data.msg.toLowerCase() === "watch") {
    if (event.data.filePath && event.data.filePath.endsWith("content.json")) {
      return Promise.resolve(false);
    }
    return navigator.serviceWorker.getRegistration(fetcherScope).then(function (registration) {
      if (registration) {
        return false;
      }
      log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "Service worker is not registered",
        debugInfo: JSON.stringify(event.data)
      });
      return window.RiseVision.Viewer.EntryPoint.reloadPageIfCorsIssueBrowserMatches("Service worker is not registered");
    });
  }
  return Promise.resolve(false);
};
var routeMessage = function routeMessage(event) {
  if (event.data.from === "content-consumer") {
    return checkServiceWorkerRegistrationAndRequestReloadIfNull(event).then(function (willReload) {
      if (!willReload) {
        module.exports.sendControllerMessage(event.data, event.source || event.srcElement);
      }
    });
  }
  if (event.data.from === "content-sentinel-controller") {
    handleContentSentinelMessage(event);
  }
  if (!event.data.from || event.data.from !== "content-sentinel-controller") {
    controllerBridge.contentWindow.postMessage(event.data, location.origin);
  }
};
var handleMessage = function handleMessage(event) {
  if (!event || !event.data) {
    return null;
  }
  var msg = event.data.msg;
  if (msg === "content-sentinel-log") {
    return log(event.data.logEntry);
  }
  if (msg === "content-sentinel-heartbeat") {
    return heartbeatMonitor.heartbeatReceived();
  }
  if (["upgrade-request", "restart-request", "reboot-request", "screenshot-request"].includes(msg)) {
    return playerMessageHandler && playerMessageHandler(msg, event.data);
  }
  if (msg === "duplicate-id") {
    return duplicateIdHandler && duplicateIdHandler(event.data.logOnly);
  }
  return routeMessage(event);
};
var handleUnhandledRejection = function handleUnhandledRejection(event) {
  console.log(event);
};
var addEventListeners = function addEventListeners() {
  addEventListener("message", handleMessage);
  addEventListener("messageerror", handleMessageError);
  addEventListener("unhandledrejection", handleUnhandledRejection);
};
var waitForActiveServiceWorker = function waitForActiveServiceWorker(registration) {
  return new Promise(function (resolve) {
    var _checkActiveServiceWorkerOrWait = function checkActiveServiceWorkerOrWait(attempts) {
      if (registration.active) {
        resolve();
      } else if (attempts > 0) {
        setTimeout(function () {
          _checkActiveServiceWorkerOrWait(attempts - 1);
        }, SERVICE_WORKER_ACTIVE_POLL_INTERVAL);
      } else {
        log({
          severity: "important",
          eventDetails: "Service worker took long to activate"
        });
        resolve();
      }
    };
    _checkActiveServiceWorkerOrWait(SERVICE_WORKER_ACTIVE_RETRY_ATTEMPTS);
  });
};
var registerFetcher = function registerFetcher() {
  console.log("Reregistering fetcher");
  if (!navigator || !navigator.serviceWorker) {
    var errorCode = "E000000102";
    log({
      severity: "error",
      eventErrorCode: errorCode,
      eventDetails: "Service worker not supported"
    });
    return Promise.reject(Error(errorCode));
  }
  navigator.serviceWorker.onerror = function () {
    var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    log({
      severity: "error",
      eventErrorCode: "E999999999",
      eventDetails: "Service worker onerror fired"
    });
    console.error("Service worker onerror fired", error);
  };
  return navigator.serviceWorker.getRegistration(fetcherScope).then(function (reg) {
    return reg && reg.unregister();
  }).then(function () {
    return navigator.serviceWorker.register(fetcherPath);
  }).then(waitForActiveServiceWorker);
};
var startController = function startController() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    displayId = _ref2.displayId,
    machineId = _ref2.machineId,
    heartbeatInterval = _ref2.heartbeatInterval,
    endpointType = _ref2.endpointType,
    endpointId = _ref2.endpointId,
    scheduleId = _ref2.scheduleId;
  var search = "?interval=".concat(heartbeatInterval, "&controllerPath=").concat(controllerPath, "&scheduleId=").concat(scheduleId, "&endpointType=").concat(endpointType, "&endpointId=").concat(endpointId || displayId);
  if (displayId) {
    search = "".concat(search, "&displayId=").concat(displayId, "&machineId=").concat(machineId);
  }
  controllerBridge = document.createElement("iframe");
  controllerBridge.src = controllerBridgePath + search;
  controllerBridge.style.display = "none";
  return new Promise(function (res) {
    controllerBridge.addEventListener("load", res);
    document.body.append(controllerBridge);
    setTimeout(res, 2e3);
  });
};
var initializationErrorCategoryFor = function initializationErrorCategoryFor(_ref3) {
  var message = _ref3.message;
  if (!message) {
    return "uncategorized";
  } else if (message === "Rejected") {
    return "service worker registration rejected";
  } else if (message.includes("ServiceWorker script evaluation failed")) {
    return "service worker script evaluation failed";
  } else if (message.includes("A bad HTTP response code (404) was received when fetching the script")) {
    return "content sentinel version not found on GCS";
  } else if (message === "Failed to get a ServiceWorkerRegistration: Operation has been aborted") {
    return "get service worker registration operation has been aborted";
  } else if (message.includes("Operation has been aborted")) {
    return "service worker registration aborted";
  } else if (message.includes("Failed to register a ServiceWorker for scope")) {
    return "service worker registration failed";
  } else if (message === "The operation is insecure.") {
    return "the operation is insecure";
  } else if (message.includes("The user denied permission to use Service Worker")) {
    return "the user denied permission to use service worker";
  } else if (message.includes("The Service Worker system has shutdown")) {
    return "the service worker system has shutdown";
  } else if (message.includes("Failed to get a ServiceWorkerRegistration")) {
    return "failed to get service worker registration";
  } else if (message.includes("load failed")) {
    return "service worker script load failed";
  } else if (message === "The object is in an invalid state.") {
    return "the object is in an invalid state";
  } else {
    return "uncategorized";
  }
};
module.exports = {
  init: function init() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      displayId = _ref4.displayId,
      machineId = _ref4.machineId,
      heartbeatInterval = _ref4.heartbeatInterval,
      heartbeatLogFn = _ref4.heartbeatLogFn,
      logFn = _ref4.logFn,
      endpointId = _ref4.endpointId,
      scheduleId = _ref4.scheduleId,
      endpointType = _ref4.endpointType,
      playerMessageFn = _ref4.playerMessageFn,
      duplicateIdHandlerFn = _ref4.duplicateIdHandlerFn;
    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === "object" && window) {
      window.contentSentinelInitializer = true;
    }
    if (logFn) {
      module.exports.setLogFn(logFn);
    }
    if (controllerBridge) {
      log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "Initialized dispatcher multiple times"
      });
    }
    heartbeatMonitor.init(heartbeatLogFn, logFn, heartbeatInterval);
    receivers = new Set();
    playerMessageHandler = playerMessageFn;
    duplicateIdHandler = duplicateIdHandlerFn;
    return registerFetcher().then(function () {
      return startController({
        displayId: displayId,
        machineId: machineId,
        heartbeatInterval: heartbeatInterval,
        endpointId: endpointId,
        scheduleId: scheduleId,
        endpointType: endpointType
      });
    }).then(addEventListeners).then(function () {
      return {
        success: true
      };
    })["catch"](function () {
      var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (/^E\d{9}$/.test(error.message)) {
        return {
          success: false,
          failure: error.message
        };
      }
      var category = initializationErrorCategoryFor(error);
      log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "error during init - ".concat(category),
        debugInfo: JSON.stringify({
          msg: error.message || error.name,
          stack: error.stack
        })
      });
      return {
        success: false,
        failure: category
      };
    });
  },
  clearReceivers: function clearReceivers() {
    receivers.clear();
  },
  sendControllerMessage: function sendControllerMessage(msg, receiver) {
    if (!receiver) {
      return log({
        severity: "error",
        eventErrorCode: "E999999999",
        eventDetails: "invalid use of dispatcher - missing receiver"
      });
    }
    if (receiver !== window) {
      receivers.add(receiver);
    }
    return controllerBridge.contentWindow.postMessage(msg, location.origin);
  },
  setLogFn: function setLogFn(fn) {
    logger = fn;
  }
};