const PopupPanel = require("../PopupPanel");
const EntryPoint = require("../../EntryPoint");

const COUNTDOWN_SECONDS = 30;

class DisplayRegisterBaseWidget extends PopupPanel {
  constructor({ showCountdown, containerId }) {
    super({
      autoHide: false,
      containerId,
    });

    this.MESSAGE_NOT_VERIFIED = "Not Verified";

    this.showCountdown = showCountdown;
    this.$countdownLabel = this.$elem.find(".countdown-label");
    if (this.showCountdown) {
      this.$countdownLabel.show();
    } else {
      this.$countdownLabel.hide();
    }
  }

  show() {
    super.show();
    // this.$content.offset({
    //   left: (window.innerWidth / 2) - 200,
    //   top: 100,
    // });
    // this.hasParent = hasParent;

    if (this.showCountdown) {
      this._startCountdownTimer();
    }
  }

  _startCountdownTimer() {
    const _this = this;
    _this.$countdownLabel.show();
    _this.countdownSeconds = COUNTDOWN_SECONDS;
    _this._timer = setInterval(() => {
      _this.countdownSeconds -= 1;
      _this.$countdownLabel.html(`Presentation will begin in ${this.countdownSeconds} seconds.`);
      if (_this.countdownSeconds === 0) {
        _this._closeAndStartPresentation();
      }
    }, 1000);
  }

  stopCountdownTimer() {
    clearInterval(this._timer);
  }

  _closeAndStartPresentation() {
    this.stopCountdownTimer();
    this.$countdownLabel.hide();
    this.hide();
    EntryPoint.loadPresentation();
  }
}

module.exports = DisplayRegisterBaseWidget;
