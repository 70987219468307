const Utils = require("../Utils");
const Logger = require("../Logger");

const GadgetSettingsParser = {};

const GADGET_SCRIPT_SHORT = "" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/gadgets.min.js\"></script>" +
    "";

const GADGET_SCRIPT_LONG = "" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/globals.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/base.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/string.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/urlparams.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/config.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/auth.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/auth-init.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/json-native.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/io.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/onload.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/prefs.js\"></script>" +
//			"<script type=\"text/javascript\" language=\"javascript\" src=\"/gadgets/setprefs.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/log.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/wpm.transport.js\"></script>" +
    "<script type=\"text/javascript\" language=\"javascript\" src=\"./gadgets/rpc.js\"></script>" +
    "";

const GADGET_INNER_HTML = "" +
    "<html><head>" +
    "<style>" +
    "body,td,div,span,p{font-family:arial,sans-serif;}a {color:#0000cc;}a:visited {color:#551a8b;}a:active {color:#ff0000;}body{margin: 0px;padding: 0px;background-color:white;}" +
    "</style>" +
    "%script%" +
    "<script>" +
//			"	// internals\n" +
    "	gadgets.Prefs.setMessages_({});\n" +
    "	gadgets.Prefs.setDefaultPrefs_(%s1%);\n" +
//			"	// Custom addition to initialize of the Gadget\n" +
    "	gadgets.Prefs.setUrlParams_(\"%s0%\");\n" +
    "	gadgets.io.preloaded_=[];\n" +
    "	gadgets.rpc.setRelayUrl(\"..\", \"%parent%\");\n" +
//			"" +
    "</script>" +
//			"<script type=\"text/javascript\" language=\"javascript\" src=\"scripts/widgetScripts.js\"></script>" +
    "	%head%" +
    "</head>" +
    "<body>" +
    "	%body%" +
    "<script>gadgets.util.runOnLoadHandlers();</script>" +
    "</body>" +
    "</html>"
    ;

const NODE_TYPE = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3,
  CDATA_SECTION_NODE: 4,
};

let useShortScript = true;

const GadgetSettingsInfo = function (gadgetUrl, additionalParams, gadgetXml) {
  const factory = {};

  let isRiseGadget;
  let isCustomUI;
  let titleUrl;
  let htmlContent = "", editorContent = "";

  let gadgetSettings = {};

  factory.setAdditionalParams = (additionalParams) => {
    this.additionalParams = additionalParams;
  };

  factory.getAdditionalParams = () => {
    return additionalParams;
  };

  factory.setGadgetXml = (gadgetXml) => {
    _parseGadgetXml(gadgetXml);

    _parseUrl();
  };

  const _parseXmlObject = (gadgetXml) => {
    let xml = null;

    if (window.DOMParser) {
      const parser = new DOMParser();
      xml = parser.parseFromString(gadgetXml, "text/xml");

      const foundErr = xml.getElementsByTagName("parsererror");

      if (!foundErr || !foundErr.length ||
        !foundErr[0].childNodes.length) {
        return xml;
      }
    }

    return null;
  };

  let _parseGadgetXml = (gadgetXml) => {
    let d;

    try {
      d = _parseXmlObject(gadgetXml);
    } catch (err) {
      Logger.logException("GadgetSettingsParser._parseGadgetXml", err);

      throw err;
    }

    let nodeList;
    let attrMap;
    // check if Rise gadget
    isRiseGadget = false;
    // check if it has Custom UI
    isCustomUI = false;

    // check if this is a Gadget
    nodeList = d.getElementsByTagName("Module");
    if (nodeList.length === 0) {
      throw "Error: Invalid XML file. <Module> tag is missing.";
    }

    // check if Gadget has ModulePrefs and then parse them
    nodeList = d.getElementsByTagName("ModulePrefs");

    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList.item(i);

      if (node.nodeName === "ModulePrefs") {
        titleUrl = _getNodeValue(node.attributes, "title_url");
      }
    }

    if (nodeList.length > 0) {
      const featureNode = _findNodeWithAttribute(nodeList.item(0).childNodes, "Optional", "feature", "rise-api");

      if (featureNode && featureNode.childNodes.length > 0) {
        isRiseGadget = (_findNodeWithAttribute(featureNode.childNodes, "Param", "name", "reports_ready") !== null);
      }
  //			attrMap = nodeList.item(0).attributes;
  //			isRiseGadget = 'yes'.equals(getNodeValue(attrMap,'reports_ready'));
    }

    nodeList = d.getElementsByTagName("Content");
    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList.item(i);
      attrMap = node.attributes;

      if (_getNodeValue(attrMap, "view") === "editor") {
        isCustomUI = true;
        editorContent = _getCdataElement(node);
  //				return;
      }
      else {
        htmlContent = _getCdataElement(node);
      }
    }

    // parse UserPrefs
    nodeList = d.getElementsByTagName("UserPref");
    gadgetSettings = {};

    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList.item(i);
      attrMap = node.attributes;

      if (!(isRiseGadget && _isRiseProperty(_getNodeValue(attrMap, "name")))) {
        const gadgetSetting = new GadgetSetting();
        gadgetSetting.name = _getNodeValue(attrMap, "name");
        gadgetSetting.displayName = _getNodeValue(attrMap, "display_name");
        gadgetSetting.defaultValue = _getNodeValue(attrMap, "default_value");
        gadgetSetting.datatype = _getNodeValue(attrMap, "datatype");
        gadgetSetting.urlParam = _getNodeValue(attrMap, "urlparam");
        gadgetSetting.required = _getNodeValueBoolean(attrMap, "required");
        if (gadgetSetting.datatype === "enum") {
          gadgetSetting.enumValues = _getNodeEnumValues(node);
        }

        gadgetSettings[gadgetSetting.name] = gadgetSetting;
      }
    }
  };

  let _getCdataElement = (node) => {
    let cdataContent = "";

    if (node.nodeType === NODE_TYPE.ELEMENT_NODE && node.hasChildNodes()) {
      // XMLParser.removeWhitespace(node);

      const nl = node.childNodes;
      for (let j = 0; j < nl.length; j++) {
        const childNode = nl.item(j);

        if (childNode.nodeType === NODE_TYPE.TEXT_NODE && childNode.value) {
          cdataContent += childNode.value + "\n";
        }
        else if (childNode.nodeType === NODE_TYPE.CDATA_SECTION_NODE && childNode.data) {
          cdataContent += childNode.data + "\n";
        }
      }
    }

    return cdataContent;
  };

  let _findNodeWithAttribute = (nodeList, nodeName, parameterName, parameterValue) => {
    for (let i = 0; i < nodeList.length; i++) {
      const node = nodeList.item(i);
      if (nodeName === node.nodeName) {
        const attrMap = node.attributes;
        if (parameterValue === _getNodeValue(attrMap, parameterName)) {
          return node;
        }
      }
    }
    return null;
  };

  let _isRiseProperty = (propertyName) => {
    const sa = ["rsA", "rsS"];
    for (let i = 0; i < sa.length; i++) {
      if (sa[i] === propertyName) {
        return true;
      }
    }
    return false;
  };

  let _getNodeValue = (attrMap, name) => {
    const node = attrMap.getNamedItem(name);
    if (node) {
      return node.value;
    }
    else {
      return "";
    }
  };

  let _getNodeValueBoolean = (attrMap, name) => {
    const node = attrMap.getNamedItem(name);
    if (node) {
      return (node.value === "true");
    }
    else {
      return false;
    }
  };

  let _getNodeEnumValues = (node) => {
    const result = [];

    const nodeList = node.childNodes;
    if (nodeList) {
      for (let i = 0; i < nodeList.length; i++) {
        const node2 = nodeList.item(i);
        if (node2 && node2.nodeName === "EnumValue") {
          const attrMap = node2.attributes;
          result.push({
            value: _getNodeValue(attrMap, "value"),
            display_value: _getNodeValue(attrMap, "display_value"),
          });
        }
      }
    }
    return result;
  };

  factory.isCustomUI = () => {
    return isCustomUI;
  };

  factory.getTitleUrl = () => {
    return titleUrl;
  };

  factory.getGadgetSettings = () => {
    return gadgetSettings;
  };

  let _parseUrl = () => {
    const xmlUrl = factory.getGadgetXmlUrl();

    if (gadgetUrl.length > xmlUrl.length) {
      const gadgetUrlParams = gadgetUrl.substring(xmlUrl.length + 1);

      factory.updateGadgetSettings(gadgetUrlParams);
    }

  //		for (GadgetSetting g: gadgetSettings) {
  //			g.setValue(urlParams.get('up_' + g.getName()));
  //		}
  };

  factory.updateGadgetSettings = (gadgetUrlParams) => {
    try {
      const ray = gadgetUrlParams.split("&");
      for (let i = 0; i < ray.length; i++) {
        const substrRay = ray[i].split("=");

        if (substrRay.length > 0) {
          let name = substrRay[0], value = "";

          if (name.indexOf("up_") !== -1) {
            name = name.substring(3);
          }

          if (substrRay.length === 2) {
            value = substrRay[1];
          }

          if (gadgetSettings[name]) {
            gadgetSettings[name].value = value;
          }
          else {
            const setting = new GadgetSetting(name, value);
            gadgetSettings[name] = setting;
          }
        }
      }
    } catch (err) {
      Logger.logException("GadgetSettingsParser.updateGadgetSettings", err, null, gadgetUrlParams);
    }
  };

  factory.getGadgetUrl = () => {
  //		if (!isCustomUI) {
    return (factory.getGadgetXmlUrl() + _getGadgetUrlParams()).trim();
  //		}
  //		else {
  //			return gadgetUrl;
  //		}
  };

  let _getGadgetUrlParams = () => {
    let params = "";

    for (const setting in gadgetSettings) {
      params += "&" + "up_" + gadgetSettings[setting].getName() + "=" + gadgetSettings[setting].getValue();
    }

    if (params.length > 0) {
      params = "&" + params.substring(1);
    }

    return params;
  };

  factory.getGadgetXmlUrl = () => {
    // handle improperly url that have '?'
    let res = gadgetUrl.replace("\\?", "&");
    // get the gadget URL part
    const queryParamsStartPos = res.indexOf("&");
    if (queryParamsStartPos > 0) {
      res = gadgetUrl.substring(0, queryParamsStartPos);
    }

    return res;
  };

  factory.getGadgetHtml = (isEditor) => {
    let gadgetHtml = isEditor ? editorContent : htmlContent;
    const gadgetDefaultSettings = _getGadgetSettingsJsonString();
    const gadgetUrlParams = _getGadgetUrlParams();

    // Gadget XML needs to be parsed and parameters in the HTML updated
    // format: __UP_text_font-style__
    for (const setting in gadgetSettings) {
      const re = new RegExp("__UP_" + gadgetSettings[setting].getName() + "__", "g");
      gadgetHtml = gadgetHtml.replace(re, gadgetSettings[setting].getDecodedValue());
    }

    const divider = _getGadgetHeadDivider(gadgetHtml);
    const gadgetHead = gadgetHtml.substring(0, divider);
    gadgetHtml = gadgetHtml.substring(divider);

    const html = GADGET_INNER_HTML
        .replace("%script%", useShortScript ? GADGET_SCRIPT_SHORT : GADGET_SCRIPT_LONG)
        .replace("%s0%", gadgetUrlParams)
        .replace("%s1%", gadgetDefaultSettings)
        .replace("%head%", gadgetHead)
        .replace("%body%", gadgetHtml)
        .replace("%parent", window.location.href);

    return html;
  };

  let _getGadgetHeadDivider = (gadgetHtml) => {
    const SCRIPT_TAG = "script";
    const STYLE_TAG = "style";

    let divider = 0;

    while (gadgetHtml.indexOf("<", divider) !== -1) {
      let tag = gadgetHtml.indexOf("<", divider);

      if (gadgetHtml.indexOf(SCRIPT_TAG, tag) === (tag + 1) ||
          gadgetHtml.indexOf(STYLE_TAG, tag) === (tag + 1)) {
        tag = gadgetHtml.indexOf("</", tag);
        divider = gadgetHtml.indexOf(">", tag) + 1;
      }
      else {
        break;
      }
    }

    return divider;
  };

  let _getGadgetSettingsJsonString = () => {
    const values = [];

    for (const setting in gadgetSettings) {
      const value = {};
      value[gadgetSettings[setting].getName()] = gadgetSettings[setting].getDefaultValue();
      values.push(value);
    }

    let response = JSON.stringify(values);
    if (response.charAt(0) === "[") response = response.substring(1);
    if (response.charAt(response.length - 1) === "]") response = response.substring(0, response.length - 1);

    return response;
  };

  const DATATYPE_HIDDEN = "hidden";

  class GadgetSetting {
    constructor(name, value) {
      this.name = name;
      this.displayName = "";
      this.defaultValue = "";
      this.urlParam = "";
      this.required;
      this.enumValues;
      // helper properties
      this.value = value;
      this.widget = null;

      this.datatype = DATATYPE_HIDDEN;
    }

    isVisible() {
      return !DATATYPE_HIDDEN === this.datatype;
    }

    getValue() {
      return (this.value) ? this.value : this.defaultValue;
    }

    setValue(value) {
      this.value = value;
    }

    getDecodedValue() {
      let res = this.getValue();
      try {
        res = Utils.decodeQueryString(res);
      } catch (err) {
        Logger.logException("GadgetSettingsParser.GadgetSetting.getDecodedValue", err);
      }
      return res;
    }

    getHeader() {
      return this.displayName + (this.required ? "*" : "") + ":";
    }

    getName() {
      return this.name;
    }

    getDisplayName() {
      return this.displayName;
    }

    getDefaultValue() {
      return this.defaultValue;
    }

    getUrlParam() {
      return this.urlParam;
    }

    getDatatype() {
      return this.datatype;
    }

    isRequired() {
      return this.required;
    }

    getEnumValues() {
      return this.enumValues;
    }

    getWidget() {
      return this.widget;
    }

    setWidget(widget) {
      this.widget = widget;
    }
  }

  (function () {
    if (gadgetUrl != null) {
      gadgetUrl = gadgetUrl.trim();
    }

    factory.setGadgetXml(gadgetXml);
  }());

  return factory;
};


GadgetSettingsParser.useLongScript = () => {
  useShortScript = false;
};

GadgetSettingsParser.parse = function (gadgetUrl, gadgetXml) {
  const info = new GadgetSettingsInfo(gadgetUrl, null, gadgetXml);

  return info.getGadgetHtml();
};

module.exports = GadgetSettingsParser;
