const RemoteRequests = require("./RemoteRequests");
const ViewerDataProvider = require("./ViewerDataProvider");

const ConfigurationFactory = require("../ConfigurationFactory");

const service = {}

const DEFAULT_DISPLAY_NAME = "NEW DISPLAY";
const SECONDS = 1000;
const RETRY_INTERVAL = 5 * SECONDS;
const MAX_VALIDATION_ATTEMPTS = 20;

let TIMEOUT_FUNCTION = setTimeout;

service.setTimeoutFunction = func => {
  TIMEOUT_FUNCTION = func;
};

service.reset = () => {
  TIMEOUT_FUNCTION = setTimeout;
};

const _waitUntilDisplayIdContentJsonExists = ( displayId, remainingAttempts = MAX_VALIDATION_ATTEMPTS ) => {
  if( remainingAttempts === 0 ) {
    return Promise.resolve( null ); // Give up
  }

  return new Promise( resolve => {
    TIMEOUT_FUNCTION(() => {
      RemoteRequests.validateDisplayId( displayId )
      .then( () => resolve( displayId ) )
      .catch( error => {
        console.log( `Wait for display id content.json failed, remaining attempts: ${ remainingAttempts }` );

        _waitUntilDisplayIdContentJsonExists( displayId, remainingAttempts - 1 ).then( resolve );
      });
    }, RETRY_INTERVAL);
  });

};

service.generateDisplayName = () => {
  try {
    const { mac, local_ip, serial_number } = ViewerDataProvider.getPlayerConfigData();

    return mac ? `Display ${ mac }` :
      serial_number ? `Display ${ serial_number }` :
      local_ip ? `Display ${ local_ip }` : DEFAULT_DISPLAY_NAME;
  } catch( error ) {
    console.error( "Error generating display name", error );

    return DEFAULT_DISPLAY_NAME;
  }
};

service.generateDisplayIdFromClaimId = claimId => {
  console.log( `Claim ID found, submitting it: ${claimId}` );

  const displayName = service.generateDisplayName();

  return RemoteRequests.createDisplayIdFromClaimId(displayName, claimId)
  .then( newDisplayId => {
    console.log( "Claim ID submitted, new display ID: ", newDisplayId );

    ConfigurationFactory.setDisplayId( newDisplayId );
    ConfigurationFactory.removeActivationCode();

    return _waitUntilDisplayIdContentJsonExists( newDisplayId );
  }).catch( error => {
    console.error( "Display id generation from claimId failed", error );

    return null;
  });
};

module.exports = service;
