/* eslint-disable class-methods-use-this */
const ConfigurationFactory = require("../ConfigurationFactory");

class NavigationUIController {
  constructor() {
    this.$elem = window.$("#navigation-ui-div");

    this._populatePieces();

    this._initHandlers();
  }

  _populatePieces() {
    const $c = this.$elem;
    this.$previousButton = $c.find("#navigation-previous");
    this.$nextButton = $c.find("#navigation-next");
    this.$progressContainer = $c.find("#nav-ui-progress-container");

    this.$body = window.$("body");
    this.$mainDiv = window.$("#mainDiv");
    this.$navAction = window.$(".navigation-action");
  }

  _initHandlers() {
    this.$previousButton.click(() => { this.previous(); });
    this.$nextButton.click(() => { this.next(); });

    this.$body.keydown((event) => {
      if (!this._showBind) {
        return;
      }

      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      switch (event.key) {
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        this.previous();
        this._showBind();
        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
        break;
      case "Right": // IE/Edge specific value
      case "ArrowRight":
        this.next();
        this._showBind();
        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
        break;
      default:
      }
    });
  }

  _addEvents() {
    this._showBind = this.show.bind(this);

    this.$mainDiv.on("click mousemove", this._showBind);
    this.$navAction.on("click", this._showBind);
  }

  _removeEvents() {
    this.$mainDiv.off("click mousemove", this._showBind);
    this.$navAction.off("click", this._showBind);

    delete this._showBind;
  }

  next() {
    if (!this._scheduleController) return;

    this._scheduleController.playNextItem();
  }

  previous() {
    if (!this._scheduleController) return;

    this._scheduleController.playPreviousItem();
  }

  setScheduleController(scheduleController) {
    this._scheduleController = scheduleController;
    scheduleController.addObserver(this._onScheduleChanged.bind(this));
  }

  _onScheduleChanged() {
    const statusInfo = this._scheduleController.getStatusInfo();
    const { itemsStatus } = statusInfo;
    let playableItemsCount = 0;

    this.$progressContainer.empty();

    for (let i = 0; i < itemsStatus.length; i++) {
      if (itemsStatus[i].canPlay) {
        const progress = $("<div class=\"nav-ui-progress\"></div>");
        if (i === statusInfo.currentItem) {
          progress.addClass("current");
          if (!itemsStatus[i].playUntilDone) {
            progress.addClass("active");
            progress.css("animation-duration", (itemsStatus[i].duration - 0.7) + "s");
            progress.css("animation-delay", (statusInfo.currentItemTime - itemsStatus[i].duration) + "s");
          }
        }

        if (!itemsStatus[i].isReady) {
          progress.addClass("not-ready");
        }

        playableItemsCount++;
        this.$progressContainer.append(progress);
      }
    }

    if (playableItemsCount > 1) {
      if (ConfigurationFactory.isInApp()) {
        this.$elem.show();
      } else {
        !this._showBind && this._addEvents();
      }
    } else {
      if (ConfigurationFactory.isInApp()) {
        this.$elem.fadeOut();
      } else {
        this._showBind && this._removeEvents();
      }
    }
  }

  show() {
    if (!this._scheduleController) return;

    if (this.$elem.css("display") === "none") {
      this._onScheduleChanged();
      this.$elem.show();
    }

    clearTimeout(this._hideTimer);
    this._hideTimer = setTimeout(() => {
      this.hide();
    }, 3000);
  }

  hide() {
    this.$elem.fadeOut();
  }
}

let instance;

// singleton
function _getInstance() {
  if (!instance) {
    instance = new NavigationUIController();
  }

  return instance;
}

NavigationUIController.init = (scheduleController) => {
  if (!ConfigurationFactory.isSharedschedule()) {
    return;
  }

  _getInstance().setScheduleController(scheduleController);

  return _getInstance();
};

// For unit tests
NavigationUIController._clearInstance = () => {
  instance = undefined;
};

module.exports = NavigationUIController;
