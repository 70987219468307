const Utils = require("../Utils");
const Logger = require("../Logger");
const PlaylistItemUtils = require("./PlaylistItemUtils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const PresentationParser = require("../Parser/PresentationParser");
const GadgetSettingsParser = require("../Parser/GadgetSettingsParser");

const GadgetController = function (placeholderInfo, playlistItem, phName, htmlName, onGadgetReady, onGadgetDone) {
  const factory = {};

  let presFrame;
  let isReady = false;
  let isPlaying = false;

  const GADGET_SCRIPT = "<script><!--\n" +
    "try {" +
    "updateGadget('%s1', '%s3', '%s4', '%s5');" +
    "gadgetRequest('%s4', '%s2');" +
    "} catch(err) { parent.writeToLog('updateGadget call - %s3 - ' + err.message); }" +
    "\n//-->" +
    "</script>";

  const GADGET_HTML = "" +
      "<iframe id=\"if_%id%\" name=\"if_%id%\" allowTransparency=\"true\" " +
      "style=\"display:block;position:absolute;height:100%;width:100%;\" frameborder=0 scrolling=\"no\">" +
      "</iframe>" +
      "";

  factory.updateHtml = (presentation) => {
    if (playlistItem && PlaylistItemInfo.TYPE_GADGET === playlistItem.type) {
      _updateHtmlGadget(presentation);
    }
  };

  let _updateHtmlGadget = (presentation) => {
    let transition = "none";
    let xmlUrl = "";
    if (playlistItem) {
      if (playlistItem.objectData.toLowerCase().indexOf(".xml") != -1) {
        const url = playlistItem.objectData;

        const queryLocation = url.indexOf(".xml") + 4;

        xmlUrl = url.substring(0, queryLocation);
      }
      else {
        // add URL functionality here
      }
    }
  //		else if (placeholderInfo.getUrl().toLowerCase().contains('.xml')) {
  //			url = placeholderInfo.getUrl();
  //		}

    if (placeholderInfo) {
      transition = placeholderInfo.transition;
    }

    const html = GADGET_HTML
  //				.replace('%s0%', url)
        .replace(/%id%/g, htmlName)
        .replace(/\n/g, "")
        .replace(/\r/g, "");

    _addGadgetScript(presentation, html, xmlUrl, phName, htmlName,
        transition);
  };

  let _addGadgetScript = (presentation, html, gadgetUrl, containerName, htmlName, transition) => {
    const tagHtml = GADGET_SCRIPT.replace(/%s1/g, html)
            .replace(/%s2/g, gadgetUrl)
            .replace(/%s3/g, containerName)
            .replace(/%s4/g, htmlName)
            .replace(/%s5/g, transition);

    PresentationParser.addScriptTag(presentation, tagHtml);
  };

  // var _addVersionNumber(var url) {
  //   var queryLocation = url.indexOf('.xml');
  //   if (queryLocation !== -1) {
  //     var versionString = '%3Fv%3D' + Random.nextInt(10000);
  //     queryLocation += '.xml'.length();
  //
  //     if (queryLocation < url.length()) {
  //       url = url.substring(0, queryLocation) + versionString + url.substring(queryLocation, url.length());
  //     }
  //     else {
  //       url = url + versionString;
  //     }
  //   }
  //   else {
  //     // TODO: handle cases where the Gadget is obtained through an API not a static .XML file.
  //   }
  //   return url;
  // }

  const _removeIdParameter = (url) => {
    while (url.indexOf("up_id=") > 0) {
      let start = url.indexOf("up_id=");
      if (url[start - 1] === "&") {
        start -= 1;
      }
      const end = url.indexOf("&", url.indexOf("up_id="));
      if (end !== -1 && url[start - 1] === "?") {
        url = url.substring(0, start) + url.substring(end + 1, url.length);
      }
      else if (end !== -1) {
        url = url.substring(0, start) + url.substring(end, url.length);
      }
      else {
        url = url.substring(0, start);
      }
    }

    return url;
  };

  const _updateUrlParams = (urlParams) => {
    let height, width;
  //		int top = 0, left = 0;
    urlParams = _removeIdParameter(urlParams);
  //		urlParams = addVersionNumber(urlParams);

    if (placeholderInfo.widthUnits === "%") {
      width = parseInt((placeholderInfo.width / 100.0) * window.innerWidth);
    }
    else {
      width = parseInt(placeholderInfo.width);
    }

    if (placeholderInfo.heightUnits === "%") {
      height = parseInt((placeholderInfo.height / 100.0) * window.innerHeight);
    }
    else {
      height = parseInt(placeholderInfo.height);
    }

    urlParams += "&up_rsW=" + width;
    urlParams += "&up_rsH=" + height;

    // '&pid=test1&up_id=test1'
  //		urlParams += '&pid=' + htmlName;
    urlParams += "&up_id=" + htmlName;
  //		urlParams += '&parent=' + URL.encodeQueryString(Window.Location.getHref());
  //		urlParams += ConfigurationFactory.getDisplayId().isEmpty() ? '': '&up_displayid=' + ConfigurationFactory.getDisplayId();

    urlParams = urlParams.replace(/'/g, "\\'");

    return urlParams;
  };

  factory.setLoad = (presFrame, gadgetXml) => {
    try {
  		// var gadgetDefaultSettings;
      const gadgetUrl = _updateUrlParams(playlistItem.objectData);

      // used for debugging purposes; doesn't use minified js file
			// GadgetSettingsParser.useLongScript();

      const gadgetHtml = GadgetSettingsParser.parse(gadgetUrl, gadgetXml);

      _renderHtml(presFrame, gadgetHtml);
    } catch (err) {
      Logger.log("Error parsing gadget XML", err);
    }
  };

  let _renderHtml = (presFrame, gadgetHtml) => {
    // render the Gadget in the iframe using (phName, htmlName)
  //		String html = GADGET_INNER_HTML.replace('%s0%', urlParams)
  //									.replace('%s1%', gadgetDefaultSettings)
  //									.replace('%body%', gadgetHtml);

    Utils.addGadgetHtml(presFrame, "if_" + htmlName, gadgetHtml);
  };

  factory.setReady = (frame, canPlay, canStop, canPause, canReportReady, canReportDone) => {
    presFrame = frame;

    if (!isReady) {
      isReady = true;
      if (onGadgetReady) {
        onGadgetReady();
      }
//			// if GadgetReadyCommand is null, than this is a single Gadget
//			// in placeholder (no ready command, just Start playing right away)
//			else if (!isPlaying) {
//				play(true);
//			}
    }
  };

  factory.setError = (presFrame, reason) => {
		// catch gadget error (not implemented)
  };

  factory.setDone = () => {
    if (isPlaying) {
      if (onGadgetDone) {
        isPlaying = false;
        onGadgetDone();
      }
      else {
        factory.stop(false);
        factory.play(false);
      }
    }
  };

  factory.play = (show) => {
    if (!isPlaying) {
      isPlaying = true;
//			if (isReady) {
      Utils.playCommand(presFrame, htmlName, show);
//			}
    }
  };

  factory.stop = (hide) => {
//		if (isPlaying) {
    isPlaying = false;
    Utils.stopCommand(presFrame, htmlName, hide);
//		}
  };

  factory.pause = (hide) => {
		// removed isPlaying check (since pause can be called on a 'paused' item to hide it)
//		if (isPlaying) {
    isPlaying = false;
    Utils.pauseCommand(presFrame, htmlName, hide);
//		}
  };

  factory.unload = () => {
    isReady = false;
  };

  factory.isReady = () => {
    return isReady;
  };

  factory.getItem = () => {
    return playlistItem;
  };

  (function () {
    PlaylistItemUtils.registerGadget(htmlName, factory);
  }());

  return factory;
};

module.exports = GadgetController;
