const PopupPanel = require("../PopupPanel");

class HelpFrameWidget extends PopupPanel {
  constructor() {
    super({
      autoHide: false,
      containerId: "help-frame-div",
    });

    this.$closePanel = this.$elem.find(".close-panel");
    this.$helpFrame = this.$elem.find(".help-frame");
    this._registerButtonEvents();
    this._reposition();
  }

  _registerButtonEvents() {
    const _this = this;
    this.$closePanel.click(() => {
      _this.hide();
    });
  }

  _reposition() {
    const w = window.innerWidth - 40;
    const h = window.innerHeight - 40;
    this.$content.width(w + 2);
    this.$content.height(h + 10);

    this.$helpFrame.width(w);
    this.$helpFrame.height(h);

    this.$closePanel.offset({ left: w - 10, top: -17 });
  }

  show() {
    super.show();
    // delayed URL loading
    this.$helpFrame.prop("src", "https://help.risevision.com/user/create-a-display");
  }
}

HelpFrameWidget.show = () => { return _getInstance().show(); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new HelpFrameWidget();
  }

  return instance;
}

module.exports = HelpFrameWidget;
