const Logger = require("../Logger");
const Global = require("../Config/Global");
const ConfigurationFactory = require("../ConfigurationFactory");
const RisePlayerController = require("../Player/RisePlayerController");

const service = {};

const baseUrl = "http://localhost:9494/";

const PING_TIME = 1 * Global.MINUTE_UPDATE_INTERVAL;
const MAX_PING_ATTEMPTS = 95;

let isActive;

let pingAttempt = 0;
let pingInterval;

let _isV2Running = false;

let callbacks = [];

// Used for unit test purposes
service.setIsActive = function (value) {
  isActive = value;
};

service.resetPingAttempts = function () {
  pingAttempt = 0;
};

const _getIsActiveAsync = function (callback) {
  if (typeof isActive !== "undefined") {
    callback();
  } else {
    callbacks.push(callback);
  }
};

const _clearCallbacks = function () {
  for (let i = 0; i < callbacks.length; i++) {
    callbacks[i]();
  }

  callbacks = [];
};

service.getCacheVideoUrl = function (url, extension, callback) {
  if (url.toLowerCase().indexOf("youtube.com") != -1 ||
    !ConfigurationFactory.isDisplay()) {
    callback(url);

    return;
  }

  _getIsActiveAsync(() => {
    if (!isActive) {
      callback(url);

      return;
    }

    if (!extension && url.lastIndexOf(".") != -1) {
      extension = url.substring(url.lastIndexOf(".") + 1);
      if (extension.indexOf("?") != -1) {
        extension = extension.substring(0, extension.indexOf("?"));
      }
    }

    let response = baseUrl;

    if (_isV2Running) {
      response += "files";
      response += "?url=" + url;
    } else {
      response += "video" + (!extension ? "" : "." + extension);
      response += "?url=" + encodeURIComponent(url);
    }

    callback(response);
  });
};

service.pingCache = function () {
  if (pingAttempt >= MAX_PING_ATTEMPTS) {
    isActive = false;
    _clearCallbacks();
    clearTimeout(pingInterval);
    Logger.log("Rise Cache is not running");
    return;
  }

  if (!isActive) {
    const url = _isV2Running ? baseUrl : baseUrl + "ping?callback=?";
    _pingCacheNative(url);

    if (RisePlayerController.getIsActive()) {
      pingInterval = setTimeout(service.pingCache, PING_TIME);

      if (pingAttempt > 0) {
        Logger.logExternalMessage("cache ping attempt", pingAttempt);
        Logger.viewerDebug("cache ping attempt", pingAttempt);
      }

      pingAttempt++;
    }
  }
};

service.getCacheUrl = function (url, callback) {
  _getIsActiveAsync(() => {
    if (!isActive || !_isV2Running) {
      callback(url);
      return;
    }

    let response = baseUrl;

    response += "files";
    response += "?url=" + url;

    callback(response);
  });
};

let _pingCacheNative = function (url) {
  Logger.log("Rise Cache ping request - start " + url);

  jQuery.ajax(url, {
    complete(xhr) {
      try {
        _pingResponseStatic(xhr);
      } catch (err) {
        Logger.logException("RiseCacheController.pingCache", err);
      }
    }
  });
};

let _pingResponseStatic = function (xhr) {
  if (xhr.status === 200) {
    isActive = true;
    clearTimeout(pingInterval);

    _clearCallbacks();

    Logger.log("Rise Cache ping response-active");
  } else if (xhr.status === 404) {
    Logger.log("Rise Cache v2 is running");
    clearTimeout(pingInterval);
    _isV2Running = true;

    service.pingCache();
  } else {
    isActive = false;

    _clearCallbacks();

    Logger.log("Rise Cache is not running");
  }
};


module.exports = service;
