const Utils = require("../Utils");
const Logger = require("../Logger");
const PlaylistItemUtils = require("./PlaylistItemUtils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const PresentationParser = require("../Parser/PresentationParser");
const ConfigurationFactory = require("../ConfigurationFactory");

const WidgetController = function (placeholderInfo, playlistItem, phName, htmlName, onGadgetReady, onGadgetDone) {
  const factory = {};

  let presFrame;
  let isReady = false;
  let isPlaying = false;

  let lastDone = 0;
  let doneCounter = 0;

  const WIDGET_SCRIPT = "<script><!--\n" +
			"try {" +
			"	updateWidget('%s1', '%s2', '%s3', '%s4');" +
			"} catch(err) { parent.writeToLog('updateWidget call - %s2 - ' + err.message); }" +
			"\n//-->" +
			"</script>";

  factory.updateHtml = (presentation) => {
    if (playlistItem && PlaylistItemInfo.TYPE_WIDGET === playlistItem.type) {
      _updateHtmlWidget(presentation);
    }
  };

  let _updateHtmlWidget = (presentation) => {
    let transition = "none";

    const widgetUrl = _updateUrlParams(playlistItem.objectData);

    if (placeholderInfo) {
      transition = placeholderInfo.transition;
    }

    _addWidgetScript(presentation, widgetUrl, phName, htmlName, transition);
  };

  let _addWidgetScript = (presentation, url, containerName, htmlName, transition) => {
    const tagHtml = WIDGET_SCRIPT.replace(/%s1/g, url)
						.replace(/%s2/g, containerName)
						.replace(/%s3/g, htmlName)
						.replace(/%s4/g, transition);

    PresentationParser.addScriptTag(presentation, tagHtml);
  };

  let _updateUrlParams = (urlParams) => {
    let height, width;
    let top = 0, left = 0;
//		urlParams = removeIdParameter(urlParams);
//		urlParams = addVersionNumber(urlParams);

    if (placeholderInfo.widthUnits === "%") {
      width = parseInt((placeholderInfo.width / 100.0) * window.innerWidth);
    }
    else {
      width = parseInt(placeholderInfo.width);
    }

    if (placeholderInfo.heightUnits === "%") {
      height = parseInt((placeholderInfo.height / 100.0) * window.innerHeight);
    }
    else {
      height = parseInt(placeholderInfo.height);
    }

    if (placeholderInfo.topUnits === "%") {
      top = parseInt((placeholderInfo.top / 100.0) * window.innerHeight);
    }
    else {
      top = parseInt(placeholderInfo.top);
    }

    if (placeholderInfo.leftUnits === "%") {
      top = parseInt((placeholderInfo.left / 100.0) * window.innerWidth);
    }
    else {
      left = parseInt(placeholderInfo.left);
    }

		// '&pid=test1&up_id=test1'
//		urlParams += '&pid=' + htmlName;
    urlParams += urlParams.indexOf("?") > -1 ? "&" : "?";
    urlParams += "up_id=" + htmlName;
    urlParams += "&parent=" + encodeURIComponent(window.location.href);
    urlParams += "&up_rsW=" + width;
    urlParams += "&up_rsH=" + height;
    urlParams += "&up_rsT=" + top;
    urlParams += "&up_rsL=" + left;
    urlParams += "&viewerId=" + Utils.getUniqueId();
    urlParams += "&type=" + ConfigurationFactory.getType();
    if (ConfigurationFactory.isSharedschedule()) {
      urlParams += "&env=" + ConfigurationFactory.getEnv(true);
    }

    urlParams = urlParams.replace(/'/g, "\\'");

    return urlParams;
  };

  factory.setReady = (frame, canPlay, canStop, canPause, canReportReady, canReportDone) => {
    presFrame = frame;

    if (!isReady) {
      isReady = true;

      WidgetController.sendReadyToPlayer(playlistItem.objectData);

      if (onGadgetReady) {
        onGadgetReady();
      }
//			// if GadgetReadyCommand is null, than this is a single Gadget
//			// in placeholder (no ready command, just Start playing right away)
//			else if (!isPlaying) {
//				play(true);
//			}
    }
  };

  factory.setError = (presFrame, reason) => {
		// catch gadget error (not implemented)
  };

  factory.setDone = () => {
    if (isPlaying) {
      if (lastDone !== 0 && new Date().getTime() - lastDone < 1000) {
        lastDone = new Date().getTime();

				// -1 indicates already logged
        if (doneCounter != -1) {
          doneCounter++;

          if (doneCounter >= 10) {
            Logger.logExternalMessage("widget done loop", playlistItem.objectReference);
            Logger.viewerDebug("widget done loop", playlistItem.objectReference);

            doneCounter = -1;
          }
        }

        return;
      }
      lastDone = new Date().getTime();

      if (onGadgetDone) {
        isPlaying = false;
        onGadgetDone();
      }
      else {
        factory.stop(false);
        factory.play(false);
      }
    }
  };

  factory.play = (show) => {
    if (!isPlaying) {
      isPlaying = true;
//			if (isReady) {
      Utils.playCommand(presFrame, htmlName, show);
//			}
    }
  };

  factory.stop = (hide) => {
//		if (isPlaying) {
    isPlaying = false;
    Utils.stopCommand(presFrame, htmlName, hide);
//		}
  };

  factory.pause = (hide) => {
		// removed isPlaying check (since pause can be called on a 'paused' item to hide it)
//		if (isPlaying) {
    isPlaying = false;
    Utils.pauseCommand(presFrame, htmlName, hide);
//		}
  };

  factory.unload = () => {
    isReady = false;
  };

  factory.isReady = () => {
    return isReady;
  };

  factory.getItem = () => {
    return playlistItem;
  };

  (function () {
    PlaylistItemUtils.registerGadget(htmlName, factory);
  }());

  return factory;
};

WidgetController.sendReadyToPlayer = (widgetUrl) => {
  try {
    if (top.postToPlayer) {
      top.postToPlayer({ message: "widget-ready", widgetUrl });
    }
  } catch (err) {
    Logger.logException("WidgetController.setReady", err);
  }
};
module.exports = WidgetController;
