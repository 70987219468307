const Config = require("../Config/Config");
const ConfigurationFactory = require("../ConfigurationFactory");
const RemoteRequests = require("../Data/RemoteRequests");
const PlayerIntegration = require("../Player/PlayerIntegration");

let domEl = null;
let displayIdEl = null;
let claimIdEl = null;
let initialized = false;

function isIdNotValidError(error = Error("")) {
  return error.message === "Invalid id" ||
    error.message.includes("is not found") ||
    error.message.includes ("deleted");
}


function showMessage(el) {
  domEl.querySelector(`#${el}`).style.display = "block";
}

function hideAllMessages() {
  [
    "bad-id-message",
    "error-id-message",
    "missing-name-message"
  ].forEach(el=>domEl.querySelector(`#${el}`).style.display = "none")
}

function hideInvalidId() {
  domEl.querySelector("#bad-id-message").style.display = "none";
}

function showInvalidId() {
  showMessage("bad-id-message");
}

function showIdError() {
  showMessage("error-id-message");
}

function showMissingName() {
  showMessage("missing-name-message");
}

function showDisplayIdFields() {
  domEl.querySelector("#claim-id-entry").style.display = "none";
  domEl.querySelector("#display-id-entry").style.display = "block";
  domEl.querySelector("#use-display-id").style.display = "none";
  domEl.querySelector("#use-claim-id").style.display = "inline-block";
}

function showClaimIdFields() {
  domEl.querySelector("#claim-id-entry").style.display = "block";
  domEl.querySelector("#display-id-entry").style.display = "none";
  domEl.querySelector("#use-display-id").style.display = "inline-block";
  domEl.querySelector("#use-claim-id").style.display = "none";
}

function hideIncognitoMessageOnPlayerBased() {
  if (!ConfigurationFactory.isWebPlayerBasedRisePlayer()) {
    return;
  }

  try {
    const notice = document.querySelector('#webplayer-registration p.important-notice');
    const base = notice.parentElement;

    base.style.visibility = 'hidden'
  } catch(error) {
    console.error(error);
  }
}

function init(entryPointFn, showActivationFn) {
  if (initialized) {return;}
  initialized = true;

  domEl = document.querySelector("#webplayer-registration");
  displayIdEl = domEl.querySelector("#webplayer-display-id");
  claimIdEl = domEl.querySelector("#webplayer-claim-id");
  const displayNameEl = domEl.querySelector("#webplayer-display-name");
  const btnOkay = domEl.querySelector("#registration-ok");
  const btnActivate = domEl.querySelector("#registration-activate");
  const btnResetActivate = domEl.querySelector("#registration-reset-activate");
  const useClaimIdEl = domEl.querySelector("#use-claim-id");
  const useDisplayIdEl = domEl.querySelector("#use-display-id");

  let usingClaimId = false;

  const submitId = ( usingClaimId, id, name ) => {
    return usingClaimId ?
      RemoteRequests.createDisplayIdFromClaimId( name, id ) :
      RemoteRequests.validateDisplayId( id );
  }

  useDisplayIdEl.addEventListener("click", ()=>{
    hideAllMessages();
    showDisplayIdFields();
    usingClaimId = false;
  });

  useClaimIdEl.addEventListener("click", ()=>{
    hideAllMessages();
    showClaimIdFields();
    usingClaimId = true;
  });

  btnOkay.addEventListener("click", ()=>{
    btnOkay.disabled = true;

    const name = displayNameEl.value.trim();

    let  id = usingClaimId ? claimIdEl.value.trim() : displayIdEl.value.trim();

    id = id.length === 12 ? id.toUpperCase() : id;

    hideAllMessages();

    return submitId( usingClaimId, id, name )
    .then(displayId=>{
      ConfigurationFactory.setDisplayId(displayId);
      localStorage.removeItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_AS_CANCELLED);
      domEl.style.display = "none";
      PlayerIntegration.flushLocalStorageToDisk();
      entryPointFn();
    })
    .catch((error = {})=>{
      console.log(error);

      if (isIdNotValidError(error)) {
        showInvalidId();
      } else if (error.message === "Missing Display Name") {
        showMissingName();
      } else {
        showIdError();
      }
    })
    .finally(()=>btnOkay.disabled = false);
  });

  // Only visible when navigating from Activation screen
  btnActivate.style.display = ConfigurationFactory.getDisplayIdAsCancelled() ? "none" : "block";

  btnActivate.addEventListener("click", ()=>{
    domEl.style.display = "none";
    showActivationFn(entryPointFn);
  });

  // Only visible when cancelling display startup
  btnResetActivate.style.display = ConfigurationFactory.getDisplayIdAsCancelled() ? "block" : "none";

  btnResetActivate.addEventListener("click", ()=>{
    localStorage.removeItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_AS_CANCELLED);
    // Safety measure for ChromeOS Player
    localStorage.removeItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_KEY);
    PlayerIntegration.setDisplayId('');

    // Android Player seems to lose localStorage changes if restart is called without delay
    setTimeout(() => {
      PlayerIntegration.requestRestart(false);
    }, 1000);
  });

  displayIdEl.addEventListener("keyup", event=>{
    if (event.keyCode === 13) {
      btnOkay.click();
    }
  });

  hideIncognitoMessageOnPlayerBased();
}

function setFocus() {
  domEl.querySelector("#webplayer-display-id").focus();
}

module.exports = {
  showWebPlayerRegistrationUI(entryPointFn, showActivationFn) {
    init(entryPointFn, showActivationFn);

    try {
      displayIdEl.value = localStorage.getItem(ConfigurationFactory.WEBPLAYER_DISPLAY_ID_AS_CANCELLED);
      claimIdEl.value = localStorage.getItem("webplayer-claim-id");
    } catch(e) {console.log(e)}

    domEl.style.display = "flex";
    setFocus();
  },
  isIdNotValidError,
  hideIncognitoMessageOnPlayerBased,
  reset: () => { initialized = false; }
};
