let domEl = null;

function setTitle(title) {
  if (!domEl) {return;}

  const el = domEl.querySelector(".title");

  if (!el) {return;}

  el.textContent = title;
}

function init() {
  domEl = document.querySelector("#browser-incompatible-container");
}

module.exports = {
  showIncompatible(isSupportedBrowser = false, unavailableApiNames = []) {
    init();

    const title = isSupportedBrowser ?
      "Required APIs Unavailable" : "Browser Incompatible";
    setTitle(title);

    const textSelector = isSupportedBrowser ?
      "#should-enable-apis-text" : "#article-text";
    domEl.querySelector(textSelector).style.display = "block";
    domEl.style.display = "flex";

    if (unavailableApiNames.length) {
      domEl.querySelector("#disabled-apis-list").innerHTML =
        unavailableApiNames.join(", ");
    }

    document.querySelector("#progress").style.display="none";
  },
  showUpdatePlayer() {
    init();

    setTitle("Please update your player");

    domEl.querySelector("#please-update-player-text").style.display = "block";
    domEl.style.display = "flex";

    document.querySelector("#progress").style.display="none";
  },
  showHTTPNotSupported() {
    init();
    setTitle("HTTP Not Supported");

    domEl.querySelector("#http-fail-text").style.display = "block";
    domEl.style.display = "flex";

    document.querySelector("#progress").style.display="none";
  },
  showLowQuota(millis) {
    init();
    setTitle("Low Browser Storage Space");

    domEl.querySelector("#low-quota-text").style.display = "block";
    domEl.querySelector("#low-quota-starting").style.display = "block";
    domEl.style.display = "flex";

    document.querySelector("#progress").style.display="none";

    setTimeout(()=>{
      domEl.style.display = "none";
      document.querySelector("#progress").style.display="block";
    }, millis);
  }
};
