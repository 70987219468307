let instance, oldInstance;

const Logger = require("../Logger");

const TimerController = function () {
  const factory = {};

  const timerCommands = [];
  let timer;
  let stopPropagation = false;
//	var currentTime;

  factory.addTimerCommand = (command) => {
    timerCommands.push(command);
  };

  factory.startTimer = () => {
    factory.cancel();

    _onTimerExecute();

    timer = setInterval(_onTimerExecute, 1000);
  };

  let _onTimerExecute = () => {
    // [AD] Reset stopPropagation on every cycle
    stopPropagation = false;

//		currentTime = new Date().getTime();
    for (let i = 0; i < timerCommands.length; i += 1) {
      if (stopPropagation) {
        stopPropagation = false;
        break;
      }

      timerCommands[i]();
    }
  };

  factory.stopPropagation = () => {
    stopPropagation = true;
  };

//	public long getCurrentTime = () => {
//		return currentTime;
//	}

  factory.cancel = () => {
    if (timer) {
      clearInterval(timer);

      timer = null;
    }
  };

  return factory;
};

TimerController.getInstance = () => {
  try {
    if (!instance) {
      instance = new TimerController();
    }
  } catch (err) {
    Logger.logException("TimerController.getInstance", err);
  }
  return instance;
};

TimerController.createInstance = () => {
  oldInstance = instance;
  try {
    instance = new TimerController();
  } catch (err) {
    Logger.logException("TimerController.createInstance", err);
  }
  return instance;
};

TimerController.destroyOldInstance = () => {
  if (oldInstance) {
    oldInstance.cancel();
    oldInstance = null;
  }
};


module.exports = TimerController;
