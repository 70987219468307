const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const PlayerIntegration = require("../Player/PlayerIntegration");

const NIO_5510_PRODUCT_NAME = "W55PNC-CS1386A-G";

const CONTENT_SENTINEL_CACHE_NAME = "content-sentinel-cache";
const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const LOG_DELAY = 3 * SECONDS;
const MIN_INTERVAL_BETWEEN_WORKAROUND_ACTION = 2 * HOURS;
const QUOTA_EXCEEDED_WORKAROUND_TIMESTAMP = "quota-exceeded-workaround-timestamp";

const service = {};

const saveWorkaroundTimestamp = () => {
  // Might fail if storage won't be written anymore.
  try {
    const timestamp = String( Date.now() );
    localStorage.setItem( QUOTA_EXCEEDED_WORKAROUND_TIMESTAMP, timestamp );

    const confirmedTimestamp = localStorage.getItem( QUOTA_EXCEEDED_WORKAROUND_TIMESTAMP )

    return timestamp === confirmedTimestamp;
  } catch( error ) {
    Logger.viewerError( "E999999999", "unable to set quota exceeded timestamp", JSON.stringify({
      error: error && ( error.stack || error.message )
    }));

    return false;
  }
};

service.handleQuotaExceeded = () => {
  if( ConfigurationFactory.isEmbed() || ConfigurationFactory.getProductName() !== NIO_5510_PRODUCT_NAME ) {
    return Promise.resolve();
  }

  const timestamp = Number( localStorage.getItem(QUOTA_EXCEEDED_WORKAROUND_TIMESTAMP) || 0 );
  if( Date.now() - timestamp < MIN_INTERVAL_BETWEEN_WORKAROUND_ACTION ) {
    return Promise.resolve();
  }

  const savedTimestamp = saveWorkaroundTimestamp();
  if( !savedTimestamp ) {
    Logger.viewerImportant( "quota exceeded workaround aborted", "could not save workaround timestamp" );
    return Promise.resolve();
  }

  return service.applyWorkaround();
};

service.applyWorkaround = () => {
  Logger.viewerImportant( "quota exceeded workaround invoked", "clearing cache and restarting" );

  return new Promise(resolve => setTimeout(resolve, LOG_DELAY))
  .then(() => PlayerIntegration.requestReboot())
  .catch( error => {
    Logger.viewerError( "E999999999", "quota exceeded workaround error", JSON.stringify({
      error: error && ( error.stack || error.message )
    }));
  });
}

module.exports = service;
