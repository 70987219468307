const ConfigurationFactory = require("./ConfigurationFactory");
const BrowserCompatibilityService = require("./BrowserCompatibilityService");
const BrowserCompatibilityUIController = require("./UI/BrowserCompatibilityUIController");
const errorCode = "E000000218";
const TIMEOUT_MS = 5000;
const MIN_REQUIRED_BYTES = 100000000;

const logger = require("./Logger");

function isForcedFailure() {
  const forceFail = "forcestoragequotafailure=true";

  return location && location.href && location.href.includes(forceFail);
}

function lowQuotaCheck(fail = false) {
  if (!navigator || !navigator.storage ||
    typeof navigator.storage.estimate !== "function") {
    return Promise.resolve();
  }

  return navigator.storage.estimate()
  .then((est = {}) => {
    logger.viewerInfo("available quota", JSON.stringify(est));

    return est;
  })
  .then(est=>est.quota < MIN_REQUIRED_BYTES || fail ?
    failure(est) :
    Promise.resolve());
}

function failure(estimate) {
  BrowserCompatibilityUIController.showLowQuota(TIMEOUT_MS);
  logger.viewerError(errorCode, "low storage quota", JSON.stringify(estimate));

  return new Promise(res=>setTimeout(res, TIMEOUT_MS));
}

module.exports = {
  checkStorageQuota() {
    const needsCheck = BrowserCompatibilityService.needsCompatibilityCheck() ||
      module.exports.isForcedFailure();

    if (!needsCheck) {return Promise.resolve();}

    return lowQuotaCheck(module.exports.isForcedFailure());
  },
  isForcedFailure
}
