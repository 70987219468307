const PersistentNotificationType = require("../Info/PersistentNotificationType");

class PersistentNotificationPanel {
  constructor() {
    this.joinCode = "";
    this.isModerated = false;
    this.container = document.getElementById("persistent-notification");
  }

  joinCodeHtml() {
    let html = "<div id=\"persistent-notification-join-code\">";
    for (const char of this.joinCode.split("")) {
      html += `<span class="code-char">${char}</span>`;
    }
    return html + "</div>";
  }

  _render() {
    let title, text, messageTitle, messageText, footerIcon, header;
    let showJoinCode = !this.isModerated;
    const paused = this.notificationType === PersistentNotificationType.SCREEN_SHARING_PAUSED;
    const panelRight = document.getElementById("persistent-notification-panel-right");
    const htmlHeaderLeft = document.querySelector("#persistent-notification-panel-left > .persistent-notification-header");
    const htmlTitleLeft = document.querySelector("#persistent-notification-panel-left > .persistent-notification-title");
    const htmlTextLeft = document.querySelector("#persistent-notification-panel-left > .persistent-notification-text");
    const htmlCode = document.querySelector("#persistent-notification-panel-left > .persistent-notification-code");
    const htmlFooter = document.getElementById("persistent-notification-footer");
    const htmlMessageTitle = document.getElementById("persistent-notification-message-title");
    const htmlMessageText = document.getElementById("persistent-notification-message-text");
    const htmlIcon = document.getElementById("persistent-notification-icon");
    const htmlTitleRight = document.querySelector("#persistent-notification-notice > .persistent-notification-title");
    const htmlTextRight = document.querySelector("#persistent-notification-notice > .persistent-notification-text");

    if (this.joinCode) {
      if (this.notificationType === PersistentNotificationType.SCREEN_SHARING_LOBBY || paused) {
        showJoinCode = this.isModerated;

        if (this.isModerated) {
          title = "Join the Room";
          text = "Go to <strong>risevision.com/join</strong> on your browser and enter this code to join the room.";

          if (paused) {
            htmlTitleRight.innerHTML = "PAUSED";
            htmlTextRight.innerHTML = "The screen has been paused, to continue sharing, the presenter needs to click resume.";
          }

          messageText = "Rise Vision";
        } else {
          htmlTitleRight.innerHTML = "PAUSED";
          htmlTextRight.innerHTML = "The screen has been paused, to continue sharing, the presenter needs to click resume.";
        }
      } else {
        if (this.isModerated) {
          title = "Screen sharing is available";
          text = "Wait for a moderator to join the channel.";
        } else {
          header = "STANDARD";
          title = "Connect with web or app";
          text = "Go to <strong>risevision.com/join</strong> on your browser and enter this code to join the room.";
        }

        if (this.notificationType === PersistentNotificationType.NO_PRESENTATION_SCHEDULED
          || this.notificationType === PersistentNotificationType.NO_SCHEDULE) {
          messageTitle = "Scheduled digital content will resume when screen sharing ends.";
          messageText = "No scheduled content? Schedule your digital signage content now to get the most out of your display.";
        } else if (this.notificationType === PersistentNotificationType.NO_DISPLAY_LICENSE) {
          messageTitle = "No digital signage content is scheduled on this display.";
          messageText = "Want to display content? Add a digital signage license to this display.";
        }
      }

      footerIcon = "";
    } else if (this.notificationType === PersistentNotificationType.NO_PRESENTATION_SCHEDULED
      || this.notificationType === PersistentNotificationType.NO_SCHEDULE) {
      title = "No Content Scheduled";
      text = "<p>There is currently no digital signage content scheduled on this display.</p><p>Please schedule content to display here.</p>";
      // messageTitle = "Interested in screen sharing?";
      // messageText = "Add a screen sharing license to this display.";
      // footerIcon = "screen-sharing";
    } else if (this.notificationType === PersistentNotificationType.NO_TEMPLATE_SCHEDULED) {
      title = "There are no updates to show right now.";
      text = "Have a great day!";
    } else if (this.notificationType === PersistentNotificationType.NO_DISPLAY_LICENSE) {
      title = "Missing Display License";
      text = "<p>No digital signage content is scheduled on this display.</p><p>Want to display content? Add a digital signage license to this display.</p>";
    }

    if (htmlHeaderLeft) htmlHeaderLeft.innerHTML = header || "";
    if (htmlTextLeft) htmlTitleLeft.innerHTML = title || "";
    if (htmlTextLeft) htmlTextLeft.innerHTML = text || "";
    if (htmlMessageTitle) htmlMessageTitle.innerHTML = messageTitle || "";
    if (htmlMessageText) htmlMessageText.innerHTML = messageText || "";
    if (this.joinCode && showJoinCode) {
      htmlCode.innerHTML = this.joinCodeHtml();
      htmlCode.style.display = "block";
    } else {
      htmlCode.style.display = "none";
    }
    panelRight.style.display = paused ? "flex" : "none";
    if (footerIcon) {
      if (htmlFooter) htmlFooter.classList.add("footer-icon");
      if (htmlIcon) {
        if (footerIcon === "screen-sharing") {
          htmlIcon.classList.add("green-icon");
          htmlIcon.innerHTML = "<img src=\"images/screen-sharing-green.svg\" width=\"24\" height=\"24\">";
        } else {
          htmlIcon.classList.remove("green-icon");
          htmlIcon.innerHTML = "";
        }
      }
    } else {
      if (htmlFooter) htmlFooter.classList.remove("footer-icon");
      if (htmlIcon) htmlIcon.innerHTML = "";
    }
  }

  isScreenSharingLobby() {
    return this.notificationType === PersistentNotificationType.SCREEN_SHARING_LOBBY;
  }

  isScreenSharingPaused() {
    return this.notificationType === PersistentNotificationType.SCREEN_SHARING_PAUSED;
  }

  setJoinCode(joinCode) {
    this.joinCode = joinCode;

    this._render();
  }

  setIsModerated(isModerated) {
    this.isModerated = isModerated;

    this._render();
  }

  show(notificationType) {
    if (this.isScreenSharingLobby() || this.isScreenSharingPaused()) {
      return;
    }

    this.notificationType = notificationType;

    this.container.style.display = "block";

    this._render();
  }

  hide() {
    if (this.isScreenSharingLobby() || this.isScreenSharingPaused()) {
      return;
    }

    this.container.style.display = "none";
  }

  clear(hide) {
    this.notificationType = null;

    if (hide) {
      this.hide();
    }
  }
}

PersistentNotificationPanel.show = (notificationType) => { return _getInstance().show(notificationType); };
PersistentNotificationPanel.hide = () => { return _getInstance().hide(); };
PersistentNotificationPanel.clear = (hide) => { return _getInstance().clear(hide); };
PersistentNotificationPanel.setJoinCode = (joinCode) => { _getInstance().setJoinCode(joinCode); };
PersistentNotificationPanel.setIsModerated = (isModerated) => { _getInstance().setIsModerated(isModerated); };
PersistentNotificationPanel.isScreenSharingPaused = () => { return _getInstance().isScreenSharingPaused(); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new PersistentNotificationPanel();
  }

  return instance;
}

module.exports = PersistentNotificationPanel;
