const Config = require("../Config/Config");

function validateDisplayId( id ) {
  if (!id) {return Promise.reject(Error("Missing id"));}

  const validationHost = "https://www.googleapis.com";
  const validationPath = "/storage/v1/b/risevision-display-notifications/o/DISPLAYID%2Fcontent.json";
  const validationParams = "?fields=size";
  const url = `${validationHost}${validationPath}${validationParams}`;

  return fetch(url.replace("DISPLAYID", id))
  .then(resp=>resp.ok ? resp.json() :
    resp.status === 404 || resp.status === 401 ? Promise.reject(Error("Invalid id")) :
    Promise.reject(Error("Display ID response error")))
  .then(json=>!json || !json.size || json.error ?
    Promise.reject(Error("Display ID error")) :
    json.size === "2" ?
    Promise.reject(Error("Display has been deleted")) :
    id
  );
}

function createDisplayIdFromClaimId( name, id ) {
  if (!name) {return Promise.reject(Error("Missing Display Name"))}

  const claimIdPath = "/v2/viewer/display/DID/register?name=NM";
  const url = Config.SERVER_URL.concat(claimIdPath).replace("DID", id).replace("NM", name);

  try {
    localStorage.setItem("webplayer-claim-id", id);
  } catch(e) {console.log(e)}

  return fetch(url)
  .then(resp=>resp.ok ? resp.json() : Promise.reject(Error("Claim ID response error")))
  .then(json=>{
    if (!json || !json.status || json.status.code !== 0 || json.error) {
      const errorMessage = (json && json.status && json.status.message) || "Claim ID error";
      return Promise.reject(Error(errorMessage));
    }

    return json.displayId;
  });
}

module.exports = { validateDisplayId, createDisplayIdFromClaimId };