const Logger = require("../Logger");
const HtmlParser = require("./HtmlParser");
// const _ = require('underscore');

const factory = {};

const SUPPORTED_PLACEHOLDER_ITEMS = [
  "widget",
];

const htmlTag = "<html";
const htmlEndTag = "</html>";
const headStartTag = "<head";
const headEndTag = "</head>";
const linkTag = "<link";
const scriptStartTag = "<script";
const scriptEndTag = "</script>";
const bodyTag = "<body";
const bodyEndTag = "</body>";

const divTag = "<div";
const divEndTag = "</div>";

const dataVariableParam = "presentationdata";
const dataVariableString = "" +
  "<script language=\"javascript\">\n" +
  "\t<!--\n" +
  "\tvar presentationData = %data%;\n" +
  "\t//-->\n" +
  "\t</script>";

const relParam = " rel=";
const relHelpLinkParam = "help";
const hrefParam = " href=";

const idParam = " id=";
const placeholderParam = " placeholder=";
//	var urlParam = ' src=';
const styleParam = " style=";
const widthParam = "width";
const heightParam = "height";
const topParam = "top";
const leftParam = "left";
const zIndexParam = "z-index";
const backgroundParam = "background";
const backgroundSizeParam = "background-size";
const backgroundScaleValue = "contain";
//	var overflowParam = 'overflow';

const idPrefix = "ph";

// AD - overflow:hidden removed
//	var nativeStyleString = 'position:absolute;overflow:hidden;';
const nativeStyleString = "position:absolute;";

const HIDE_SCRIPT = "<script>try {" +
  "setVisible(\"%s1\", false);" +
  "} catch(err) { parent.writeToLog(\"setVisible call - %s1 - \" + err.message); }" +
  "</script>";


factory.parseHelpLink = function (htmlString) {
  let start, end;

  start = htmlString.toLowerCase().indexOf(linkTag);
  while (start !== -1) {
    // search for the end of the tag
    end = htmlString.indexOf(">", start);

    if (relHelpLinkParam === HtmlParser.stripGarbage(HtmlParser.getPropertyValue(
        htmlString.substring(start, end + 1), relParam)).toLowerCase()) {
      return HtmlParser.stripGarbage(HtmlParser.getPropertyValue(
        htmlString.substring(start, end + 1), hrefParam));
    }

    start = htmlString.toLowerCase().indexOf(linkTag, end);
  }

  return "";
};

factory.parseBodyStyle = function (presentation, htmlString) {
  const tokens = htmlString.split(";");
  for (let x = 0; x < tokens.length; x++) {
    if (tokens[x].indexOf(":") !== -1) {
      const param = tokens[x].split(":")[0];
      const value = tokens[x].substring(tokens[x].indexOf(":") + 1).trim();

      if (param.equalsIgnoreCase(widthParam)) {
        presentation.width = HtmlParser.getIntValue(value);
        presentation.widthUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(heightParam)) {
        presentation.height = HtmlParser.getIntValue(value);
        presentation.heightUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(backgroundParam)) {
        presentation.backgroundStyle = value;
      } else if (param.equalsIgnoreCase(backgroundSizeParam)) {
        presentation.backgroundScaleToFit = value ===
          backgroundScaleValue;
      }
    }
  }
};

const _cleanPlaceholderData = function (placeholders) {
  let items, j;
  for (let i = 0; i < placeholders.length; i++) {
    HtmlParser.parseBooleanProperty(placeholders[i],
      "recurrenceAbsolute");
    HtmlParser.parseBooleanProperty(placeholders[i], "timeDefined");
    HtmlParser.parseBooleanProperty(placeholders[i], "visibility");

    HtmlParser.parseIntProperty(placeholders[i],
      "recurrenceDayOfMonth");
    HtmlParser.parseIntProperty(placeholders[i],
      "recurrenceDayOfWeek");
    HtmlParser.parseIntProperty(placeholders[i],
      "recurrenceFrequency");
    HtmlParser.parseIntProperty(placeholders[i],
      "recurrenceMonthOfYear");
    HtmlParser.parseIntProperty(placeholders[i],
      "recurrenceWeekOfMonth");

    if (placeholders[i].items) {
      items = placeholders[i].items;
      for (j = 0; j < items.length; j++) {
        if (SUPPORTED_PLACEHOLDER_ITEMS.indexOf(items[j].type) === -1) {
          factory.hasLegacyItems = true;
        }
        HtmlParser.parseBooleanProperty(items[j], "playUntilDone");
        HtmlParser.parseBooleanProperty(items[j],
          "recurrenceAbsolute");
        HtmlParser.parseBooleanProperty(items[j], "timeDefined");

        HtmlParser.parseIntProperty(items[j], "duration", 10);
        HtmlParser.parseIntProperty(items[j], "index");

        HtmlParser.parseIntProperty(items[j], "recurrenceDayOfMonth");
        HtmlParser.parseIntProperty(items[j], "recurrenceDayOfWeek");
        HtmlParser.parseIntProperty(items[j], "recurrenceFrequency");
        HtmlParser.parseIntProperty(items[j], "recurrenceMonthOfYear");
        HtmlParser.parseIntProperty(items[j], "recurrenceWeekOfMonth");
      }
    }
  }
};

factory.parsePresentationData = function (presentation) {
  let start, end;
  const htmlString = presentation.layout;

  start = htmlString.toLowerCase().indexOf(dataVariableParam);
  if (start !== -1) {
    // find if the next character is a quote or a bracket
    if (htmlString.indexOf("'", start) === -1 ||
      htmlString.indexOf("{", start) < htmlString.indexOf("'", start)
    ) {
      start = htmlString.indexOf("{", start);

      end = htmlString.lastIndexOf("};");

      // increment end only if end string was found
      if (end !== -1) {
        // do not include the ;
        end = end + 1;
      }
    } else {
      const quote = HtmlParser.getNextQuote(htmlString.substring(start));

      start = htmlString.indexOf(quote, start) + 1;
      end = htmlString.indexOf(quote, start);
      while (end !== -1 && htmlString.charAt(end - 1) === "\\") {
        end = htmlString.indexOf(quote, end + 1);
      }
    }

    if (end !== -1) {
      let json = htmlString.substring(start, end);

      json = json.replace(/\\\'/g, "'");

      let dataObject = JSON.parse(json);

      dataObject = dataObject && dataObject.presentationData;

      if (!dataObject) {
        return;
      }

      presentation.hidePointer =
        HtmlParser.getBooleanValue(dataObject.hidePointer);
      presentation.donePlaceholder = dataObject.donePlaceholder;

      _cleanPlaceholderData(dataObject.placeholders);

      presentation.placeholders = dataObject.placeholders;
    }
  }
};

factory.parseStyle = function (placeholder, htmlString) {
  const tokens = htmlString.split(";");
  for (let x = 0; x < tokens.length; x++) {
    if (tokens[x].indexOf(":") !== -1) {
      const param = tokens[x].split(":")[0].trim();
      const value = tokens[x].substring(tokens[x].indexOf(":") + 1).trim();

      if (param.equalsIgnoreCase(widthParam)) {
        placeholder.width = HtmlParser.getFloatValue(value);
        placeholder.widthUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(heightParam)) {
        placeholder.height = HtmlParser.getFloatValue(value);
        placeholder.heightUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(topParam)) {
        placeholder.top = HtmlParser.getFloatValue(value);
        placeholder.topUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(leftParam)) {
        placeholder.left = HtmlParser.getFloatValue(value);
        placeholder.leftUnits = HtmlParser.getUnits(value);
      } else if (param.equalsIgnoreCase(zIndexParam)) {
        placeholder.zIndex = HtmlParser.getIntValue(value);
      } else if (param.equalsIgnoreCase(backgroundParam)) {
        placeholder.backgroundStyle = value;
      } else if (param.equalsIgnoreCase(backgroundSizeParam)) {
        placeholder.backgroundScaleToFit = value ===
          backgroundScaleValue;
      }
    }
  }
};

factory.parseDiv = function (htmlString) {
  let placeholder = null;

  const id = HtmlParser.getPropertyValue(htmlString, idParam);
  if (id && HtmlParser.getPropertyValue(htmlString, placeholderParam)) {
    placeholder = {};

    placeholder.id = id;

    factory.parseStyle(placeholder, HtmlParser.stripOuterGarbage(
      HtmlParser.getPropertyValue(htmlString, styleParam)));
  }
  return placeholder;
};

const _removeDeletedPlaceholders = function (placeholders) {
  // remove deleted placeholders from the list
  for (let i = 0; i < placeholders.length; i++) {
    if (placeholders[i].deleted) {
      placeholders.splice(i, 1);
      i--;
    }
  }
};

factory.parsePlaceholders = function (presentation, htmlString) {
  let start, end;
  let found, i, j;
  presentation.placeholders = presentation.placeholders || [];
  const placeholders = presentation.placeholders;
  const newPlaceholders = [];

  start = htmlString.indexOf(divTag);
  while (start !== -1) {
    // search for the end of the div
    end = htmlString.indexOf(">", start);

    const placeholder = factory.parseDiv(htmlString.substring(start,
      end + 1));

    if (placeholder) {
      newPlaceholders.push(placeholder);
    }

    start = htmlString.indexOf(divTag, end);
  }

  let listChanged = false;
  for (i = 0; i < placeholders.length; i++) {
    found = false;
    for (j = 0; j < newPlaceholders.length; j++) {
      if (newPlaceholders[j].id === placeholders[i].id) {
        found = true;
        break;
      }
    }
    if (!found) {
      listChanged = true;
      placeholders[i].deleted = true;
    }
  }

  _removeDeletedPlaceholders(placeholders);

  for (j = 0; j < newPlaceholders.length; j++) {
    found = false;
    for (i = 0; i < placeholders.length; i++) {
      if (newPlaceholders[j].id === placeholders[i].id) {
        found = true;
        placeholders[i].width = newPlaceholders[j].width;
        placeholders[i].widthUnits = newPlaceholders[j].widthUnits;
        placeholders[i].height = newPlaceholders[j].height;
        placeholders[i].heightUnits = newPlaceholders[j].heightUnits;
        placeholders[i].left = newPlaceholders[j].left;
        placeholders[i].leftUnits = newPlaceholders[j].leftUnits;
        placeholders[i].top = newPlaceholders[j].top;
        placeholders[i].topUnits = newPlaceholders[j].topUnits;
        placeholders[i].zIndex = newPlaceholders[j].zIndex;
        placeholders[i].backgroundStyle = newPlaceholders[j].backgroundStyle;
        placeholders[i].backgroundScaleToFit = newPlaceholders[j].backgroundScaleToFit;
      }
    }

    if (!found) {
      listChanged = true;
      placeholders.push(newPlaceholders[j]);
    }
  }

  return listChanged;
};

factory.parsePresentation = function (presentation) {
  let start, end;
  const htmlString = presentation.layout;

  factory.hasLegacyItems = false;

  if (!htmlString) {
    return;
  }

  start = htmlString.toLowerCase().indexOf(htmlTag);
  end = htmlString.toLowerCase().indexOf(htmlEndTag, start);
  if (start === -1 || end === -1) {
    return;
  }

  // process head for help link
  start = htmlString.toLowerCase().indexOf(headStartTag, start);
  end = htmlString.toLowerCase().indexOf(headEndTag, start);

  if (start === -1 || end < start) {
    return;
  }

  presentation.helpURL = factory.parseHelpLink(htmlString.substring(
    start, end + 1));

  // process body next
  start = htmlString.toLowerCase().indexOf(bodyTag, start);
  end = htmlString.indexOf(">", start);

  if (start === -1 || end < start) {
    return;
  }

  factory.parseBodyStyle(presentation, HtmlParser.stripOuterGarbage(
    HtmlParser.getPropertyValue(htmlString.substring(start, end +
      1), styleParam)));

  end = htmlString.toLowerCase().indexOf(bodyEndTag, start);
  if (start === -1 || end === -1) {
    return;
  }

  // process data
  factory.parsePresentationData(presentation);

  factory.parsePlaceholders(presentation, htmlString.substring(
    start, end + bodyEndTag.length));

  Logger.log("parse presentation result", presentation);
};

// ======================================================================
// Update Presentation functionality beings:

factory.updatePresentationObject = function (htmlString,
  modifiedDataVariableString) {
  let start, end = -1;

  start = htmlString.toLowerCase().indexOf(dataVariableParam);
  if (start === -1) {
    end = htmlString.indexOf(htmlEndTag);
    start = end;

    // Add spacing for the data variable
    modifiedDataVariableString = "" +
      "\n<!-- Warning - Editing the Presentation Data Object incorrectly may result in the Presentation not functioning correctly -->" +
      "\n\t" + modifiedDataVariableString + "" +
      "\n<!-- No scripts after this point -->" +
      "\n";
  } else {
    if (htmlString.indexOf("'", start) === -1 || htmlString.indexOf(
        "{", start) < htmlString.indexOf("'", start)) {
      start = htmlString.indexOf("{", start);

      end = htmlString.lastIndexOf("};");

      // increment end only if end string was found
      if (end !== -1) {
        end = end + 2;
      }
    } else {
      const quote = HtmlParser.getNextQuote(htmlString.substring(
        start));

      start = htmlString.indexOf(quote, start);
      end = htmlString.indexOf(quote, start + 1);
      while (end !== -1 && htmlString.charAt(end - 1) === "\\") {
        end = htmlString.indexOf(quote, end + 1);
      }
      end++;
    }

    if (end !== -1) {
      start = htmlString.lastIndexOf(scriptStartTag, start);
      end = htmlString.lastIndexOf(scriptEndTag) + scriptEndTag.length;
    }
  }

  if (start !== -1 && end !== -1) {
    htmlString = HtmlParser.updateInnerString(htmlString, start, end,
      modifiedDataVariableString);
  }

  return htmlString;
};


// ======================================================================
// ------------------------- Presentation Writer ------------------------

factory.addHeaderScripts = (presentation, headerScript) => {
  let myHtml = presentation.layout;

//		if (myHtml.contains(htmlTag)) {
//			myHtml.replaceFirst(htmlTag, htmlTag + " manifest=\"offline.manifest\"");
//		}

  let headEnd = myHtml.toLowerCase().indexOf(headStartTag);

  if (headEnd !== -1) {
    headEnd = myHtml.indexOf(">", headEnd);
//			int headEnd = myHtml.indexOf(headEndTag);

    if (headEnd != -1) {
      headEnd++;
//				String modifiedHeader = headerScript.replace("%s", containerName);
      myHtml = myHtml.substring(0, headEnd) + headerScript + myHtml.substring(headEnd, myHtml.length);
    }
  }

  presentation.layout = myHtml;
};

factory.removePresentationObject = (presentation) => {
  presentation.layout = factory.updatePresentationObject(presentation.layout, "");
};

factory.addScriptTag = (presentation, tagHtml) => {
  let myHtml = presentation.layout;
  const bodyEnd = myHtml.lastIndexOf(bodyEndTag);

  if (bodyEnd != -1) {
    myHtml = myHtml.substring(0, bodyEnd) + tagHtml + myHtml.substring(bodyEnd, myHtml.length);
  }

  presentation.layout = myHtml;
};

factory.hidePlaceholder = (presentation, placeholderId) => {
  let myHtml = presentation.layout;
  const bodyEnd = myHtml.lastIndexOf(bodyEndTag);

  if (bodyEnd != -1) {
    const modifiedTextScript = HIDE_SCRIPT.replace(/%s1/g, placeholderId);
    myHtml = myHtml.substring(0, bodyEnd) + modifiedTextScript + myHtml.substring(bodyEnd, myHtml.length);
  }

  presentation.layout = myHtml;
};


module.exports = factory;
