const service = {};

let count = 0;

service.render = () => {
  service.containerName = "c" + count;
  count++;

  _createContainer();

  RiseVision.Viewer.UI.ViewerZoomController.resetContainers();

  return service.containerName;
};

let _createContainer = () => {
  window.createContainer(service.containerName);
};

module.exports = service;
