"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.retry = void 0;
var jitterMax = 1000;

var jitter = function jitter() {
  return Math.random() * jitterMax;
};

var delay = function delay(ms) {
  return new Promise(function (res) {
    setTimeout(res, ms + jitter());
  });
};

var retry = function retry(fn) {
  var retries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  var retryDelay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2000;
  return fn()["catch"](function (err) {
    console.error("".concat(err.message, ". ").concat(retries, " retries remaining"));
    return retries <= 0 ? Promise.reject(err) : delay(retryDelay).then(function () {
      return retry(fn, retries - 1, retryDelay * 2);
    });
  });
};

exports.retry = retry;