const LocalMessaging = require("../LocalMessaging");

const service = {};

service.sendCurrentScheduleItem = function (item) {
  const msMessage = {
    topic: "playing-item",
    item
  };
  LocalMessaging.write(msMessage);
};

module.exports = service;
