/* eslint-disable class-methods-use-this */
const Global = require("../Config/Global");
const ConfigurationFactory = require("../ConfigurationFactory");

const TIMER_LABEL = "%timer%";
const DISPLAY_ID_LABEL = "%display-id%";


class NotificationType {
  constructor(message, fullscreen, time, delay) {
    this.message = message;
    this.fullscreen = fullscreen;
    this.time = time;
    this.delay = delay;
  }

  getMessage(count) {
    const msg = this.message.replace(DISPLAY_ID_LABEL, ConfigurationFactory.getDisplayId());
    if (typeof count === "number") {
      return msg.replace(TIMER_LABEL, count.toString());
    } else {
      return msg;
    }
  }

  getFullscreen() {
    return this.fullscreen;
  }

  getTime() {
    return this.time;
  }

  getDelay() {
    return this.delay;
  }

  _getCompanyLogoURL() {
    const logoFile = RiseVision.Viewer.Data.ViewerDataInfo.getCompanyData().brandingLogoFile;
    return logoFile && "https://storage.googleapis.com/" + logoFile;
  }

  getIcon() {
    if (this === NotificationType.NO_TEMPLATE_SCHEDULED) {
      return this._getCompanyLogoURL();
    } else {
      return undefined;
    }
  }
}

NotificationType.USE_GOOGLE_CHROME = new NotificationType("For the Best Viewing Experience Please Use Google Chrome", false, 10, Global.ONE_SECOND_DELAY);
NotificationType.MULTIPLE_DISPLAY_INSTANCES = new NotificationType("Multiple Display Instances found in this Browser session");
NotificationType.NO_PRESENTATION_SCHEDULED = new NotificationType("A Presentation has not been assigned to this Schedule.", true);
NotificationType.NO_TEMPLATE_SCHEDULED = new NotificationType("There are no updates to show right now.<br/>Have a great day!", true);
NotificationType.SERVER_CONNECTION_FAILED = new NotificationType(`An error occurred trying to connect to the Server. Retrying in ${TIMER_LABEL} seconds...`, false, 60, Global.ONE_SECOND_DELAY);
NotificationType.COOKIES_DISABLED = new NotificationType("Cookies must be enabled in order for the Display to receive updates.");
NotificationType.DISPLAY_ID_NOT_FOUND = new NotificationType(`The Display ID, <span style="color:red;font-weight:strong;">${DISPLAY_ID_LABEL}</span> was not found.`);
NotificationType.DISPLAY_ID_DUPLICATE = new NotificationType(`This Display ID, <span style='color:red;font-weight:strong;'>${DISPLAY_ID_LABEL}</span> is in use by another Display.`);
NotificationType.DISPLAY_ID_NULL = new NotificationType("Display ID not specified.");

module.exports = NotificationType;
