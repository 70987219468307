const HtmlParser = require("./HtmlParser");

const factory = {};

const _parseDistribution = function (placeholders, distributionList) {
  let j, placeholder;

  for (let i = 0; i < distributionList.length; i++) {
    const id = distributionList[i].itemId;

    if (id) {
      const tokens = id.split("#");
      const displaysList = distributionList[i].displayIds;

      if (tokens.length > 0) {
        const placeholderId = tokens[0];

        for (j = 0; j < placeholders.length; j++) {
          placeholder = placeholders[j];

          if (placeholder.id === placeholderId) {
            if (tokens.length === 1) {
              placeholder.distribution = displaysList;
              placeholder.distributeToAll = false;
            } else {
              const itemNumber = HtmlParser.getIntValue(tokens[1]);
              if (itemNumber !== -1 &&
                itemNumber < placeholder.items.length) {
                placeholder.items[itemNumber].distribution =
                  displaysList;
                placeholder.items[itemNumber].distributeToAll = false;
              }
            }
            break;
          }
        }
      }
    }
  }
};

factory.parseDistribution = function (presentation) {
  if (presentation.placeholders && presentation.distribution) {
    _parseDistribution(presentation.placeholders,
      presentation.distribution);
  }
};

module.exports = factory;
