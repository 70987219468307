/* eslint-disable no-continue */

const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const Utils = require("../Utils");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const TimelineParser = require("../Parser/TimelineParser");
const ScheduleRenderer = require("../Renderer/ScheduleRenderer");
const NavigationUIController = require("../UI/NavigationUIController");
const PresentationController = require("./PresentationController");
const HTMLTemplateController = require("./HTMLTemplateController");
const TimerController = require("./TimerController");
const PlayerMessaging = require("../Player/PlayerMessaging");

const ScheduleController = function (schedule, htmlName, containerName,
  onScheduleReady, onScheduleDone) {
  const factory = {};

  let status;

  const _observers = [];

  const items = [];
  const presentations = [];
  let currentItem = -1, nextItem = -1, lastItem = -1;
  let doneReceived = false;

  let timerController = TimerController.getInstance();
  let itemTime = -1;
  let scheduleTime = -1;

  let isPlaying = false;

  let lastPlayed;
  let backward = false;

  const _setCurrentItem = (index) => {
    currentItem = index;

    localStorage.setItem("ScheduleController.lastPlayed", index);

    Logger.setEndpointLoggerContentFields({
      scheduleItemUrl: items[index] && items[index].type === "url" && items[index].presentationType !== "HTML Template" ? items[index].objectReference : null});
  };

  factory.init = () => {
    lastPlayed = parseInt(localStorage.getItem("ScheduleController.lastPlayed"));
    lastPlayed = isNaN(lastPlayed) ? -1 : lastPlayed;
    lastPlayed++;

    if (schedule) {
      _bindSchedule();
    }

    return presentations.length;
  };

  let _bindSchedule = function () {
    ScheduleRenderer.render(htmlName, containerName);

    // Removes items with blank durations
    let itemCount = 0;
    for (let i = 0; i < schedule.items.length; i++) {
      const item = schedule.items[i];

      if ((!item.duration || item.duration < 1) && !item.playUntilDone) {
        continue;
      }

      if (ConfigurationFactory.isSharedschedule()) {
        Logger.logSharedScheduleError("Showing Shared Schedule Item", item.objectReference);
        Logger.viewerInfo(`Showing Shared Schedule Item ${item.objectReference}`);

        if (item.type === PlaylistItemInfo.TYPE_PRESENTATION && item.presentationType !== PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
          Logger.logSharedScheduleError("Showing legacy shared schedule", item.objectReference);
          Logger.viewerInfo(`Showing legacy shared schedule ${item.objectReference}`);

          const incompatible = ViewerDataInfo.getSharedScheduleIncompatible(item);

          if (incompatible.length !== 0) {
            Logger.logSharedScheduleError("Unable to show Presentation Item", incompatible.join("|"));
            Logger.viewerError("E000000001", "Unable to show Presentation Item", incompatible.join("|"));
            continue;
          }
        }
      }

      if (item.type === PlaylistItemInfo.TYPE_URL) {
        if (!item.objectReference) {
          if (ConfigurationFactory.isSharedschedule()) {
            // Skip blank URLs
            Logger.logSharedScheduleError("Unable to show content with blank URL", item.objectReference);
            Logger.viewerError("E000000002", "Unable to show content with blank URL", item.objectReference);

            continue;
          }
        } else if (item.objectReference.startsWith("http://")) {
          if (ConfigurationFactory.isSharedschedule()) {
            // Skip http:// URLs
            Logger.logSharedScheduleError("Unable to show HTTP-based content", item.objectReference);
            Logger.viewerError("E000000003", "Unable to show HTTP-based content", item.objectReference);
            continue;
          } else {
            Logger.logExternalMessage("Attempting to show HTTP-based content", item.objectReference);
            Logger.viewerError("E000000003", "Attempting to show HTTP-based content", item.objectReference);
          }

        } else if (item.objectReference.indexOf("://") === -1) {
          // Add https:// if no protocol parameter exists
          item.objectReference = "https://" + item.objectReference;
        }
      }

      const onPresentationDone = item.playUntilDone ? _onPresentationDone : null;

      if (item.type === PlaylistItemInfo.TYPE_PRESENTATION && item.presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
        const template = new HTMLTemplateController(item,
          htmlName + "_pre" + itemCount,
          htmlName,
          _onPresentationReady,
          onPresentationDone);
        presentations.push(template);
      } else {
        const presentation = new PresentationController(item,
            htmlName + "_pre" + itemCount,
            htmlName,
            _onPresentationReady,
            onPresentationDone);
        presentations.push(presentation);
      }

      items.push(item);
      itemCount++;
    }

    const initList = [];
    let skippedList = [];

    const _synchronousInit = function (j) {
      if (j >= presentations.length) { return _initSkippedPresentations(); }

      const index = (j + lastPlayed) % presentations.length;
      const playable = TimelineParser.canPlay(items[index]);
      const iframeName = "iFrame_" + htmlName + "_pre" + index;
      const nextCall = _synchronousInit.bind(null, j + 1);
      const appendReady = presentations[index].appendOnPresentationReady;

      if (initList.indexOf(j) > -1) { return; }

      if (!playable) {
        skippedList.push(index);
        return nextCall();
      }

      presentations[index].init(playable);

      Utils.attachDOMListener(iframeName, "load", nextCall);
      Utils.attachDOMListener(iframeName, "unload", nextCall);
      (appendReady && appendReady(nextCall));

      initList.push(j);
    };

    const _initSkippedPresentations = function () {
      for (let i = 0; i < skippedList.length; i++) {
        presentations[skippedList[i]].init(false);
      }

      skippedList = [];
    };

    if (!ConfigurationFactory.isSharedschedule()) {
      localStorage.removeItem("ScheduleController.lastPlayed");
    }

    _synchronousInit(0);

    if (presentations.length === 0) {
      status = PlaylistItemInfo.READY_STATUS;
      onScheduleReady();
    }

    NavigationUIController.init(factory);
  };

  let _onPresentationReady = () => {
    if (status !== PlaylistItemInfo.ALL_READY_STATUS) {
      // [AD] - All ready signifies all items are ready and if none canPlay(), ready command will execute
      // and the screen will go black
      let allReady = true;
      for (let i = 0; i < presentations.length; i++) {
        if (presentations[i].isReady()) {
          // [AD] - Added extra check for item.canPlay() or else the ready command is called and
          // no items would be ready
          if (TimelineParser.canPlay(items[i])) {
            status = PlaylistItemInfo.READY_STATUS;
          }
        }
        else if (presentations[i].getStatus() === PlaylistItemInfo.UNLOADED_STATUS
          || presentations[i].getStatus() === PlaylistItemInfo.ERROR_STATUS) {
          // do nothing
        }
        else {
          allReady = false;
        }
      }

      if (allReady && status !== PlaylistItemInfo.ALL_READY_STATUS) {
        status = PlaylistItemInfo.ALL_READY_STATUS;

        onScheduleReady();
      }

      if (status === PlaylistItemInfo.READY_STATUS) {
        onScheduleReady();
      }
    }

    _notifyObservers();
  };

  let _onPresentationDone = () => {
    if (doneReceived) {
      Logger.logDebug(`Presentation ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done received multiple times!`);

      presentations[currentItem].stop();

      // Will trigger next item playback
      _setCurrentItem(-1);
    } else {
      doneReceived = true;
    }

    if (isPlaying && currentItem !== -1 && !items[currentItem].playUntilDone &&
      lastItem === currentItem) {
      presentations[currentItem].play();
    }
    else {
      if (items.length !== 1) {
        // Next item should play - reset doneReceived
        doneReceived = false;
      }

      _playNextItem(true);
    }

    // Reset doneReceived once play has executed
    // to prevent recursion loop
    doneReceived = false;
  };

  const _timerExecute = () => {
    if (itemTime != -1) {
      itemTime--;
    }

    // calculate the next item time
    if (itemTime == 0) {
      _playNextItem(true);
    }

    if (scheduleTime != -1) {
      scheduleTime--;
    }

    if (scheduleTime == 0) {
      _verifySchedule(true);
    }
  };

  const _setNextItemCheck = (seconds) => {
    itemTime = seconds;
  };

  const _setNextScheduleCheck = (seconds) => {
    scheduleTime = seconds;
  };

  let _verifySchedule = (executeDoneCommand) => {
    if (!isPlaying) {
      isPlaying = true;
      _playNextItem(executeDoneCommand);
    }
    else if (currentItem !== -1 && !TimelineParser.canPlay(items[currentItem])) {
      _playNextItem(executeDoneCommand);
    }

    _setNextScheduleCheck(60 - new Date().getSeconds());

    // load if needed
    for (let i = 0; i < items.length; i++) {
      _loadPresentation(i);
    }
  };

  let _playNextItem = (executeDoneCommand) => {
    // Reset any previous timers
    _setNextItemCheck(-1);

    // only send Done if an item actually played
    if (!backward && nextItem === items.length - 1) {
      nextItem = -1;
      isPlaying = false;

      if (executeDoneCommand && lastItem !== -1) {
        Logger.logDebug(`Presentation ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- executeDoneCommand!`);

        onScheduleDone();
      } else if (lastItem === -1 && currentItem !== -1) {
        presentations[currentItem].stop();

        // we haven't played through any items yet
        _setCurrentItem(-1);
      }

      return;
    }

    // signifies Done was sent right after Play
    if (isPlaying && lastItem !== currentItem && currentItem !== -1 && presentations[currentItem].isReady()) {
      Logger.logDebug(`Presentation ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Done after play Stop!`);

      presentations[currentItem].stop();

      // we haven't played through any items yet
      if (lastItem === -1) {
        _setCurrentItem(-1);
      } else if (nextItem === items.length - 1) {
        // Stop the last item just in case before clearing reference
        presentations[lastItem].stop();

        lastItem = -1;
      }
    }

    _setNextItem();

    if (nextItem === -1) {
      Logger.logDebug(`Presentation ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- end of playback stop!`);

      // This will stop playback; stop the last item just in case
      if (lastItem !== -1) {
        presentations[lastItem].stop();

        lastItem = -1;
      }

      _setNextItemCheck(60 - new Date().getSeconds());

      _notifyObservers();
    } else if (!TimelineParser.canPlay(items[nextItem])) {
      if (nextItem === currentItem) {
        _setCurrentItem(-1);
      }

      if (presentations.length !== 1) {
        _unloadPresentation(nextItem);
      }

      _playNextItem(executeDoneCommand);
    } else if (!presentations[nextItem].isReady()) {
      // if the item is not ready, skip it
      presentations[currentItem] && presentations[currentItem].pause();
      _playNextItem(executeDoneCommand);
    } else {
      _setCurrentItem(nextItem);

      if (!items[currentItem].playUntilDone) {
        const duration = items[currentItem].duration;
        // Schedule the timer to run again in x seconds.
        _setNextItemCheck(duration);
      }

      Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters before - last:${lastItem} current:${currentItem} next:${nextItem}`);

      presentations[currentItem].play();

      PlayerMessaging.sendCurrentScheduleItem(items[currentItem]);
      _notifyObservers();

      if (lastItem !== -1 && currentItem !== lastItem && presentations[lastItem] !== presentations[currentItem]) {
        // AD: Fixes issues with Gadgets not having the stop command implemented
        presentations[lastItem].pause();
      }

      Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Counters after - last:${lastItem} current:${currentItem} next:${nextItem}`);

      // [AD] Only stop propagation when an item is switched
      if (currentItem !== -1 && lastItem !== -1 && currentItem !== lastItem) {
        Logger.logDebug("Stop propagation");

        // because the play() command sets all the Placeholders moving, no need to call any more commands
        // in the timer controller
        timerController.stopPropagation();
      }

      lastItem = currentItem;
    }
  };

  let _setNextItem = () => {
    if (backward) {
      return _setPreviousItem();
    }

    if (nextItem < presentations.length - 1) {
      nextItem++;
    } else if (currentItem !== -1) {
      nextItem = 0;
    } else {
      nextItem = -1;
    }
  };

  let _setPreviousItem = () => {
    if (nextItem === 0) {
      nextItem = presentations.length - 1;
    } else if (currentItem !== -1) {
      nextItem--;
    } else {
      nextItem = -1;
    }
  };

  let _unloadPresentation = (item) => {
    if (item !== -1 && presentations[item].getStatus() !== PlaylistItemInfo.UNLOADED_STATUS
      && !TimelineParser.canPlay(items[item], new Date(new Date().getTime() + PlaylistItemInfo.UNLOAD_TIME))) {
      presentations[item].unload();
    }
  };

  let _loadPresentation = (item) => {
    if (item !== -1 && presentations[item].getStatus() === PlaylistItemInfo.UNLOADED_STATUS
      && TimelineParser.canPlay(items[item], new Date(new Date().getTime() + PlaylistItemInfo.UNLOAD_TIME))) {
      presentations[item].load();
    }
  };

  factory.unloadSchedule = () => {
    // only unload READY schedules that are NOT playing
    if (status >= PlaylistItemInfo.READY_STATUS && !isPlaying) {
      status = PlaylistItemInfo.UNLOADED_STATUS;

      factory.stop();

      for (let i = 0; i < presentations.length; i++) {
        // Check if not already Unloaded
        if (presentations[i].getStatus() !== PlaylistItemInfo.UNLOADED_STATUS) {
          presentations[i].unload();
        }
      }
    }
  };

  factory.loadSchedule = () => {
    if (status === PlaylistItemInfo.UNLOADED_STATUS) {
      status = PlaylistItemInfo.LOADING_STATUS;

      for (let i = 0; i < presentations.length; i++) {
        // Check if it should actually load
        _loadPresentation(i);
      }
    }
  };

  factory.play = () => {
    schedule.didNotPlay = false;

    if (!isPlaying) {
      if (TimelineParser.applies()) {
        _verifySchedule(false);
      }
      else {
        isPlaying = true;
        _playNextItem(false);
      }
    }

    // returning false if nothing is playing
    if (presentations.length === 0 || !isPlaying) {
      schedule.didNotPlay = true;

      onScheduleDone();
    }
  };

  factory.stop = () => {
    Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Stop!`);

    // if (isPlaying) {
    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextScheduleCheck(-1);

    for (let i = 0; i < presentations.length; i++) {
      presentations[i].stop();
    }

      // [AD] Prevent done next time the schedule is played
    if (nextItem === presentations.length - 1) {
      nextItem = -1;
    }
    // }
  };

  factory.pause = () => {
    Logger.logDebug(`Schedule ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Pause!`);

    // if (isPlaying) {
    isPlaying = false;

    _setNextItemCheck(-1);
    _setNextScheduleCheck(-1);

    for (let i = 0; i < presentations.length; i++) {
      presentations[i].pause();
    }

      // [AD] Prevent done next time the schedule is played
    if (nextItem === presentations.length - 1) {
      nextItem = -1;
    }
    // }
  };

  factory.addObserver = (observerCallback) => {
    return _observers.push(observerCallback);
  };

  factory.isReady = () => {
    return status >= PlaylistItemInfo.READY_STATUS;
  };

  factory.getStatus = () => {
    return status;
  };

  factory.getStatusInfo = () => {
    const itemsStatus = [];

    for (let i = 0; i < items.length; i++) {
      itemsStatus.push({
        isReady: presentations[i].isReady(),
        canPlay: TimelineParser.canPlay(items[i]),
        duration: items[i].duration,
        playUntilDone: items[i].playUntilDone
      });
    }
    return {
      currentItem,
      currentItemTime: itemTime,
      itemsStatus
    };
  };

  function _notifyObservers() {
    for (let i = 0; i < _observers.length; i++) {
      _observers[i]();
    }
  }

  factory.unload = () => {};

  factory.resize = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    for (let i = 0; i < presentations.length; i++) {
      // [ad] bug fix - automatically re-size URL iframe size as well
      if (items[i].type === PlaylistItemInfo.TYPE_URL || items[i].presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
        Utils.resizeContainer("iFrame_" + htmlName + "_pre" + i, width, height);
      }
    }
  };

  (function () {
    status = PlaylistItemInfo.LOADING_STATUS;

    timerController.addTimerCommand(_timerExecute);
  }());

  factory.playNextItem = () => {
    _playNextItem(true);
  };

  factory.playPreviousItem = () => {
    backward = true;

    _playNextItem(false);

    backward = false;
  };

  return factory;
};

module.exports = ScheduleController;
