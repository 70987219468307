const DisplayRegisterBaseWidget = require("./DisplayRegisterBaseWidget");
const ConfigurationFactory = require("../../ConfigurationFactory");
const DisplayRegistrationController = require("../../Data/DisplayRegistrationController");
const RisePlayerController = require("../../Player/RisePlayerController");

class EnterDisplayIdWidget extends DisplayRegisterBaseWidget {
  constructor() {
    super({
      containerId: "enter-display-id-div",
    });

    this.$displayIdText = this.$content.find(".display-id-text");
    this.$errorLabel = this.$content.find(".error-label");
    this.$applyButton = this.$content.find(".apply-button");
    this.$cancelButton = this.$content.find(".cancel-button");
    this.$helpButton = this.$content.find(".help-button");

    this._initHandlers();
  }

  _initHandlers() {
    const _this = this;
    this.$applyButton.click(() => {
      _this.stopCountdownTimer();
      _this.newDisplayId = _this.$displayIdText.val().trim();
      if (!_this.newDisplayId) {
        _this.$errorLabel.html(_this.MESSAGE_NOT_VERIFIED);
        _this.$errorLabel.show();
      } else if (_this.newDisplayId === ConfigurationFactory.getDisplayId()) {
        // no need to do anything. Just hide the window.
        _this.hide();
      } else {
        _this.$applyButton.prop("disabled", true);
          // Call CORE to validate display ID
        DisplayRegistrationController.validateDisplayId(
          _this.newDisplayId, (errorCode, errorMessage) => {
            _this._resetUI();
            const isValid = errorCode === 0;
            if (!isValid) {
              _this.$errorLabel.html(`${_this.MESSAGE_NOT_VERIFIED}. ${errorMessage}`);
              _this.$errorLabel.show();
            } else {
              RisePlayerController.saveAndRestart(_this.newDisplayId, "");
            }
          });
          // response is processed in ValidateDisplayIdCallback()
      }
    });

    this.$cancelButton.click(() => {
      _this.stopCountdownTimer();
      _this.hide();
    });
    this.$helpButton.click(() => {
      _this.stopCountdownTimer();
      window.RiseVision.Viewer.UI.DisplayRegister.HelpFrameWidget.show();
    });
  }

  _resetUI() {
    this.$errorLabel.hide();
    this.$errorLabel.html(this.MESSAGE_NOT_VERIFIED);
    this.$applyButton.prop("disabled", false);
  }

  show() {
    this._resetUI();
    super.show();
    ConfigurationFactory.getDisplayId();
  }

}
EnterDisplayIdWidget.show = () => { return _getInstance().show(); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new EnterDisplayIdWidget();
  }

  return instance;
}

module.exports = EnterDisplayIdWidget;
