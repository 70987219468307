const Logger = require("../Logger");

const service = {};

const DISPLAY_CONTROL_CONFIGURATION_KEY = "display-control-configuration";
const DISPLAY_CONTROL_CONFIGURATION_LAST_LOGGED_KEY = "display-control-configuration-last-logged";
const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const MIN_LOG_INTERVAL = 23 * HOURS;

service.shouldLogConfiguration = ( configuration, storedConfiguration, storedTimestamp ) => {
  const now = Date.now()

  return configuration !== storedConfiguration || now - storedTimestamp > MIN_LOG_INTERVAL;
}

service.logConfiguration = configuration => {
  const storedConfiguration = localStorage.getItem( DISPLAY_CONTROL_CONFIGURATION_KEY ) || ''
  const storedTimestamp = Number( localStorage.getItem(DISPLAY_CONTROL_CONFIGURATION_LAST_LOGGED_KEY) || 0 )

  if( !service.shouldLogConfiguration(configuration, storedConfiguration, storedTimestamp) ) {
    return;
  }

  Logger.viewerImportant( "Set display control configuration", configuration );
  localStorage.setItem( DISPLAY_CONTROL_CONFIGURATION_KEY, configuration );
  localStorage.setItem( DISPLAY_CONTROL_CONFIGURATION_LAST_LOGGED_KEY, Date.now() );
};

module.exports = service;
