const factory = {};

function _range(init, end) {
  const values = [];

  for (let i = init; i < end; i++) {
    values.push(i);
  }

  return values;
}

function _createStripes(name, width, angle, color) {
  const frames = _range(0, 101).map((i) => {
    return _buildStep(angle, width, i, color);
  }).join(" ");

  return `@keyframes ${name} {
    ${frames}
  }`;
}

function _buildStep(angle, width, step, color) {
  const stepWidth = width / 100;

  return `${step}% {
    backgroundBlendMode: overlay;
    background: repeating-linear-gradient(
      ${angle}deg,
      ${color},
      ${color} ${width - step * stepWidth}px,
      #00000000 ${width - step * stepWidth}px,
      #00000000 ${width}px
    );
  }`;
}

factory.createStripeClass = (name, width, angle, color) => {
  const animDef = _createStripes(name, width, angle, color);
  const classDef = `.${name} {
    animation-name: ${name};
    animation-duration: 1s
  }`;
  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = `${animDef}\n${classDef}`;
  document.getElementsByTagName("head")[0].appendChild(style);
};

module.exports = factory;
