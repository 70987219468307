const { Workbox } = require("workbox-window");

const ConfigurationFactory = require("./ConfigurationFactory");
const Logger = require("./Logger");
const Platform = require("./Platform");

const service = {};

const _logServiceWorkerEvent = (log) => {
  const { severity, eventDetails, debugInfo } = log;

  if (!severity || !eventDetails) {
    return;
  }

  if (debugInfo) {
    log.debugInfo = JSON.stringify(debugInfo);
  }

  Logger.logServiceWorkerEvent(log);
};

const _onMessageFromSW = (evt) => {
  const message = evt.data || null;

  if (!message) {
    return;
  }

  _logServiceWorkerEvent(message);
};

service.shouldDisableRangeRequests = () => {
  if (ConfigurationFactory.isChromeOSAppPlayer()) {
    return true;
  }

  // Firesticks with browser version <98 are affected by issue 99; and with browser version 98 are affected by issue 130
  if (ConfigurationFactory.isFireOSPlayer() && Platform.getBrowserMajorVersion() < 98) {
    return true;
  }

  return false;
}

const _init = (cb) => {
  const wb = new Workbox("./service-worker.js", { scope: "/" });
  const channel = new MessageChannel();

  let inactiveTimerId = null;
  let callbackExecuted = false;

  wb.addEventListener("installed", (event) => {
    const debugInfo = { eventInfo: event },
      severity = "INFO";

    if (!event.isUpdate) {
      _logServiceWorkerEvent({ severity, eventDetails: "SW installed for the very first time", debugInfo });
    } else {
      _logServiceWorkerEvent({ severity, eventDetails: "A new version of SW installed", debugInfo });
    }
  });

  wb.addEventListener("activated", (event) => {
    const debugInfo = { eventInfo: event },
      severity = "INFO";

    if (!event.isUpdate) {
      _logServiceWorkerEvent({ severity, eventDetails: "SW has activated for the very first time.", debugInfo });

      // TODO: This would be the right place to send the SW a list of runtime URLs to precache. i.e. template dependencies
    } else {
      _logServiceWorkerEvent({ severity, eventDetails: "A new version of SW has activated.", debugInfo });
    }
  });

  wb.addEventListener("waiting", (event) => {
    const debugInfo = { eventInfo: event },
      severity = "INFO";

    // Given we call skipWaiting() in SW it's unexpected that the SW would be stuck in waiting phase
    _logServiceWorkerEvent({ severity, eventDetails: "A new version of SW is stuck in waiting phase.", debugInfo });
  });

  wb.addEventListener("controlling", (event) => {
    const debugInfo = { eventInfo: event },
      severity = "INFO";

    if (!event.isUpdate) {
      _logServiceWorkerEvent({ severity, eventDetails: "SW is controlling page for the very first time.", debugInfo });
    } else {
      _logServiceWorkerEvent({ severity, eventDetails: "A new version of SW has taken control.", debugInfo });
    }
  });

  // Listen for messages on port1
  channel.port1.onmessage = _onMessageFromSW;

  // set a timer to account for SW registration delay or problem
  inactiveTimerId = setTimeout(() => {
    cb();
    callbackExecuted = true;
  }, 5000);

  wb.register();

  // wb.active resolves to the service worker registered by this instance as soon as it is active.
  // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#active
  wb.active
    .then((sw) => {
      clearTimeout(inactiveTimerId);
      inactiveTimerId = null;

      sw.postMessage({ message: "INITIALIZE_LOGGING" }, [channel.port2]);

      if (service.shouldDisableRangeRequests()) {
        sw.postMessage({ message: "DISABLE_RANGE_REQUESTS" });
      }

      if (!callbackExecuted) {
        cb();
        callbackExecuted = true;
      }

      _unregisterLegacyServiceWorkers();
    });
};

const _unregisterLegacyServiceWorkers = () => {
  navigator.serviceWorker.getRegistrations()
    .then((registrations) => {
      let count = 0;
      for (const registration of registrations) {
        if (registration && registration.scope &&
          (registration.scope.startsWith("https://widgets.risevision.com/stable/templates/") ||
          registration.scope.startsWith("https://widgets.risevision.com/beta/templates/") ||
          registration.scope.startsWith("https://widgets.risevision.com/staging/templates/")))
        {
          registration.unregister();
          count++;
        }
      }
      if (count > 0) {
        _logServiceWorkerEvent({ severity: "IMPORTANT", eventDetails: `Unregistered ${count} legacy Service Workers` });
      }
    });
};

const _isSupported = () => {
  return "serviceWorker" in navigator;
};

service.register = (cb) => {
  if (_isSupported()) {
    if (document.domain.indexOf("localhost") === -1) {
      _init(cb);
    } else {
      cb();
    }
  } else {
    _logServiceWorkerEvent({ eventDetails: "Service worker API is not supported", severity: "ERROR", eventErrorCode: "E000000094" });
    cb();
  }
};

module.exports = service;
