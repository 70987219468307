const notifications = [];

const FIVE_SECONDS = 5000;

const addNotification = ( content, duration=FIVE_SECONDS ) =>
  notifications.push({ content, duration });

const clear = () => { notifications.length = 0; }

const showNextNotification = div => {
  if (!notifications.length) {
    div.style.display = "none";

    return;
  }

  const { content, duration } = notifications.shift();

  div.innerHTML = content;

  setTimeout(() => showNextNotification(div), duration);
}

const showNotifications = () => {
  if (!notifications.length) { return; }

  const div = document.querySelector("#floating-notifications");
  div.style.display = "block";

  showNextNotification(div);
}

module.exports = {
  addNotification,
  showNotifications,
  clear
}
