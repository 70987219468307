const ConfigurationFactory = require("../ConfigurationFactory");
const DataProvider = require("./ViewerDataProvider");
const Logger = require("../Logger");
const Global = require("../Config/Global");
const ViewerNotificationsPanel = require("../UI/ViewerNotificationsPanel");
const DisplayRegisterWidget = require("../UI/DisplayRegister/DisplayRegisterWidget");
const NotificationType = require("../Info/NotificationType");
const RisePlayerController = require("../Player/RisePlayerController");
const Messaging = require("../Messaging");
const ViewerDataInfo = require("./ViewerDataInfo");
const GiadaDN74BackgroundStatePreventionWorkaround = require("../Issues/GiadaDN74BackgroundStatePreventionWorkaround");

const service = {};

const INITIAL_STATE = 0;
const CLOSED_STATE = 4;

let state = INITIAL_STATE;

let pollingTimer;

let onDataReady;

const _getEmbedData = function (id, parentId) {
  try {
    if (window.parent) {
      return window.parent.getEmbedData(id, parentId);
    }
  }
  catch (err) {
    Logger.logException("ViewerDataController.getParentData", err, id);
  }
};

service.init = (dataReady) => {
  onDataReady = dataReady;

  if (ConfigurationFactory.isEmbed()) {
    service.reportDataReady(_getEmbedData(ConfigurationFactory.getId(), ConfigurationFactory.getParentId()));
  } else {
    if (ConfigurationFactory.isDisplay() || ConfigurationFactory.isWebPlayer()) {
      window.RiseVision.Viewer.Data.ViewerInstanceController.init();
    }

    if (window.disableViewerContentFetch) {
      try {
        window.receiveFromPlayer("content-update", (message) => {
          Logger.logExternalMessage("content-update", window.getUrlVars().id, " ", JSON.stringify(message.newContent));
          Logger.viewerDebug("content-update", `ID: ${window.getUrlVars().id} Message: ${JSON.stringify(message.newContent)}`);

          _updateViewerData(message.newContent);
        });

        window.postToPlayer({ message: "data-handler-registered" });
      } catch (err) {
        Logger.logException("ViewerDataController.receiveFromPlayer", err);
      }
    } else if (ConfigurationFactory.isWebPlayer()) {
      window.RiseVision.Viewer.Data.ContentSentinelDataProvider.init(_updateViewerData);
      window.RiseVision.Viewer.Player.DisplayControlService.init();

      DataProvider.logConfigData()
    }
    else {
      DataProvider.retrieveData(DataProvider.REASON.VIEWER_INIT);
    }
  }
};

const _pollingTimerExecute = () => {
  pollingTimer = null;

  DataProvider.retrieveData(DataProvider.REASON.POLLING_TIMER);
};

service.setDuplicateInstance = () => {
  _setClosedState();

  // Do not show notification
  // TODO: Relay message to server
  //    ViewerNotificationsWidget.show(NotificationType.multiple_display_instances, true);
};

service.reportDataReady = (result) => {
  if (!Messaging.canConnect()) {
    // Do nothing; should start polling
  }

  if (result) {
    RisePlayerController.setPlayerInfo(result);
  }

  _updateViewerData(result);
};

let _updateViewerData = (result) => {
  ViewerNotificationsPanel.hide();

  if (result && result.status) {
    _setNewViewerData(result);
  }

  if (ViewerDataInfo.updateViewerData(result)) {
    onDataReady();
    GiadaDN74BackgroundStatePreventionWorkaround.init();
  }
};

let _setNewViewerData = (newViewerData) => {
  if (state === CLOSED_STATE) {
    return;
  }

  _stopPolling();

  switch (newViewerData.status.code) {
  case Global.VIEWER_STATUS.OK:
    if (!ConfigurationFactory.isChrome() && ConfigurationFactory.isPreview() && ConfigurationFactory.getShowUi()) {
      ViewerNotificationsPanel.showError(NotificationType.USE_GOOGLE_CHROME);
    }
    break;

  case Global.VIEWER_STATUS.NO_COOKIE:
    _showNotification(NotificationType.COOKIES_DISABLED.getMessage());
    break;

  case Global.VIEWER_STATUS.CONTENT_NOT_FOUND:
    _showNotification(newViewerData.status.message, true);
    break;

  case Global.VIEWER_STATUS.ID_SHARING_VIOLATION:
    service.showDupeId();
    break;
  case Global.VIEWER_STATUS.DISPLAY_NOT_FOUND:
    _showDisplayRegistration(NotificationType.DISPLAY_ID_NOT_FOUND);
    break;

  case Global.VIEWER_STATUS.NOT_SUBSCRIBED:
    _showNotification(NotificationType.NO_TEMPLATE_SCHEDULED);
    break;

  default:
    break;
  }

  if (ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed() &&
    !Messaging.canConnect()) {
    _startPolling(0);
  }
};

let _showDisplayRegistration = (notificationType) => {
  _setClosedState();
  if (RisePlayerController.getIsActive() && ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed()) {
    DisplayRegisterWidget.show(notificationType);
  } else {
    ViewerNotificationsPanel.showError(notificationType);
  }
};

let _showNotification = (notification, fullscreen) => {
  _setClosedState();

  if (!ViewerDataInfo.hasData()) {
    ViewerNotificationsPanel.showError(notification, fullscreen);
  }
};

let _setClosedState = () => {
  state = CLOSED_STATE;
};

let _startPolling = (interval) => {
  let newInterval = interval;
  if (!pollingTimer) {
    if (interval == 0) {
      newInterval = ViewerDataInfo.getPollInterval();
    }
    else {
      newInterval += 2;
    }

    pollingTimer = setTimeout(_pollingTimerExecute, newInterval * Global.MINUTE_UPDATE_INTERVAL);
  }
};

let _stopPolling = () => {
  if (pollingTimer) {
    clearTimeout(pollingTimer);

    pollingTimer = null;
  }
};

service.resetPolling = () => {
  _stopPolling();

  const interval = ViewerDataInfo.getPollInterval();
  pollingTimer = setTimeout(_pollingTimerExecute, interval * Global.MINUTE_UPDATE_INTERVAL);
};

service.showDupeId = () => {
  _showDisplayRegistration(NotificationType.DISPLAY_ID_DUPLICATE);
};

module.exports = service;
