const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");

const AFFECTED_COMPANY_IDS = [
  "7dba1066-d5ba-40b4-9213-87009e8ac22b",
  "3ff69fad-fc75-4e8f-be8d-343d50af42f0",
  "5e0f0bca-7f5c-40a5-8547-7db1c2fefc90",
  "6ce78836-aec6-44d2-a3db-d60859d136ad",
  "6f7c56df-e911-48d0-b975-1cae72230b50",
  "dba9e8c2-b88e-4b13-836a-06f4201e0338",
  "bbf27c5b-544a-4fb5-bb6a-4e974021c552",
  "4e22da3e-bcd1-439a-b6e0-5f07e3b1d7cf",
  "de3d91cd-7153-44bb-96f6-8edb48305e17",
  "a90a2abe-da5b-4a72-8314-f6d33db638c8",
  "b428b4e8-c8b9-41d5-8a10-b4193c789443" // Test company
]

let audio = null
const service = {}

service.shouldApplyWorkaround = () => {
  if (!ConfigurationFactory.isGiadaDN74()) {
    return false
  }

  const companyId = ConfigurationFactory.getCompanyId()
  return AFFECTED_COMPANY_IDS.includes( companyId )
}

service.init = () => {
  // Prevent multiple instances
  if (audio) {
    return
  }

  if (!service.shouldApplyWorkaround()) {
    return
  }

  // Consider removing once it's applied to all companies
  Logger.viewerImportant( "Applying background state prevention workaround", JSON.stringify({
    companyId: ConfigurationFactory.getCompanyId()
  }))

  audio = new Audio('https://storage.googleapis.com/widgets.risevision.com/recordings/silent.mp3')
  audio.loop = true
  audio.volume = 0.01

  audio.play().catch((error) => {
    Logger.viewerError( "E999999999", "unable to play silent audio", JSON.stringify({
      error: error && ( error.stack || error.message )
    }))
  })
}

module.exports = service
