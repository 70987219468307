const DisplayRegisterBaseWidget = require("./DisplayRegisterBaseWidget");
const RisePlayerController = require("../../Player/RisePlayerController");
const NotificationType = require("../../Info/NotificationType");

class DisplayRegisterWidget extends DisplayRegisterBaseWidget {
  constructor() {
    super({
      containerId: "display-registration-div",
    });

    this.$notificationPanel = this.$elem.find(".notification-panel");
    this.$helpButton = this.$elem.find(".help-button");
    this.$quitButton = this.$elem.find(".quit-button");
    this.$enterDisplayIdButton = this.$elem.find(".enter-display-id-button");
    this.$enterClaimIdButton = this.$elem.find(".enter-claim-id-button");
    this._initHandlers();
  }

  _initHandlers() {
    const _this = this;
    this.$enterDisplayIdButton.click(() => {
      _this.stopCountdownTimer();
      window.RiseVision.Viewer.UI.DisplayRegister.EnterDisplayIdWidget.show();
    });

    this.$enterClaimIdButton.click(() => {
      _this.stopCountdownTimer();
      window.RiseVision.Viewer.UI.DisplayRegister.EnterClaimIdWidget.show();
    });

    this.$helpButton.click((e) => {
      e.preventDefault();
      _this.stopCountdownTimer();
      window.RiseVision.Viewer.UI.DisplayRegister.HelpFrameWidget.show();
    });

    this.$quitButton.click(() => {
      _this.stopCountdownTimer();
      RisePlayerController.shutdown();
    });
  }

  show(notificationType) {
    let msg;
    if (notificationType instanceof NotificationType) {
      msg = _ensureStr(notificationType);
    } else if (typeof notificationType === "string") {
      msg = _ensureStr(NotificationType[notificationType]);
    }
    this.$notificationPanel.html(msg);
    super.show();
  }
}

function _ensureStr(notification, count) {
  let msg;
  if (typeof notification === "string") {
    msg = notification;
  } else {
    msg = notification.getMessage(count);
  }

  return msg;
}

let instance = null;

function _getInstance() {
  if (!instance) {
    instance = new DisplayRegisterWidget();
  }
  return instance;
}

DisplayRegisterWidget.show = (notification) => { return _getInstance().show(notification); };

module.exports = DisplayRegisterWidget;
