const Utils = require("../Utils");
const Logger = require("../Logger");
const ConfigurationFactory = require("../ConfigurationFactory");
const TimelineParser = require("../Parser/TimelineParser");
const PresentationParser = require("../Parser/PresentationParser");

const PlaceholderVideoController = (placeholderInfo, presFrame, phName) => {
  const factory = {};

  let currentVideo = -1, nextVideo = -1, previousVideo = -1;
//  var height, width;
  const videoItems = [];
  const videoItemObjects = [];
  const videoItemLoaded = [];
  const videoErrorCount = [];

  let lastPosition = -1;
  let nudgeCounter = 0;

  let checkPlayTimer;

  const LOAD_ERROR = "loadError";

  const VIDEO_SCRIPT = "<script><!--\n" +
    "try {" +
    "updateVideo('%s1', '%s2', '%s3', '%s4');" +
    "} catch(err) { parent.writeToLog('updateVideo call - %s2 - ' + err.message); }" +
    "\n//-->" +
    "</script>";

  const HTML_JWPLAYER = "" +
    "<html>" +
    "<head>" +
    "<meta http-equiv=\"content-type\" content=\"text/html; charset=UTF-8\">" +
    "<title></title>" +
    //    "<script type=\"text/javascript\" src=\"mediaplayer/BrowserDetect.js\"></script>" +
    "<script type=\"text/javascript\" src=\"./jwplayer/jwplayer.js\"></script>" +
    "<script>jwplayer.key=\"xBXyUtAJ+brFzwc2kNhDg/Sqk8W7rmktAYliYHzVgxo=\"</script>" +
    "<script type=\"text/javascript\" src=\"./scripts/videoScripts.js\"></script>" +
    "<style>" +
    "  body { " +
    "    background-color: transparent; " +
    "    -moz-user-select: none; " +
    "    -webkit-user-select: none; " +
    "    -khtml-user-select: none; " +
    "    user-select: none; " +
    "  }" +
    "</style>" +
    "</head>" +
    "<body style=\"margin:0px;\">" +
    "<div id=\"flash\" style=\"visibility:hidden\">Loading the player...</div>" +
    "<script language=\"javascript\">" +
    "  window.oncontextmenu = function() {" +
    "    return false;" +
    "  };" +
    "  try {" +
    "    player = new PlayerJW();" +
    "    parent.parent.RiseVision.Viewer.Cache.RiseCacheController.getCacheVideoUrl(\"%s4\" ,\"%s5\" , function(videoUrl) {" +
    "      try {" +
    "        loadVideo(\"%s1\", \"%s2\", \"%s3\", videoUrl, \"%s5\", %s6, %s7, %s8);" +
    "      } catch (e) {" +
    "        console.log(e.message);" +
    "        parent.itemError(\"%s1\", \"" + LOAD_ERROR + "\");" +
    "      }" +
    "    });" +
    "  } catch (e) {" +
    "    console.log(e.message);" +
    "    parent.itemError(\"%s1\", \"" + LOAD_ERROR + "\");" +
    "  }" +
    "</script>" +
    "</body>" +
    "</html>";

  factory.addVideo = (itemName, videoController) => {
    if (nextVideo === -1 && TimelineParser.canPlay(videoController.getItem())) {
      nextVideo = videoItems.length;
    }

    videoItems.push(itemName);

    videoItemObjects.push(videoController);
    videoItemLoaded.push(false);
    videoErrorCount.push(0);
  };

  factory.updateHtml = (presentation) => {
    if (nextVideo !== -1) {
      _addVideoScript(presentation, _createHtmlString(nextVideo), phName, videoItems[nextVideo],
      //        width + 'px',
      //        height + 'px',
      //        0 + 'px', 0 + 'px',
      placeholderInfo.transition);

      videoItemLoaded[nextVideo] = true;
    }
  };

  let _addVideoScript = (presentation, html, containerName, htmlName, transition) => {
    html = html.replace(/'/g, "\\'");

    const scriptTag = VIDEO_SCRIPT.replace(/%s1/, html)
      .replace(/%s2/, containerName)
      .replace(/%s3/, htmlName)
      .replace(/%s4/, transition);

    PresentationParser.addScriptTag(presentation, scriptTag);
  };

//  public void setPresFrame(presFrame) {
//    this.presFrame = presFrame;
//  }

//  public void reportReady(ViewerVideoController videoController) {
//    int readyVideo = _indexOf(videoItemObjects, videoController);
//
//    videoErrorCount.set(readyVideo, 0);
//  }

  const _indexOf = (array, object) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i] === object) {
        return i;
      }
    }

    return -1;
  };

  factory.reportError = (videoController, reason) => {
    const errorVideo = _indexOf(videoItemObjects, videoController);

    _destroyVideo(errorVideo);
    videoItemLoaded[errorVideo] = false;

    if (LOAD_ERROR !== reason) {
      //      videoErrorCount.set(errorVideo, videoErrorCount.get(errorVideo) + 1);

      // error from Video - skip video and load the next one
      if (errorVideo === nextVideo) {
        _getNextPlayableVideo();

        if (nextVideo !== errorVideo) {
          _loadNextVideo();
        }
      } else if (errorVideo === currentVideo) {
        videoController.setDone();
      }
    }
  };

  factory.readyRequested = (videoController) => {
    const requestedVideo = _indexOf(videoItemObjects, videoController);

    if (currentVideo === -1 && nextVideo === -1 && !videoItemLoaded[requestedVideo]) {
      _getNextPlayableVideo();

      _loadNextVideo();
    }
  };

  factory.play = (videoController, show) => {
    const playVideo = _indexOf(videoItemObjects, videoController);

    _destroyPreviousVideo();

    if (playVideo === nextVideo) {
      if (_getPlayableVideoCount() > 3) {
        previousVideo = currentVideo;
      }

      _compareNextVideo(currentVideo);

      currentVideo = nextVideo;

      _getNextPlayableVideo();
      _playCurrentVideo(show);
    } else if (playVideo === currentVideo) {
      nextVideo = currentVideo;
      _getNextPlayableVideo();

      _playCurrentVideo(show);
    } else if (videoItemLoaded[playVideo]) {
      nextVideo = currentVideo;
      currentVideo = playVideo;

      _playCurrentVideo(show);
    } else {
      nextVideo = -1;
      currentVideo = playVideo;

      _playCurrentVideo(show);
    }

    // load the next video
    _loadNextVideo();
  };

  let _playCurrentVideo = (show) => {
    try {
      Utils.playCommand(presFrame, videoItems[currentVideo], show);

      if (checkPlayTimer) {
        clearInterval(checkPlayTimer);
      }
      checkPlayTimer = setInterval(_verifyPosition, 10000);
    }
    catch (err) {
      Logger.logException("PlaceholderVideoController._playCurrentVideo", err);
    }
  };

  let _loadNextVideo = () => {
    // next video not ready yet?
    //    if (videoItemLoaded.get(nextVideo) && !videoItemObjects.get(nextVideo).isReady()) {
    //      destroyVideo(nextVideo);
    //    }

    if (!videoItemLoaded[nextVideo] && videoErrorCount[nextVideo] < 3) {
      if (currentVideo !== -1
        && videoItemObjects[currentVideo].getVideoInfo().videoUrl === videoItemObjects[nextVideo].getVideoInfo().videoUrl) {
        videoItemLoaded[nextVideo] = true;
        videoItemObjects[nextVideo].setReady(presFrame, true, true, true, true, true);
      }
      else if (!_compareNextVideo(previousVideo)) {
        videoItemLoaded[nextVideo] = true;

        _createVideo(nextVideo);
      }
    }
  };

  let _compareNextVideo = (thisVideo) => {
    const nextVideoInfo = videoItemObjects[nextVideo].getVideoInfo();
    if (thisVideo !== -1 && videoItemObjects[thisVideo].getVideoInfo().videoUrl === nextVideoInfo.videoUrl) {
      if (thisVideo !== nextVideo) {
        _configureVideoNative(presFrame, videoItems[thisVideo], videoItems[nextVideo], nextVideoInfo.volume, nextVideoInfo.autoHide, nextVideoInfo.carryOn);

        videoItemLoaded[nextVideo] = true;
        videoItemObjects[nextVideo].setReady(presFrame, true, true, true, true, true);

        videoItemLoaded[thisVideo] = false;
        videoItemObjects[thisVideo].unload();
      }

      if (thisVideo === previousVideo) {
        Utils.pauseCommand(presFrame, videoItems[nextVideo], true);
      }

      previousVideo = -1;

      return true;
    } else {
      return false;
    }
  };

  let _getNextPlayableVideo = () => {
    do {
      if (nextVideo < videoItemObjects.length - 1) {
        nextVideo += 1;
      } else {
        nextVideo = 0;
      }
    }
    while (nextVideo !== currentVideo && (!TimelineParser.canPlay(videoItemObjects[nextVideo].getItem())
      || !(videoErrorCount[nextVideo] < 3)));
  };

  let _getPlayableVideoCount = () => {
    let count = 0;

    for (let i = 0; i < videoItemObjects.length; i++) {
      if (TimelineParser.canPlay(videoItemObjects[i].getItem())) {
        count += 1;
      }
    }

    return count;
  };

  factory.stop = (videoController, hide) => {
    if (previousVideo !== -1) {
      videoItemObjects[previousVideo].unload();
    }

    _pausePlayTimer(videoController);

    try {
      const itemIndex = _indexOf(videoItemObjects, videoController);
      if (videoItemLoaded[itemIndex]) {
        Utils.stopCommand(presFrame, videoItems[itemIndex], hide);
      }
    }
    catch (err) {
      Logger.logException("PlaceholderVideoController.stop", err);
    }
  };

  factory.pause = (videoController, hide) => {
    if (previousVideo !== -1) {
      videoItemObjects[previousVideo].unload();
    }

    _pausePlayTimer(videoController);

    try {
      const itemIndex = _indexOf(videoItemObjects, videoController);
      if (videoItemLoaded[itemIndex]) {
        Utils.pauseCommand(presFrame, videoItems[itemIndex], hide);
      }
    }
    catch (err) {
      Logger.logException("PlaceholderVideoController.pause", err);
    }
  };

  let _pausePlayTimer = (videoController) => {
    if (_indexOf(videoItemObjects, videoController) === currentVideo) {
      if (checkPlayTimer) {
        clearInterval(checkPlayTimer);
        checkPlayTimer = null;
      }
      lastPosition = -1;
    }
  };

  let _verifyPosition = () => {
    if (currentVideo !== -1) {
      let d = _checkVideoPosition(presFrame, videoItems[currentVideo]);

      if (d !== -1) {
        if (lastPosition !== -1) {
          if (d === lastPosition) {
            if (nudgeCounter < 3) {
              _nudgeVideo(presFrame, videoItems[currentVideo], lastPosition + 0.01);
              nudgeCounter += 1;
              d = -1;
            } else {
              _reloadWindow();
            }
          } else {
            nudgeCounter = 0;
          }
        }

        lastPosition = d;
      }
    }
  };

  let _reloadWindow = () => {
    window.location.reload();
  };

  let _createVideo = (videoId) => {
    _createVideoNative(presFrame, _createHtmlString(videoId), phName, videoItems[videoId],
    //        width + 'px',
    //        height + 'px',
    placeholderInfo.transition);
  };

  let _destroyPreviousVideo = () => {
    _destroyVideo(previousVideo);
  };

  let _destroyVideo = (videoItem) => {
    if (videoItem !== -1 && videoItemLoaded[videoItem]) {
      Utils.destroyFrameElement(presFrame, videoItems[videoItem], phName);
      videoItemLoaded[videoItem] = false;
      videoItemObjects[videoItem].unload();
    }
  };

  let _createHtmlString = (videoId) => {
    const videoInfo = videoItemObjects[videoId].getVideoInfo();
    let videoUrl = videoInfo.videoUrl;
    let extension = videoInfo.videoExtension;
    extension = extension || "";

  // Append Display id to Video URL
    if (ConfigurationFactory.isDisplay() && videoUrl.indexOf("commondatastorage.googleapis.com") !== -1) {
      videoUrl += "?displayId=" + ConfigurationFactory.getDisplayId();
    }

    let htmlString = HTML_JWPLAYER;
    htmlString = htmlString.replace(/%s1/g, videoItems[videoId]);
    htmlString = htmlString.replace(/%s2/g, "100%");
    htmlString = htmlString.replace(/%s3/g, "100%");
  //    htmlString = htmlString.replace(/%s2/, width + 'px');
  //    htmlString = htmlString.replace(/%s3/, height + 'px');
    htmlString = htmlString.replace(/%s4/g, videoUrl.trim());
    htmlString = htmlString.replace(/%s5/g, extension);
    htmlString = htmlString.replace(/%s6/g, videoInfo.volume);
    htmlString = htmlString.replace(/%s7/g, videoInfo.autoHide);
    htmlString = htmlString.replace(/%s8/g, videoInfo.carryOn);

    return htmlString;
  };

  let _createVideoNative = (presFrame, html, containerName, htmlName, transition) => {
    try {
      window.updateVideo(presFrame, html, containerName, htmlName, transition);
    } catch (err) {
      Logger.logException("PlaceholderVideoController.updateVideo._createVideoNative", err, htmlName);
    }
  };

  let _configureVideoNative = (presFrame, htmlName, newHtmlName, volumeParam, autoHideParam, carryOnParam) => {
    try {
      window.configureVideo(presFrame, htmlName, newHtmlName, volumeParam, autoHideParam, carryOnParam);
    } catch (err) {
      Logger.logException("PlaceholderVideoController.configureVideo._configureVideoNative", err, htmlName);
    }
  };

  let _checkVideoPosition = (presFrame, htmlName) => {
    try {
//      debugger;

      const presElement = window.document.getElementById(presFrame);
      if (presElement) {
        const videoElement = presElement.contentWindow.document.getElementById("if_" + htmlName);
        if (videoElement) {
          return videoElement.contentWindow.player.getPosition();
        }
      }
      return -1;
    } catch (err) {
      Logger.logException("PlaceholderVideoController.checkVideoPlayback", err, htmlName);
    }
  };

  let _nudgeVideo = (presFrame, htmlName, nextPosition) => {
    try {
//      debugger;

      const presElement = window.document.getElementById(presFrame);
      if (presElement) {
        const videoElement = presElement.contentWindow.document.getElementById("if_" + htmlName);
        if (videoElement) {
          videoElement.contentWindow.player.resetPosition(nextPosition);
        }
      }
    } catch (err) {
      Logger.logException("PlaceholderVideoController.nudgeVideo", err, htmlName);
    }
  };

  return factory;
};

module.exports = PlaceholderVideoController;
