let id = setMachineId();

function setMachineId() {
  if (id) { return id; }

  try {
    id = localStorage.machineId;
  } catch (e) {}

  if (!id) {
    id = "" + Math.random();
    try {
      localStorage.setItem("machineId", id);
    } catch (e) {}
  }

  return id;
}

module.exports = {
  matches(target) {
    return target && target === id;
  },
  id() {
    return id;
  }
};
