const Config = require("./Config/Config");
const Global = require("./Config/Global");
const ConfigurationFactory = require("./ConfigurationFactory");
const Logger = require("./Logger");
const Utils = require("./Utils");
const PlaylistItemInfo = require("./Info/PlaylistItemInfo");

const factory = {};

// Google Analytics
factory.initGoogleAnalytics = (trackerId) => {
  factory.type = "ga";

  if (trackerId && !Utils.isTestEnvironment()) {
    try {
      (function (i, s, o, g, r, a, m) { i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments); }, i[r].l = 1 * new Date(); a = s.createElement(o),
      m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
      }(window, document, "script", "//www.google-analytics.com/analytics.js", "ga"));

      ga("create", trackerId, "auto");
      ga("send", "pageview");
    } catch (err) { Logger.logException("AnalyticsFactory.initGoogleAnalytics", err); }
  }
};

// Legacy tracking with GA
factory.trackEvent = (eventCategory, eventAction, eventLabel) => {
  _load();

  try {
    if (factory.type === "ga") {
      ga("send", "event", {
        eventCategory,
        eventAction,
        eventLabel
      });
    } else {
      Logger.logDebug("AnalyticsFactory.trackEvent not supported outside Google Analytics");
    }
  } catch (err) { Logger.logException("AnalyticsFactory.trackEvent", err); }
};
// ========================================================

const _getSessionProperties = () => {
  // default to url
  const platform = ConfigurationFactory.getEnv(true);

  return {
    url: window.location.href,
    platform,
    app_name: "Rise Vision",
    version: Global.VIEWER_VERSION,
    companyId: RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId(),
    // parentId
    // userId
    // email
    scheduleId: RiseVision.Viewer.Data.ViewerDataInfo.getItemId(),
    scheduleName: RiseVision.Viewer.Data.ViewerDataInfo.getItemName(),
  };
};

const _getTrackProperties = () => {
  const properties = _getSessionProperties();

  properties.category = "app";
  properties.label = RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId();

  return properties;
};

const _track = (event, properties) => {
  // Segment tracking was removed. Leaving method here so that we can plug the new Logging mechanism once ready.
};

factory.trackLoadTime = () => {
  if (factory.loadTimeTracked || !ConfigurationFactory.isSharedschedule()) {
    return;
  }

  factory.loadTimeTracked = true;

  try {
    const properties = _getTrackProperties();
    properties.loadTime = new Date().getTime() - window.performance.timing.navigationStart;

    Logger.logDebug(`AnalyticsFactory.trackLoadTime loadTime=${properties.loadTime}ms`);

    factory.trackEvent("Shared Schedule", "schedule load time", properties.loadTime);
    _track("schedule load time", properties);
  } catch (err) { Logger.logException("AnalyticsFactory.trackLoadTime", err); }
};

factory.trackPlayback = (item) => {
  if (!ConfigurationFactory.isSharedschedule()) {
    return;
  }

  const properties = _getTrackProperties();

  properties.slideId = item.objectReference;
  properties.slideType = item.type;

  if (item.presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
    properties.slideType = item.presentationType;
  }

  if (item.type === PlaylistItemInfo.TYPE_PRESENTATION) {
    const presentation = RiseVision.Viewer.Data.ViewerDataInfo.getPresentation(item.objectReference);

    if (presentation) {
      properties.slideName = presentation.name;
      properties.slideProductCode = presentation.productCode;
    }
  }

  factory.trackEvent("Shared Schedule", item.type + " item viewed", item.objectReference);
  _track("app slide viewed", properties);
};
// ========================================================

const _load = () => {
  // Check if already initialized
  if (factory.type) {
    return;
  }

  if (ConfigurationFactory.isPreview()) {
    factory.initGoogleAnalytics(Config.PREVIEW_TRACKER_ID);
  } else if (ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed()) {
    factory.initGoogleAnalytics(Config.DISPLAY_TRACKER_ID);
  } else if (ConfigurationFactory.isSharedschedule()) {
    factory.initGoogleAnalytics(Config.SHARED_SCHEDULE_TRACKER_ID);
  }
};

factory.init = () => {
  _load();

  if (ConfigurationFactory.isPreview()) {
    factory.trackEvent("Preview", "Load", ConfigurationFactory.getShowUi() ? "Showing" : "Not Showing");

    if (RiseVision.Viewer.Data.ViewerDataInfo.isTemplate()) {
      factory.trackEvent("Preview", "Template", RiseVision.Viewer.Data.ViewerDataInfo.getItemName());
    }
  } else if (ConfigurationFactory.isDisplay() && !ConfigurationFactory.isEmbed()) {
    factory.trackEvent("Display", "Display Activated", ConfigurationFactory.getDisplayId());

    Logger.logAppsEvent("Display Activated");
  } else if (ConfigurationFactory.isSharedschedule()) {
    factory.trackEvent("Shared Schedule", "schedule started", RiseVision.Viewer.Data.ViewerDataInfo.getItemCompanyId());
    _track("app started", _getTrackProperties());
  }
};

module.exports = factory;
