const ConfigurationFactory = require("../ConfigurationFactory");
const PlayerIntegration = require("../Player/PlayerIntegration");
const Logger = require("../Logger");
const cacheName = "content-sentinel-cache";
const fileBucket = "risevision-display-notifications";
const contentJson = "content.json";

function isFileCurrent(file) {
  return file.status === "CURRENT";
}

function isFileStale(file) {
  return file.status === "STALE";
}

function getCachePathData(cachePath) {
  return caches.open(cacheName)
    .then(cache => cache.match(cachePath))
    .then(resp => resp ? resp.json() : Promise.reject(Error("Missing cache resource")));
}

function watchContentJson(displayId, cb) {
  const contentFilePath = `${fileBucket}/${displayId}/${contentJson}`;
  const watchMessage = {
    from: "content-consumer",
    to: "content-sentinel-controller",
    msg: "watch",
    filePath: contentFilePath
  };
  const unsubscribe = () => {
    window.removeEventListener("message", handler);
  };
  const handler = (event = {}) => {
    if (!event.data) {return;}

    const {filePath, topic} = event.data;

    if (!filePath || !topic) {return;}
    if (filePath !== contentFilePath) {return;}
    if (event.data.topic !== "FILE-UPDATE") {return;}

    cb(event.data, unsubscribe);
  };

  window.addEventListener("message", handler);

  window.postMessage(watchMessage, location.origin);
}

function onUpdate(displayId, cb) {
  Logger.logDebug(`Listening for content.json updates on ${displayId}`);

  watchContentJson(displayId, cb);
}

function onDelete(displayId, cb) {
  watchContentJson(displayId, (file, unsubscribe) => {
    if (isFileCurrent(file)) {
      getCachePathData(file.cachePath)
        .then(data => {
          if (Object.keys(data).length == 0) {
            unsubscribe();
            PlayerIntegration.setDisplayId("");
            cb(file);
          } else {
            // console.log(`File content ${file.cachePath}`, data);
          }
        })
        .catch(err => {
          Logger.viewerError( "E999999999", `Error loading cached file ${file.cachePath}: ${err}`);
        });
    }
  });
}

module.exports = {
  init(contentUpdateHandler) {
    watchContentJson(ConfigurationFactory.getId(), (file, unsubscribe) => {
      console.log("INIT", file.cachePath, file);

      if (!file.cachePath) { return; }

      return getCachePathData(file.cachePath)
      .then(PlayerIntegration.processContentJson)
      .then(contentUpdateHandler)
      .catch((error = {})=>{
        const details = error.stack || error.message;
        Logger.viewerError("E999999999", "Viewer Content Sentinel Provider", details);
      });
    })
  },
  onUpdate,
  onDelete,
  isFileCurrent,
  isFileStale
};
