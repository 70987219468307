const Platform = {};

const uaParser = require("ua-parser-js");

// Definitive minimum allowed version will be communicated to us later, before this check is enabled
const MINIMUM_ALLOWED_CHROME_VERSION = 49;
const MINIMUM_SUPPORTED_CHROME_VERSION = 81;
const MINIMUM_SUPPORTED_SAFARI_VERSION = 12;
const MINIMUM_SUPPORTED_EDGE_VERSION = 81;

let ua = null;

const getUA = () => {
  if (!ua) {
    ua = uaParser(Platform.getUserAgent());
  }

  return ua;
};

Platform.clear = () => {
  ua = null;
}

const shouldReportArchitectureFor = (family) => {
  return family && (
    family.indexOf("Windows") >= 0 ||
    family.indexOf("Linux") >= 0 ||
    family.indexOf("Ubuntu") >= 0
  );
};

const getCpuArchitecture = () => {
  const ua = getUA();
  const cpu = ua.cpu || {};

  return cpu.architecture || "";
}

const shouldReportVersionFor = (family) => {
  return family && !(
    family.indexOf("Chrome") >= 0 ||
    ( family.indexOf("Linux") >= 0 && getCpuArchitecture().indexOf("arm") < 0 )
  )
}

const isChromeOrChromiumBrowser = browserName =>
  browserName.startsWith("Chrom");

const isSafariBrowser = browserName =>
  browserName.startsWith("Safari");

const isEdgeBrowser = browserName =>
  browserName.startsWith("Microsoft Edge") || browserName.startsWith("Edge");

const browserOverrides = {
  "Android Browser": {
    nameOverride: "Embedded Chromium",
    versionRegex: RegExp("Chrom(?:e|ium)\\/([\\d.]+)")
  },
  "Chrome WebView": {
    nameOverride: "Embedded Chromium",
    versionRegex: RegExp("Chrom(?:e|ium)\\/([\\d.]+)")
  },
  "Electron": {
    nameOverride: "Chromium",
    versionRegex: RegExp("Chrom(?:e|ium)\\/([\\d.]+)")
  },
  "GSA": {
    nameOverride: "Safari",
    versionRegex: RegExp("Version\\/([\\d.]+)"),
    discardOriginalVersion: true
  },
  "WebKit": {
    nameOverride: "Safari",
    versionRegex: RegExp("Version\\/([\\d.]+)"),
    discardOriginalVersion: true
  }
};

const osOverrides = {
  "Chromium OS": "Chrome OS",
  "Ubuntu": "Linux",
};

Platform.isAllowedBrowserAndVersion = () => {
  const browserName = Platform.getBrowserName();
  const browserVersion = Platform.getBrowserMajorVersion();

  if (isChromeOrChromiumBrowser(browserName)) {
    return browserVersion >= MINIMUM_ALLOWED_CHROME_VERSION;
  } else {
    // currently there are not required restrictions on other browser types
    return true;
  }
};

Platform.isSupportedBrowserAndVersionForWebPlayer = () => {
  const browserName = Platform.getBrowserName();
  const browserVersion = Platform.getBrowserMajorVersion();

  if (isChromeOrChromiumBrowser(browserName)) {
    return browserVersion >= MINIMUM_SUPPORTED_CHROME_VERSION;
  } else if (isSafariBrowser(browserName) && browserVersion) {
    return browserVersion >= MINIMUM_SUPPORTED_SAFARI_VERSION;
  } else if (isEdgeBrowser(browserName)) {
    return browserVersion >= MINIMUM_SUPPORTED_EDGE_VERSION;
  } else {
    return false;
  }
};

Platform.getArchitecture = family => {
  const architecture = getCpuArchitecture();

  return architecture.indexOf("64") >= 0 ? "64" :
    architecture.indexOf("32") >= 0 ||
    architecture.indexOf("i686") >= 0 ||
    Platform.getUserAgent().indexOf("armv7l") >= 0 ||
    family.startsWith("Windows") ? "32" : "";
}

Platform.getOsVersion = () => {
  const ua = getUA();
  const os = ua.os || {};
  const family = osOverrides[os.name] || os.name || "";

  if (!family) {
    return "unknown";
  }

  const version = shouldReportVersionFor(family) && os.version ?
    os.version : "";
  const architecture = shouldReportArchitectureFor(family) ?
    Platform.getArchitecture(family) : "";

  return `${family}${
    architecture ? ` ${architecture}` : ""
  }${
    version ? ` ${version}` : ""
  }`;
};

const getBrowser = () => {
  return getUA().browser || {};
}

Platform.getBrowserName = () => {
  const name = getBrowser().name || "";

  return browserOverrides[name] ? browserOverrides[name].nameOverride : name;
}

Platform.getBrowserVersion = () => {
  const browser = getBrowser();
  const version = browser.version || "";
  const override = browserOverrides[browser.name];

  if (!override) {return version;}

  const overrideVersionMatch = override.versionRegex.exec(Platform.getUserAgent());

  return overrideVersionMatch ? overrideVersionMatch[1] :
    override.discardOriginalVersion ? "" : version;
}

Platform.getBrowserNameAndVersion = () => {
  const name = Platform.getBrowserName();
  const version = Platform.getBrowserVersion();

  if (!name) {
    return "unknown";
  }

  return `${name}${version ? ` ${version}` : ""}`;
};

Platform.getBrowserMajorVersion = () => {
  const version = Platform.getBrowserVersion();

  return Number(version.split(".")[0])
}

Platform.getUserAgent = () => window.navigator.userAgent || "";

Platform.getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch(e) {
    console.error(e);
    return "";
  }
};

Platform.getUtcOffset = () => {
  try {
    const offset = new Date().getTimezoneOffset();
    const sign = offset <= 0 ? "+" : "-";

    const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

    return `${sign}${hours}:${minutes}`;
  } catch(e) {
    console.error(e);
    return "";
  }
};

module.exports = Platform;
