const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const Platform = require("../Platform");
const PlayerIntegration = require("../Player/PlayerIntegration");

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const LOG_WAIT = 2 * SECONDS;
const LESS_THAN_4_HOURS = ( 4 * HOURS ) - ( 10 * MINUTES );

const service = {};

let mainTimeout;

service.init = () => {
  if( !ConfigurationFactory.isFireOSPlayer() ||
      Platform.getBrowserVersion() !== "110.0.5481.154"
  ) {
    return;
  }

  Logger.viewerImportant( "Firestick 'still watching' issue workaround configured" );

  mainTimeout = setTimeout(() => {
    Logger.viewerImportant( "Firestick 'still watching' issue time elapsed - restarting" );

    setTimeout(() => PlayerIntegration.requestRestart(), LOG_WAIT);
  }, LESS_THAN_4_HOURS)
}

service.clear = () => {
  clearTimeout( mainTimeout )
  mainTimeout = undefined;
}

module.exports = service;
