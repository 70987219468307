const Logger = require("./Logger");
const Global = require("./Config/Global");
const Config = require("./Config/Config");
const ConfigurationFactory = require("./ConfigurationFactory");
const MachineId = require("./Machine-id");
const INITIAL_CONNECTION_TIMEOUT = 20 * Global.ONE_SECOND_DELAY;
const service = {};
const handlers = [];

let connection;
let connectivity = false;
let initialConnectionTimer = null;

service.init = (cb) => {
  if (window.disableViewerContentFetch) {
    console.log("disablingViewerFetch");
    return cb();
  }

  if (ConfigurationFactory.isEmbed()) { return cb(); }
  if (ConfigurationFactory.isPreview()) { return cb(); }
  if (ConfigurationFactory.isSharedschedule()) { return cb(); }
  if (ConfigurationFactory.isWebPlayer()) { return cb(); }

  if (!window.Primus) {
    Logger.log("Primus-MS.js was not loaded");
    return cb();
  }

  if (!ConfigurationFactory.getId()) {
    Logger.logExternalMessage("messaging service was not provided a display id");
    Logger.viewerInfo("messaging service was not provided a display id");
    return cb();
  }

  Logger.viewerImportant("using legacy viewer MS connection");

  const serverUrl = `${Config.MESSAGING_SERVER_URL}?displayId=${ConfigurationFactory.getId()}&machineId=${MachineId.id()}`;

  service.disconnect();

  Logger.log("messaging connecting to " + serverUrl);

  connection = Primus.connect(serverUrl, {
    pingTimeout: 45000,
    reconnect: {
      max: 1800000,
      min: 2000,
      retries: Infinity
    },
    manual: true
  });

  connection.on("open", () => {
    clearTimeout(initialConnectionTimer);
    connectivity = true;
    Logger.logExternalMessage("messaging service connected");
    Logger.viewerInfo("messaging service connected");

    if (cb && typeof cb === "function") {
      cb();
      cb = null;
    }
  });

  connection.on("close", () => {
    Logger.logExternalMessage("messaging service connection closed");
    Logger.viewerInfo("messaging service connection closed");
  });

  connection.on("end", () => {
    Logger.logExternalMessage("messaging service disconnected");
    Logger.viewerInfo("messaging service disconnected");
  });

  connection.on("data", (data) => {
    Logger.logExternalMessage("message received", JSON.stringify(data));
    Logger.viewerDebug("message received", JSON.stringify(data));
    if (data.msg === "content-update") {
      RiseVision.Viewer.Data.ViewerDataProvider.retrieveData(RiseVision.Viewer.Data.ViewerDataProvider.REASON.UPDATE_MESSAGE_RECEIVED);
    }
    if (data.msg === "reboot-request") {
      data.player = { rebootRequired: true };
      RiseVision.Viewer.Player.RisePlayerController.setPlayerInfo(data);
    }
    if (data.msg === "restart-request") {
      data.player = { restartRequired: true };
      RiseVision.Viewer.Player.RisePlayerController.setPlayerInfo(data);
    }
    if (data.msg === "duplicate-display-id" && !MachineId.matches(data.machineId)) {
      RiseVision.Viewer.Data.ViewerDataController.showDupeId();
      service.disconnect();
      Logger.logExternalMessage("duplicate-display");
      Logger.viewerInfo("duplicate-display");
    }
  });

  connection.on("error", (error) => {
    Logger.logExternalMessage("messaging error", error.stack);
    Logger.viewerError("E999999999", "messaging error", error.stack);
  });

  initialConnectionTimer = setTimeout(() => {
    if (cb && typeof cb === "function") {
      cb();
      cb = null;
    }
  }, INITIAL_CONNECTION_TIMEOUT);

  connection.open();
};

service.disconnect = () => {
  if (connection) {
    connection.end();
  }
};

service.write = (message) => {
  if (connection) {
    connection.write(message);
  }
};

service.canConnect = () => {
  return connectivity;
};

module.exports = service;
