const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const Platform = require("../Platform");
const Utils = require("../Utils");

const ENDPOINT_ID_PARAM = "endpointId=";
const RELOADED_PARAM = "reloaded=";
const RELOAD_COUNT_TIMEOUT = 10 * 60 * 1000;
const AFFECTED_SAFARI = /Safari 1[456]/

let endpointId = "";
let reloadAlreadyRequested = false;

const service = {};

function _isBrowserAffectedBySafariCorsIssue() {
  const browser = Platform.getBrowserNameAndVersion();

  return AFFECTED_SAFARI.test(browser) || browser.indexOf("Chrome Mobile 92") === 0
}

const getStoredId = () => {
  try {
    return window.localStorage.uniqueId
  } catch (e) {
    return "-UNREACHABLE-";
  }
}

const getReloadURL = (reloaded) => {
  const count = reloaded + 1;
  const url = Utils.getViewerUrl();
  const index = url.indexOf( `&${RELOADED_PARAM}` );

  const urlWithParamRemoved = index > 0 ? url.substring(0, index) : url;

  return `${urlWithParamRemoved}&${RELOADED_PARAM}${count}&${ENDPOINT_ID_PARAM}${endpointId}`;
}

const getReloadedCount = (elapsedTime) => {
  if( elapsedTime > RELOAD_COUNT_TIMEOUT ) {
    return 0;
  }

  const reloaded = Utils.getFromQueryString(RELOADED_PARAM);

  return Number(reloaded) || 0;
}

service.init = (id) => {
  endpointId = id;

  if (ConfigurationFactory.isEmbed()) {
    return Promise.resolve();
  }

  if (!ConfigurationFactory.isEmbedSharedSchedule() && !ConfigurationFactory.isInApp()) {
    return Promise.resolve();
  }

  if (!_isBrowserAffectedBySafariCorsIssue()) {
    return Promise.resolve();
  }

  window.corsIssueTimestamp = Date.now();

  return Promise.resolve();
}

service.clearReloadAlreadyRequested = () => {
  reloadAlreadyRequested = false;
}

service.reloadPageIfCorsIssueBrowserMatches = message => {
  if (reloadAlreadyRequested) {
    return true;
  }
  if (!window.corsIssueTimestamp) {
    return false;
  }

  const elapsedTime = Date.now() - window.corsIssueTimestamp;
  const reloaded = getReloadedCount(elapsedTime);

  if (reloaded > 1) {
    return false;
  }

  Logger.viewerImportant( "CORS issue workaround applied after issue was detected", JSON.stringify({
    message, elapsedTime, reloaded, id: getStoredId()
  }));

  setTimeout(() => Utils.reloadViewer(getReloadURL(reloaded)), 10000);

  reloadAlreadyRequested = true;
  return true;
}

module.exports = service;
