const Config = require("../Config/Config");
const Logger = require("../Logger");
const ConfigurationFactory = require("../ConfigurationFactory");
const Utils = require("../Utils");
const TimelineParser = require("../Parser/TimelineParser");
const LowPerformanceDetection = require("../Performance/LowPerformanceDetection");

const service = {};
const HTML_TEMPLATE_URL = "https://widgets.risevision.com/STAGE/templates/PCODE/src/template.html?cid=CID&id=DID&presentationId=PID&frameElementId=FRAMEID&type=TYPE&viewerId=VIEWERID&applyTimeline=APPLYTIMELINE&isSlowDevice=ISSLOWDEVICE";

service.render = (presentation, htmlName, containerName) => {
  let isPortrait = false;

  try {
    isPortrait = JSON.parse(presentation.templateAttributeData || "{}").orientation === "portrait";
  } catch(e) {
    console.log(e);
  }

  const width = isPortrait ? ConfigurationFactory.getWindowHeight() : ConfigurationFactory.getWindowWidth();
  const height = isPortrait ? ConfigurationFactory.getWindowWidth() : ConfigurationFactory.getWindowHeight();
  const top = 0, left = 0;

  Logger.logDebug(`HTMLTemplateRenderer rendering ${htmlName} into container ${containerName}, presentation: ${JSON.stringify(presentation && presentation.name)}`);

  if (!presentation || !htmlName || !containerName) {
    return;
  }

  let url = HTML_TEMPLATE_URL
    .replace("STAGE", Config.HTML_TEMPLATE_ENV)
    .replace("PCODE", presentation.productCode)
    .replace("DID", ConfigurationFactory.getId())
    .replace("CID", ConfigurationFactory.getCompanyId())
    .replace("PID", presentation.id)
    .replace("FRAMEID", "iFrame_" + htmlName)
    .replace("TYPE", ConfigurationFactory.getType())
    .replace("VIEWERID", Utils.getUniqueId())
    .replace("APPLYTIMELINE", TimelineParser.applies() ? "true" : "false")
    .replace("ISSLOWDEVICE", LowPerformanceDetection.isDeviceMarkedAsLowPerformance() ? "true" : "false");

  if (ConfigurationFactory.isSharedschedule()) {
    url += "&env=" + ConfigurationFactory.getEnv(true);
  }

  const viewerHref = Utils.getViewerUrl();
  const riseLitComponentsStagingParam = Utils.getUrlParam(viewerHref, "rlcStage=") || "";
  const commonTemplateStagingParam = Utils.getUrlParam(viewerHref, "ctStage=") || "";

  if (riseLitComponentsStagingParam) {
    url += "&rlcStage=" + riseLitComponentsStagingParam;
  }

  if (commonTemplateStagingParam) {
    url += "&ctStage=" + commonTemplateStagingParam;
  }

  window.createURL(htmlName, containerName, width, height, top, left, 0, 0, url);

  RiseVision.Viewer.UI.ViewerZoomController.init(htmlName, width, height);
};

module.exports = service;
