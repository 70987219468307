const PlayerIntegration = require("./PlayerIntegration")

const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");

const CACHE_NAME = "content-sentinel-cache";
const FILE_BUCKET = "risevision-display-notifications";
const DISPLAY_JSON_FILE = "display.json";
const SCREEN_CONTROL_FILE = "screen-control.txt";
const VALID_INTERFACES = [ "CEC", "RS232" ];

let displayJsonFilePath;
let screenControlFilePath;
let configuration = null;
let isDisplayOff = null;

const isValidConfigurationText = text=>{
  return text.split("\n")
  .some(line=>{
    const fragments = line.trim().split("=");

    return fragments.length === 2 && fragments[0] === "interface" &&
      VALID_INTERFACES.includes( fragments[1].toUpperCase() );
  });
}

const setConfiguration = value=>{
  if (value && isValidConfigurationText(value) ){
    configuration = value;
  } else {
    configuration = null;
  }

  PlayerIntegration.setDisplayControlConfiguration(configuration);

  if (isDisplayOff !== null) {
    setDisplayControl(isDisplayOff);
  }
}

const readCachedResponse = cachePath=>{
  return caches.open(CACHE_NAME)
  .then(cache=>cache.match(cachePath))
  .then(response=>{
    if (!response) {
      return Promise.reject(Error("Missing cache resource"))
    }

    return response;
  });
};

const handleDisplayJsonFile = ({cachePath, status})=>{
  if (!cachePath || (status!=="CURRENT" && status!=="STALE")) {
    return Promise.resolve();
  }

  return readCachedResponse(cachePath)
  .then(response=>response.json())
  .then(displayConfiguration=>{
    const displayControlConfiguration = displayConfiguration && displayConfiguration.displayControl;

    if (!displayControlConfiguration) {
      if( typeof displayControlConfiguration === "undefined" ) {
        // Field not provided in display.json, fallback to screen-control.txt file
        watchDisplayFile( screenControlFilePath );

        return;
      }

      Logger.viewerWarning( "Display control configuration not available" );

      setConfiguration(null);
      return;
    }

    setConfiguration(displayControlConfiguration);
  });
}

const handleConfigurationFile = ({cachePath, status})=>{
  if (!cachePath || (status!=="CURRENT" && status!=="STALE")) {
    setConfiguration(null);

    return Promise.resolve();
  }

  return readCachedResponse(cachePath)
  .then(response=>response.text())
  .then(text=>{
    if (!text) {
      Logger.viewerWarning( "Display control configuration not available" );

      setConfiguration(null);
      return;
    }

    setConfiguration(text);
  });
}

const fileUpdateMessageHandler = event=>{
  return Promise.resolve().then(()=>{
    if (!event || !event.data) {
      return;
    }

    const {filePath, topic} = event.data;
    if (!filePath || topic !== "FILE-UPDATE") {
      return;
    }

    if (filePath === displayJsonFilePath) {
      return handleDisplayJsonFile(event.data);
    } else if (filePath === screenControlFilePath) {
      return handleConfigurationFile(event.data);
    }
  })
  .catch((error = {})=>{
    Logger.viewerError("E999999999", "Display Control File Update Handler Error", JSON.stringify({
      message: event && event.data,
      error: error.stack || error.message
    }));
  });
};

const setDisplayControl = (isOff = false)=>{
  isDisplayOff = isOff;

  if (!configuration) {
    return;
  }

  if (!isOff) {
    PlayerIntegration.turnDisplayOn();
  } else {
    PlayerIntegration.turnDisplayOff();
  }
}

const watchDisplayFile = filePath => {
  window.addEventListener("message", fileUpdateMessageHandler);

  const screenControlWatchMessage = {
    from: "content-consumer",
    to: "content-sentinel-controller",
    msg: "watch",
    filePath
  };

  window.postMessage(screenControlWatchMessage, location.origin);
};

const init = ()=>{
  if (!ConfigurationFactory.viewerSupportsDisplayControl()) {
    return;
  }

  const displayId = ConfigurationFactory.getId();
  displayJsonFilePath = `${FILE_BUCKET}/${displayId}/${DISPLAY_JSON_FILE}`;
  screenControlFilePath = `${FILE_BUCKET}/${displayId}/${SCREEN_CONTROL_FILE}`;

  watchDisplayFile( displayJsonFilePath );
};

const clear = ()=>{
  displayJsonFilePath = null;
  screenControlFilePath = null;
  configuration = null;
  isDisplayOff = null;
};

module.exports = {
  init,
  clear,
  setConfiguration,
  setDisplayControl
};
