const Config = require("../Config/Config");

const service = {};

const callExtensionAPI = ( methodName, options, callback ) => {
  console.log( `PWA Extension: action ${ methodName } called` )

  if (!window.chrome || !window.chrome.runtime) {
    const message = 'PWA Extension could not be invoked: chrome.runtime is not available'
    console.warn( message )

    callback({ error: message })
    return
  }

  const extensionId = Config.CHROME_OS_PWA_PLAYER_EXTENSION_ID;
  const payload = Object.assign( {methodName}, options )
  window.chrome.runtime.sendMessage( extensionId, payload, callback )

  console.log( `PWA Extension: action ${ methodName } message sent; extension id: ${ extensionId }; payload: ${ JSON.stringify(payload) }` )
}

const invokeExtensionMethod = ( methodName, options={} ) => new Promise(( resolve, reject ) => {
  callExtensionAPI( methodName, options, response => {
    if( window.chrome && window.chrome.runtime && window.chrome.runtime.lastError ) {
      reject( window.chrome.runtime.lastError )
    } else if( response.error ) {
      reject( Error(response.error) )
    } else {
      resolve( response )
    }
  })
})

service.initializeAndGetConfiguration = () => {
  return invokeExtensionMethod( 'callInitializeAndGetConfiguration' )
}

service.requestReboot = () => {
  return invokeExtensionMethod( 'callReboot' )
}

service.requestScreenshot = url => {
  return invokeExtensionMethod( 'callRequestScreenshot', {url} )
}

service.setDisplayId = displayId => {
  return invokeExtensionMethod( 'callSetDisplayId', {displayId} )
}

module.exports = service;
