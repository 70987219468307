const service = {};

service.render = (htmlName, containerName) => {
  _createContainer(htmlName, containerName);
};

let _createContainer = (htmlName, containerName) => {
  window.createScheduleContainer(htmlName, containerName);
};

module.exports = service;
