const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");
const Utils = require("../Utils");
const ViewerDataInfo = require("../Data/ViewerDataInfo");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const PresentationRenderer = require("../Renderer/PresentationRenderer");
const PresentationParser = require("../Parser/PresentationParser");
const DistributionParser = require("../Parser/DistributionParser");
const TimelineParser = require("../Parser/TimelineParser");
const PlaceholderController = require("./PlaceholderController");
const HTMLTemplateMessaging = require("../Renderer/HTMLTemplateMessaging");
const TransitionHandler = require("../Renderer/TransitionHandler");
const AnalyticsFactory = require("../AnalyticsFactory");

let instances = [];

const PresentationController = function (item, htmlName, containerName,
  onPresentationReady, onPresentationDone) {
  const factory = {};

  const PRESENTATION_HEADER_SCRIPT = `
    <link type='text/css' rel='stylesheet' href='./style/viewer.css'>
    <script type='text/javascript' src='./scripts/jquery-1.7.1.min.js'></script>
    <script type='text/javascript' src='./scripts/jquery.timers-1.2.js'></script>
    ` +
    // <script type='text/javascript' src='http://www-open-opensocial.googleusercontent.com/gadgets/js/rpc.js'></script>
    // <script type='text/javascript' src='gadgets/globals.js'></script>
    // <script type='text/javascript' src='gadgets/base.js'></script>
    // <script type='text/javascript' src='gadgets/string.js'></script>
    // <script type='text/javascript' src='gadgets/urlparams.js'></script>
    // <script type='text/javascript' src='gadgets/config.js'></script>
    // <script type='text/javascript' src='gadgets/auth.js'></script>
    // <script type='text/javascript' src='gadgets/auth-init.js'></script>
    // <script type='text/javascript' src='gadgets/json-native.js'></script>
    // <script type='text/javascript' src='gadgets/io.js'></script>
    // <script type='text/javascript' src='gadgets/wpm.transport.js'></script>
    // <script type='text/javascript' src='gadgets/rpc.js'></script>
    `<script type='text/javascript' src='./gadgets/gadgets.min.js'></script>

    <script type='text/javascript' src='./scripts/presentationScripts.js'></script>

    <script type='text/javascript' src='./slicebox/js/jquery.slicebox.js'></script>
    <script type='text/javascript' src='./slicebox/js/jquery.easing.1.3.js'></script>

    <script>
    var presFrame = '%s';
    </script>`;

  let status;
  let isPlaying = false;
  let firstPlayback = true;
  let iframeName;
  let presentation;
  const placeholders = [];

  const _onPresentationDoneIfPlaying = () => {
    if (isPlaying && onPresentationDone) {
      onPresentationDone();
    }
  };

  const htmlTemplateMessaging = new HTMLTemplateMessaging(_onPresentationDoneIfPlaying, htmlName);
  const transitionHandler = new TransitionHandler(containerName, htmlName, item.transitionType);

  let readyTimer;

  factory.init = (load) => {
    if (status === PlaylistItemInfo.LOADING_STATUS) {
      if (item && item.type === PlaylistItemInfo.TYPE_PRESENTATION) {
        presentation = ViewerDataInfo.getPresentation(item.objectReference);

        if (presentation) {
          _initPlaceholders();

          if (load) {
            _loadPresentation();
          } else {
            status = PlaylistItemInfo.UNLOADED_STATUS;
            _presentationReady();
          }
        }
      }
      else {
        if (onPresentationDone) {
          htmlTemplateMessaging.addListenerForTemplatePlayUntilDone();
        }
        _addPresentation();

        if (item && item.presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
          htmlTemplateMessaging.onRiseComponentsReady(_onPlaceholderReady);
        } else {
          _onPlaceholderReady();
        }
      }
    }
    // if this presentation is a multiple, init has already executed for another instance
    else if (load) {
      _loadPresentation();
    }
  };

  const _setDonePlaceholderFirst = () => {
    if (presentation.donePlaceholder) {
      for (let i = 0; i < presentation.placeholders.length; i++) {
        const ph = presentation.placeholders[i];
        if (ph.id === presentation.donePlaceholder) {
          presentation.placeholders.splice(i, 1);
          presentation.placeholders.unshift(ph);

          return;
        }
      }
    }
  };

  let _initPlaceholders = () => {
    PresentationParser.parsePresentation(presentation);
    DistributionParser.parseDistribution(presentation);

    const headerScript = PRESENTATION_HEADER_SCRIPT.replace("%s", iframeName);
    PresentationParser.addHeaderScripts(presentation, headerScript);

    PresentationParser.removePresentationObject(presentation);

    // [AD] Sets the done placeholder as the first in the list to prevent timing issues
    _setDonePlaceholderFirst();

    let placeholderDoneCommand = _onPlaceholderDone;

    for (let i = 0; i < presentation.placeholders.length; i++) {
      const ph = presentation.placeholders[i];
      const phName = htmlName + "_" + ph.id;
      if (!ph.type || ph.type === PlaylistItemInfo.TYPE_PLAYLIST) {
        if (ph.distributeToAll || ConfigurationFactory.checkDistribution(ph.distribution)) {
          // if there is no donePlaceholder
          // Send done command to the first placeholder
          let doneCommand = null;
          if (onPresentationDone && placeholderDoneCommand &&
              (!presentation.donePlaceholder ||
              presentation.donePlaceholder === ph.id)) {
            doneCommand = placeholderDoneCommand;

            // send done for only 1 placeholder
            placeholderDoneCommand = null;
          }

          const placeholder = new PlaceholderController(ph,
              iframeName,
              phName,
              _onPlaceholderReady,
              doneCommand);

          placeholders.push(placeholder);

          if (!TimelineParser.canPlay(ph)) {
            PresentationParser.hidePlaceholder(presentation, ph.id);
          }
        }
        else {
          PresentationParser.hidePlaceholder(presentation, ph.id);
          PlaceholderController.sendReadyToPlayerAllItems(ph);
        }
      }
    }

    Logger.setEndpointLoggerContentFields({presentationId: item.presentationId || item.objectReference});

    for (let j = 0; j < placeholders.length; j++) {
      placeholders[j].init();
      placeholders[j].updateHtml(presentation);
    }
  };

  let _loadPresentation = () => {
    _addPresentation();

    let allReady = true;
    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i].getStatus() !== PlaylistItemInfo.READY_STATUS) {
        allReady = false;
        break;
      }
    }

    // if there are no placeholders
    if (placeholders.length === 0 || allReady) {
      _onPlaceholderReady();
    }
  };

  let _addPresentation = () => {
    PresentationRenderer.render(item, presentation, htmlName, containerName);

    // Timer that will cut loading to a maximum of 20 seconds.
    readyTimer = setTimeout(_forceDataReady, 20 * 1000);

    status = PlaylistItemInfo.ADDED_STATUS;
  };

  let _onPlaceholderReady = () => {
    let allReady = true;
    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i].getStatus() !== PlaylistItemInfo.READY_STATUS) {
        allReady = false;
      }
    }

    if (allReady && status !== PlaylistItemInfo.ALL_READY_STATUS) {
      status = PlaylistItemInfo.ALL_READY_STATUS;

      clearTimeout(readyTimer);

      _presentationReady();
    }

    if (status === PlaylistItemInfo.ADDED_STATUS) {
      status = PlaylistItemInfo.READY_STATUS;
      _presentationReady();
    }
  };

  let _forceDataReady = () => {
    // Confirm readiness of at least one placeholder if there is at least one
    if (placeholders.length === 0 || placeholders.some(ph => ph.getStatus() === PlaylistItemInfo.READY_STATUS)) {
      status = PlaylistItemInfo.ALL_READY_STATUS;
      _presentationReady();
    } else {
      readyTimer = setTimeout(_forceDataReady, 20 * 1000);
    }
  };

  let _presentationReady = () => {
    const type = (item.type === PlaylistItemInfo.TYPE_PRESENTATION &&
      item.presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) ? item.presentationType : item.type;
    Logger.logDebug(`${type} - ${htmlName} - Ready!`);

    if (onPresentationReady) {
      onPresentationReady();
    }
  };

  let _onPlaceholderDone = () => {
    if (onPresentationDone) {
      onPresentationDone();
    }
  };

  function _trackPlayback() {
    if (firstPlayback) {
      AnalyticsFactory.trackPlayback(item);

      firstPlayback = false;
    }
  }

  factory.play = () => {
    Logger.setEndpointLoggerContentFields({presentationId: item.presentationId || item.objectReference});
    Logger.logDebug(`Presentation ${htmlName} ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Play!`);

    _trackPlayback();

    if (!isPlaying) {
      htmlTemplateMessaging.sendPresentationEvent("rise-presentation-play");
    }

    // if (!isPlaying) {
    isPlaying = true;

    if (placeholders.length === 0 && item.type === PlaylistItemInfo.TYPE_PRESENTATION && !presentation.backgroundStyle) {
      _onPlaceholderDone();

      return;
    }

    for (let i = 0; i < placeholders.length; i++) {
      if (!placeholders[i].play()) {
        _onPlaceholderDone();

        return;
      }
    }

    if (isPlaying) {
      if (ConfigurationFactory.isEmbed()) {
        window.showElement(htmlName);
      } else {
        transitionHandler.show();
      }
    }
    // }
  };

  factory.stop = () => {
    Logger.logDebug(`Presentation ${htmlName} ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Stop!`);

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    if (isPlaying) {
      isPlaying = false;

      if (ConfigurationFactory.isEmbed()) {
        window.hideElement(htmlName);

        _stopPlaceholders();
      } else {
        transitionHandler.hide(_stopPlaceholders);
      }
    }
  };

  factory.pause = () => {
    Logger.logDebug(`Presentation ${htmlName} ${ConfigurationFactory.isEmbed() ? "(Embedded) " : ""}- Pause!`);

    htmlTemplateMessaging.sendPresentationEvent("rise-presentation-stop");

    if (isPlaying) {
      isPlaying = false;

      if (ConfigurationFactory.isEmbed()) {
        window.hideElement(htmlName);

        _pausePlaceholders();
      } else {
        transitionHandler.hide(_pausePlaceholders);
      }
    }
  };

  let _pausePlaceholders = () => {
    for (let i = 0; i < placeholders.length; i++) {
      placeholders[i].pause();
    }
  };

  let _stopPlaceholders = () => {
    for (let i = 0; i < placeholders.length; i++) {
      placeholders[i].stop();
    }
  };

  factory.isReady = () => {
    return status === PlaylistItemInfo.ALL_READY_STATUS;
  };

  factory.getStatus = () => {
    return status;
  };

  factory.load = () => {
    if (status === PlaylistItemInfo.UNLOADED_STATUS) {
      _loadPresentation();
    }
  };

  factory.unload = () => {
    // only unload READY presentations that are NOT playing
    if (status >= PlaylistItemInfo.READY_STATUS && !isPlaying) {
      status = PlaylistItemInfo.UNLOADED_STATUS;

      for (let j = 0; j < placeholders.length; j++) {
        placeholders[j].unload();
      }

      Utils.destroyElement(htmlName, containerName);
    }
  };

  factory.clearInstance = () => {
    htmlTemplateMessaging.removeListenerForTemplatePlayUntilDone();
  };

  (function () {
    iframeName = "iFrame_" + htmlName;

    status = PlaylistItemInfo.LOADING_STATUS;
  }());

  instances.push(factory);

  return factory;
};

PresentationController.clearInstances = () => {
  instances.forEach((instance) => {
    instance.clearInstance();
  });
  instances = [];
};

module.exports = PresentationController;
