const ConfigurationFactory = require("./ConfigurationFactory");
const BrowserCompatibilityUIController = require("./UI/BrowserCompatibilityUIController");
const FloatingNotificationsController = require("./UI/FloatingNotificationsController");

const DISABLE_ALLOWED_BROWSER_CHECK = false;

const compatibilityErrorCode = "E000000102";
const oldBrowserErrorCode = "E000000103";
const httpErrorCode = "E000000003";

const logger = require("./Logger");
const configuration = require("./ConfigurationFactory");
const platform = require("./Platform");

const SUPPORTED_BROWSER_RECOMMENDATION =
  "<p>For the best experience we recommend using the latest version of Chrome, Edge or Safari.</p>";

function needsCompatibilityCheck() {
    if (ConfigurationFactory.isWebPlayer()) {return true;}

    if (ConfigurationFactory.isSharedschedule() && !ConfigurationFactory.isInApp()) {
      return true;
    }

    return false;
}

function isForcedFailure(failureType = "compatibility") {
  const forceFail = `force${failureType}failure=true`;

  if (location && location.href && location.href.includes(forceFail)) {
    if (failureType==="compatibility") {
      BrowserCompatibilityUIController.showIncompatible();
    } else {
      BrowserCompatibilityUIController.showUpdatePlayer();
    }

    return true;
  }
}

function isLoadedOverHTTPS() {
  if (window.location && window.location.protocol === "http:") {
    logger.viewerError(httpErrorCode, "window or parent loaded over http");
    BrowserCompatibilityUIController.showHTTPNotSupported();

    return false;
  }

  return true;
}

function isLocalStorageAvailable() {
  try {
    localStorage.removeItem("remove_item_should_not_fail");

    logger.viewerDebug("localStorage is available");
    return true;
  }
  catch(e) {
    console.error(e);
    logger.viewerError(compatibilityErrorCode, "localStorage not available");

    return false;
  }
}

function isIndexedDBAvailable() {
  if (!window.indexedDB || !window.indexedDB.open) {
    logger.viewerError(compatibilityErrorCode, "IndexedDB not available");
    return false;
  }

  const req = window.indexedDB.open("db_will_not_be_used", 1);

  const expectedAPI = [
    "onerror",
    "onsuccess",
    "result",
    "source"
  ];

  if (!expectedAPI.every(property=>property in req)) {
    logger.viewerError(compatibilityErrorCode, "unexpected indexeddb api");
    return false;
  }

  logger.viewerDebug("IndexedDB is available");
  return true;
}

function checkCacheStorageAvailable() {
  if (!window.caches || !window.caches.open) {
    logger.viewerError(compatibilityErrorCode, "CacheStorage not available");
    return Promise.resolve(false);
  }

  return window.caches.keys()
  .then(()=>{
    logger.viewerDebug("CacheStorage is available");

    return true;
  })
  .catch(()=>{
    logger.viewerError(compatibilityErrorCode, "CacheStorage not available");

    return false;
  });
}

function isServiceWorkerAvailable() {
  if (!window.navigator || !("serviceWorker" in navigator)) {
    logger.viewerError(compatibilityErrorCode, "ServiceWorker not available");
    return false;
  }

  logger.viewerDebug("ServiceWorker is available");
  return true;
}

function getUnavailableApiNames(
  localStorageAvailable, indexedDBAvailable, cacheStorageAvailable,
  serviceWorkerAvailable
) {
  const names = [];

  if (!localStorageAvailable) {
    names.push( "local storage" )
  }
  if (!indexedDBAvailable) {
    names.push( "indexedDB" )
  }
  if (!cacheStorageAvailable) {
    names.push( "cache storage" )
  }
  if (!serviceWorkerAvailable) {
    names.push( "service workers" )
  }

  return names;
}

module.exports = {
  checkBrowserCompatibility() {
    if (module.exports.isForcedFailure()) {return Promise.resolve(false);}

    if (!needsCompatibilityCheck()) {return Promise.resolve(true);}

    let localStorageAvailable = isLocalStorageAvailable();
    let indexedDBAvailable = isIndexedDBAvailable();
    let cacheStorageCheck = checkCacheStorageAvailable();
    let serviceWorkerAvailable = isServiceWorkerAvailable();

    return cacheStorageCheck.then(cacheStorageAvailable=>{
      const unavailableApiNames = getUnavailableApiNames(
        localStorageAvailable, indexedDBAvailable, cacheStorageAvailable, serviceWorkerAvailable);
      const isCompatible = unavailableApiNames.length === 0;
      const isSupportedBrowser = configuration.isWebPlayerBasedRisePlayer() ||
        platform.isSupportedBrowserAndVersionForWebPlayer();

      if (!isCompatible) {
        BrowserCompatibilityUIController.showIncompatible(isSupportedBrowser, unavailableApiNames);
      } else if (!isSupportedBrowser) {
        FloatingNotificationsController.addNotification(SUPPORTED_BROWSER_RECOMMENDATION);
      }

      return isLoadedOverHTTPS() && isCompatible;
    });
  },
  checkAllowedBrowser() {
    if (module.exports.isForcedFailure("allowedbrowser")) {
      return false;
    }

    if (module.exports.isAllowedBrowserCheckDisabled()) {
      return true;
    }

    const allowed = platform.isAllowedBrowserAndVersion()

    if (!allowed) {
      logger.viewerError(oldBrowserErrorCode, "Old browser detected");

      BrowserCompatibilityUIController.showUpdatePlayer();
    }

    return allowed;
  },
  needsCompatibilityCheck,
  isAllowedBrowserCheckDisabled() {
    return DISABLE_ALLOWED_BROWSER_CHECK;
  },
  isForcedFailure
}
