const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const ConfigurationFactory = require("../ConfigurationFactory");
const Logger = require("../Logger");

const service = {};

service.render = (scheduleItem, presentation, htmlName, containerName) => {
  const width = ConfigurationFactory.getWindowWidth();
  const height = ConfigurationFactory.getWindowHeight();
  const top = 0, left = 0;

  Logger.logDebug(`PresentationRenderer rendering ${htmlName} into container ${containerName}, item: ${JSON.stringify(scheduleItem && scheduleItem.name)}`);

  if (!scheduleItem || !htmlName || !containerName) {
    return;
  }

  if (scheduleItem.type === PlaylistItemInfo.TYPE_PRESENTATION && presentation) {
    const hidePointer = presentation.hidePointer && (ConfigurationFactory.isDisplay() || ConfigurationFactory.isWebPlayer());

    _addPresentation(htmlName, containerName, top, left,
        presentation.layout,
        presentation.width + presentation.widthUnits,
        presentation.height + presentation.heightUnits,
        hidePointer, ConfigurationFactory.isPreview() && !ConfigurationFactory.isEmbed());

    RiseVision.Viewer.UI.ViewerZoomController.init(htmlName, presentation.width, presentation.height);
  } else if (scheduleItem.type === PlaylistItemInfo.TYPE_URL && scheduleItem.objectReference) {
    let url = scheduleItem.objectReference;

    if (scheduleItem.presentationType === PlaylistItemInfo.TYPE_HTML_TEMPLATE) {
      url += url.indexOf("?") > -1 ? "&" : "?";
      url += "frameElementId=" + "iFrame_" + htmlName;
    }

    _addUrl(htmlName, containerName, width, height, top, left, 0, 0, url);

    RiseVision.Viewer.UI.ViewerZoomController.init(htmlName, width, height);
  }
};

let _addPresentation = (frameName, containerName, top, left, html, presentationWidth, presentationHeight, hidePointer, enableScroll) => {
  window.createPresentation(frameName, containerName, top, left, html, presentationWidth, presentationHeight, hidePointer, enableScroll);
};

let _addUrl = (frameName, containerName, width, height, top, left, scrollx, scrolly, url) => {
  window.createURL(frameName, containerName, width, height, top, left, scrollx, scrolly, url);
};

module.exports = service;
