const Logger = require("../Logger");
const ViewerDataInfo = require("../Data/ViewerDataInfo");

const HTMLTemplateMessaging = (onPresentationDone, htmlName, messageHandler) => {
  const factory = {};
  const HTML_TEMPLATE_DOMAIN = "https://widgets.risevision.com";
  const iframeName = "iFrame_" + htmlName;

  let riseComponentsReadyReceived = false;
  let onRiseComponentsReadyCallback;
  let templateErrorReceived = false;
  let templateErrorCallback;

  const _isMessageForCurrentIFrame = (event, topic) => {
    return typeof event.data === "object" &&
    (!topic || event.data.topic === topic) &&
    event.data.frameElementId === iframeName;
  };

  const _processTemplateEvent = (event) => {
    if (_isMessageForCurrentIFrame(event, "rise-components-ready")) {
      Logger.logDebug(`Presentation ${htmlName} - rise-components-ready received`);

      riseComponentsReadyReceived = true;
      onRiseComponentsReadyCallback && onRiseComponentsReadyCallback();
    } else if (_isMessageForCurrentIFrame(event, "template-error")) {
      Logger.logDebug(`Presentation ${htmlName} - template-error received`);

      templateErrorReceived = true;
      templateErrorCallback && templateErrorCallback();

      window.removeEventListener("message", _processTemplateEvent);
    } else if (_isMessageForCurrentIFrame(event, "get-template-data")) {
      _postTemplateData(event.data);
    } else if (_isMessageForCurrentIFrame(event, "get-presentation-object")) {
      _postPresentationObject(event.data);
    } else if (_isMessageForCurrentIFrame(event, "log-endpoint-event")) {
      const eventData = Object.assign({}, event.data);
      delete eventData.topic;
      delete eventData.frameElementId;

      Logger.logTemplateEvent(eventData);
    } else if (_isMessageForCurrentIFrame(event, "log-endpoint-heartbeat")) {
      const eventData = Object.assign({}, event.data);
      delete eventData.topic;
      delete eventData.frameElementId;

      Logger.recordUptimeHeartbeat(eventData);
    } else if (_isMessageForCurrentIFrame(event, "get-heartbeat-interval")) {
      event.source.postMessage({
        topic: "heartbeat-interval",
        intervalMS: Logger.heartbeatInterval()
      }, "*");
    } else if (_isMessageForCurrentIFrame(event) && messageHandler) {
      messageHandler(event.data);
    }
  };

  window.addEventListener("message", _processTemplateEvent);

  factory.onRiseComponentsReady = (readyCallback, errorCallback) => {
    if (riseComponentsReadyReceived) {
      readyCallback && readyCallback();
    } else if (templateErrorReceived) {
      errorCallback && errorCallback();
    } else {
      onRiseComponentsReadyCallback = readyCallback;
      templateErrorCallback = errorCallback;
    }
  };

  factory.addListenerForTemplatePlayUntilDone = () => {
    window.addEventListener("message", _processTemplatePUD);
  };

  factory.removeListenerForTemplatePlayUntilDone = () => {
    window.removeEventListener("message", _processTemplatePUD);
  };

  const _processTemplatePUD = (event) => {
    if (_isMessageForCurrentIFrame(event, "template-done")) {
      onPresentationDone();
    }
  };

  factory.sendPresentationEvent = (topic) => {
    factory.sendPresentationMessage({ topic }, "*");
  };

  factory.sendPresentationMessage = (message, domain) => {
    const presentationIframe = document.getElementById(iframeName);

    if (presentationIframe) {
      presentationIframe.contentWindow.postMessage(message, domain);
    }
  };

  function _postMessageToTemplate(message) {
    factory.sendPresentationMessage(message, HTML_TEMPLATE_DOMAIN);
  }

  function _postDisplayData(id) {
    const companyData = ViewerDataInfo.getCompanyData();

    const message = {
      type: "displayData",
      id,
      value: {
        displayAddress: {
          city: companyData.city,
          province: companyData.province,
          country: companyData.country,
          postalCode: companyData.postalCode
        },
        companyBranding: {
          logoFile: companyData.brandingLogoFile,
          baseColor: companyData.brandingBaseColor,
          accentColor: companyData.brandingAccentColor
        }
      }
    };

    _postMessageToTemplate(message);
  }

  function _postPresentationData(id) {
    const presentation = ViewerDataInfo.getPresentation(id);

    if (!presentation) {
      return;
    }

    const attributeData = JSON.parse(presentation.templateAttributeData || "{}");
    const message = {
      type: "attributeData",
      id,
      value: attributeData
    };

    _postMessageToTemplate(message);
  }

  function _postTemplateData(data) {
    if (!data) {
      return;
    }

    Logger.logDebug(`_postTemplateData() request - id: ${data.id}`);

    _postDisplayData(data.id);

    _postPresentationData(data.id);

    _postMessageToTemplate({
      type: "sendStartEvent",
      id: data.id
    });
  }

  function _postPresentationObject(data) {
    if (!data) {
      return;
    }

    Logger.logDebug(`_postPresentationObject() request - id: ${data.id}`);

    const presentation = ViewerDataInfo.getPresentation(data.id);

    const message = {
      type: "presentationObject",
      id: data.id,
      value: presentation
    };

    _postMessageToTemplate(message);
  }

  return factory;
};

module.exports = HTMLTemplateMessaging;
