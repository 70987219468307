const Logger = require("./Logger");
const Config = require("./Config/Config");
const Global = require("./Config/Global");
const ConfigurationFactory = require("./ConfigurationFactory");
const Object = require("es6-polyfills/lib/polyfills/object");
const INITIAL_CONNECTION_TIMEOUT = 20 * Global.ONE_SECOND_DELAY;
const service = {};
const messageHandlers = [];

let connection;
let connectivity = false;
let initialConnectionTimer = null;
let imageLSTestSent = false;
let hasAlreadyConnectedBefore = false;

service.init = (cb) => {
  if (window.useWindowMessagingForLocalMessaging) { return cb(); }
  if (!window.disableViewerContentFetch) { return cb(); }
  if (ConfigurationFactory.isEmbed()) { return cb(); }
  if (ConfigurationFactory.isPreview()) { return cb(); }
  if (ConfigurationFactory.isSharedschedule()) { return cb(); }
  if (ConfigurationFactory.isWebPlayer()) { return cb(); }
  hasAlreadyConnectedBefore = false;

  if (!window.PrimusLMS) {
    Logger.log("primus-local-messaging.js was not loaded");
    return cb();
  }

  service.disconnect();

  Logger.logExternalMessage("Attempting LMS connection");
  Logger.viewerDebug("Attempting LMS connection");

  connection = PrimusLMS.connect(Config.LOCAL_MESSAGING_SERVER_URL, {
    reconnect: {
      max: 1800000,
      min: 2000,
      retries: Infinity
    },
    manual: true
  });

  connection.on("open", () => {
    clearTimeout(initialConnectionTimer);
    connectivity = true;
    Logger.logExternalMessage("LMS connected");
    Logger.viewerDebug("LMS connected");

    if (!hasAlreadyConnectedBefore) {
      sendFirstConnectionMessage();
      hasAlreadyConnectedBefore = true;
    }

    if (cb && typeof cb === "function") {
      cb();
      cb = null;
    }
  });

  connection.on("close", () => {
    Logger.logExternalMessage("LMS connection closed");
    Logger.viewerDebug("LMS connection closed");
  });

  connection.on("end", () => {
    connectivity = false;
    Logger.logExternalMessage("LMS disconnected");
    Logger.viewerDebug("LMS disconnected");
  });

  connection.on("reconnected", () => {
    connectivity = true;
    Logger.logExternalMessage("LMS reconnected");
    Logger.viewerDebug("LMS reconnected");
  });

  connection.on("error", (error) => {
    Logger.logExternalMessage("LMS error", error.stack);
    Logger.viewerError("E999999999", "LMS error", error.stack);
  });

  connection.on("data", (data) => {
    messageHandlers.forEach((handler) => {
      handler(data);
    });
  });

  initialConnectionTimer = setTimeout(() => {
    if (cb && typeof cb === "function") {
      cb();
      cb = null;
    }
  }, INITIAL_CONNECTION_TIMEOUT);

  connection.open();
};

const sendFirstConnectionMessage = () => {
  const firstConnectionMessage = { topic: "ws-client-connected" };
  service.write(firstConnectionMessage);
};

service.disconnect = () => {
  if (connection) {
    connection.end();
  }
};

service.write = (message) => {
  const msg = typeof message === "string" ? { msg: message } : message;
  const isTestFile = message.filePath && message.filePath.indexOf("local-storage-test/test-1x1.png") !== -1;
  if (isTestFile) {
    // only allow a display to send test WATCH message once to account for multiple image widget instances in a presentation
    if (imageLSTestSent) { return; }
    imageLSTestSent = true;
  }
  if (!window.useWindowMessagingForLocalMessaging && connection) {
    connection.write(Object.assign({}, { from: Config.LOCAL_MESSAGING_CLIENT_NAME }, msg));
  } else if (typeof window.postToPlayer === "function") {
    window.postToPlayer(Object.assign({}, { from: Config.LOCAL_MESSAGING_CLIENT_NAME }, msg));
  }
};

service.receiveMessages = (action) => {
  if (!window.useWindowMessagingForLocalMessaging) {
    messageHandlers.push((data) => {
      action(data);
    });
  } else {
    if (typeof window.receiveFromPlayer !== "function") {
      return Logger.logExternalMessage("error", "expected window.receiveFromPlayer");
      return Logger.viewerError("E999999999", "expected window.receiveFromPlayer");
    }

    window.receiveFromPlayer("local-messaging", action);
  }
};

service.canConnect = () => {
  return window.useWindowMessagingForLocalMessaging || connectivity;
};

module.exports = service;
