"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENTS_SCHEMA = exports.INSERT_SCHEMA = exports.GRANT_URL = exports.HEARTBEAT_SERVICE_URL = exports.EVENTS_SERVICE_URL = exports.LOG_LEVEL_FILE_NAME = exports.DISPLAY_BUCKET = exports.SCHEDULE_BUCKET = exports.GCS_SERVICE_URL = void 0;
var GCS_SERVICE_URL = "https://storage.googleapis.com/storage/v1/b/BUCKET/o/OBJECT?alt=media&ifGenerationNotMatch=GENERATION";
exports.GCS_SERVICE_URL = GCS_SERVICE_URL;
var SCHEDULE_BUCKET = "risevision-schedule-notifications";
exports.SCHEDULE_BUCKET = SCHEDULE_BUCKET;
var DISPLAY_BUCKET = "risevision-display-notifications";
exports.DISPLAY_BUCKET = DISPLAY_BUCKET;
var LOG_LEVEL_FILE_NAME = "loglevel.json";
exports.LOG_LEVEL_FILE_NAME = LOG_LEVEL_FILE_NAME;
var BQ_SERVICE_URL = "https://www.googleapis.com/bigquery/v2/projects/endpoint-event-logs/datasets/DATASET_ID/tables/TABLE_ID/insertAll";
var EVENTS_SERVICE_URL = BQ_SERVICE_URL.replace("DATASET_ID", "logs").replace("TABLE_ID", "eventLog");
exports.EVENTS_SERVICE_URL = EVENTS_SERVICE_URL;
var HEARTBEAT_SERVICE_URL = BQ_SERVICE_URL.replace("DATASET_ID", "heartbeats").replace("TABLE_ID", "uptimeHeartbeats");
exports.HEARTBEAT_SERVICE_URL = HEARTBEAT_SERVICE_URL;
var GRANT_URL = atob("aHR0cHM6Ly93d3cuZ29vZ2xlYXBpcy5jb20vb2F1dGgyL3YzL3Rva2VuP2NsaWVudF9pZD0xMDg4NTI3MTQ3MTA5LTZxMW8ydnRpaG4zNDI5MnBqdDRja2htaGNrMHJrMG83LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tJmNsaWVudF9zZWNyZXQ9bmxaeXJjUExnNm9Fd085ZjlXZm4yOVdoJnJlZnJlc2hfdG9rZW49MS94enQ0a3d6RTFIN1c5Vm5LQjhjQWFDeDZ6YjRFczRuS0VvcWFZSGRURDE1SWdPckpEdGR1bjZ6SzZYaUFUQ0tUJmdyYW50X3R5cGU9cmVmcmVzaF90b2tlbg==");
exports.GRANT_URL = GRANT_URL;
var INSERT_SCHEMA = {
  kind: "bigquery#tableDataInsertAllRequest",
  skipInvalidRows: false,
  ignoreUnknownValues: false,
  rows: [{
    insertId: "",
    json: {}
  }]
};
exports.INSERT_SCHEMA = INSERT_SCHEMA;
var EVENTS_SCHEMA = {
  event: "",
  display_id: "",
  viewer_version: "",
  event_details: "",
  ts: 0
};
exports.EVENTS_SCHEMA = EVENTS_SCHEMA;