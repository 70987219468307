const Logger = require("../Logger");

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const MAX_ADDITIONAL_REBOOT_TIME = 30 * MINUTES;

const service = {};

const shouldScheduleReboot = content => {
  if (!content || !content.display || !content.display.restartEnabled) {
    Logger.viewerDebug("Scheduled reboot not enabled in display settings")

    return false;
  }

  if (!content.display.restartTime || !content.display.restartTime.includes(':')) {
    Logger.viewerError("E999999999", "Invalid reboot schedule time", content.display.restartTime);

    return false;
  }

  return true;
}

const parseRebootDate = (restartHHMM, now) => {
  const hours = parseInt( restartHHMM.split(":")[0], 10 );
  const minutes = parseInt( restartHHMM.split(":")[1], 10 );

  return getNextDateAndTimeFor( hours, minutes, now );
}

const getNextDateAndTimeFor = ( hours, minutes, now ) => {
  const rebootDate = new Date();

  rebootDate.setHours(hours);
  rebootDate.setMinutes(minutes);

  if (rebootDate.getTime() < now) {
    rebootDate.setDate(rebootDate.getDate() + 1);
  }

  return rebootDate;
}

service.calculateExtraRebootTime = (restartTime) => {
  if( restartTime !== "02:00" ) {
    return 0;
  }

  return generateExtraTimePadding();
}

const generateExtraTimePadding = () => Math.floor( MAX_ADDITIONAL_REBOOT_TIME * Math.random() );

service.scheduleReboot = (content, callback, now = Date.now(), timeout = setTimeout) => {
  if (!shouldScheduleReboot(content)) {
    scheduleContentReload(now, timeout);

    return;
  }

  const restartTime = content.display.restartTime;
  const rebootDate = parseRebootDate(restartTime, now);

  Logger.viewerDebug("Scheduling reboot", rebootDate.toString());

  const timeToReboot = rebootDate.getTime() - now;

  if( timeToReboot < 0 ) {
    Logger.viewerError("E999999999", "Invalid calculated time to reboot", `${ timeToReboot }`);

    return;
  }

  const randomExtraTime = service.calculateExtraRebootTime(restartTime);
  if (randomExtraTime) {
    Logger.viewerDebug("Adding reboot extra milliseconds", String(randomExtraTime));
  }

  timeout(callback, timeToReboot + randomExtraTime);
}

const scheduleContentReload = (now, timeout) => {
  Logger.viewerDebug( "Daily reboot not configured, scheduling content reload" );

  const reloadTime = getNextDateAndTimeFor( 1, 0, now );
  const timeToReload = reloadTime.getTime() - now;

  if( timeToReload < 0 ) {
    Logger.viewerError("E999999999", "Invalid calculated time to reload", `${ timeToReload }`);

    return;
  }

  const randomExtraTime = generateExtraTimePadding();

  timeout( reloadContentController, timeToReload + randomExtraTime );
}

const reloadContentController = () => {
  Logger.viewerDebug( "Reloading viewer controller" );
  RiseVision.Viewer.EntryPoint.reloadContentController();

  setTimeout( reloadContentController, 24 * HOURS );
}

module.exports = service;
