const Logger = require("./Logger");
const ViewerNotificationsPanel = require("./UI/ViewerNotificationsPanel");

const DUPLICATE_ID_ERROR_MESSAGE = `
  <p>
    This display id is currently being used by another device.
    If you need assistance please contact
    <a href="mailto:support@risevision.com">support@risevision.com</a>.
  </p>
`;

const service = {};

service.handleDuplicateId = logOnly=>{
  Logger.viewerError("E000000110", "duplicate id", JSON.stringify({
    logOnly
  }));

  if (!logOnly) {
    Logger.disableRemoteLogging();
    window.RiseVision.Viewer.EntryPoint.destroyContent();

    ViewerNotificationsPanel.show(DUPLICATE_ID_ERROR_MESSAGE);
  }
};

module.exports = service;
