const Utils = require("../Utils");
const PlaylistItemUtils = require("./PlaylistItemUtils");

const VideoController = function (playlistItem, htmlName, onGadgetReady, onGadgetDone, videoController) {
  const factory = {};

  let isReady = false, isPlaying = false;
  let videoInfo;

  factory.updateHtml = (presentation) => {

  };

  factory.setReady = (frame, canPlay, canStop, canPause, canReportReady, canReportDone) => {
// presFrame = frame;

    if (!isReady) {
      isReady = true;
      if (onGadgetReady) {
        onGadgetReady();
      }
//  // if GadgetReadyCommand is null, than this is a single Gadget
//  // in placeholder (no ready command, just Start playing right away)
      else if (!isPlaying) {
        factory.play(true);
      }
    }
  };

  factory.setError = (presFrame, reason) => {
    videoController.reportError(factory, reason);
  };

  factory.setDone = () => {
    if (isPlaying) {
      if (onGadgetDone) {
        isPlaying = false;
        onGadgetDone();
      } else {
        factory.stop(false);
        factory.play(false);
      }
    }
  };

  factory.play = (show) => {
    if (!isPlaying) {
      isPlaying = true;
      if (isReady) {
        videoController.play(factory, show);
      }
    }
  };

  factory.stop = (hide) => {
//    if (isPlaying) {
    isPlaying = false;
    videoController.stop(factory, hide);
//    }
  };

  factory.pause = (hide) => {
// removed isPlaying check (since pause can be called on a "paused" item to hide it)
//    if (isPlaying) {
    isPlaying = false;
    videoController.pause(factory, hide);
//    }
  };

  factory.unload = () => {
    isReady = false;
  };

  factory.isReady = () => {
    if (!isReady) {
      videoController.readyRequested(factory);
    }
    return isReady;
  };

  factory.getItem = () => { return playlistItem; };

  factory.getVideoInfo = () => { return videoInfo; };

  const _parseVideoInfo = (objectData) => {
    videoInfo = {};

    videoInfo.videoUrl = Utils.getUrlParam(objectData, "up_url=");
    videoInfo.videoExtension = Utils.getUrlParam(objectData, "up_extension=");
    videoInfo.volume = Utils.getUrlParam(objectData, "up_volume=");
    videoInfo.autoHide = Utils.getUrlParam(objectData, "up_autoHide=");
    videoInfo.carryOn = Utils.getUrlParam(objectData, "up_carryOn=");
  };

  (function () {
    _parseVideoInfo(playlistItem.objectData);

    PlaylistItemUtils.registerGadget(htmlName, factory);

    videoController.addVideo(htmlName, factory);
  }());

  return factory;
};


module.exports = VideoController;
