const PopupPanel = require("./PopupPanel");
const Config = require("../Config/Config");
const AnalyticsFactory = require("../AnalyticsFactory");

class ViewerPreviewWidget extends PopupPanel {
  constructor() {
    super({
      autoHide: false,
      modalBackdrop: false,
      containerId: "viewer-header-div",
    });

    this._populatePieces();

    this._styleControls();

    this._initItemButton();
    this._initHandlers();
  }

  _populatePieces() {
    const $c = this.$content;

    this.$signupButton = $c.find(".signup-button");
    this.$itemButton = $c.find(".item-button");
    this.$loginButton = $c.find(".login-button");
    this.$showButton = $c.find(".show-button");
    this.$hideButton = $c.find(".hide-button");
    this.$alertPanel = $c.find(".alert-panel");
    this.$zoomSelector = $c.find(".zoom-selector");
    this.$showButton.hide();
  }

  _styleControls() {
    this._resizeLarge();

    // TODO
    // itemButton.addStyleName("link-action");
  }

  _resizeLarge() {
    this.$content.removeClass("collapsed");
    this.$content.addClass("large");
  }

  _initHandlers() {
    const _this = this;
    [this.$loginButton,
      this.$signupButton,
      this.$hideButton,
      this.$showButton,
      this.$zoomSelector].forEach(($b) => { return $b.click(_trackClick); });
    this.$hideButton.click(() => { return _this._hideClick(); });
    this.$showButton.click(() => { return _this._showClick(); });
  }

  _hideClick() {
    this._setElementsVisibility(false);
    this.$content.removeClass("large");
    this.$content.addClass("collapsed");
  }

  _showClick() {
    this._setElementsVisibility(true);
    this._resizeLarge();
  }

  _setElementsVisibility(isShow) {
    [this.$alertPanel,
      this.$loginButton,
      this.$signupButton,
      this.$hideButton,
      this.$zoomSelector].forEach(($e) => {
        if ($e) {
          if (isShow) {
            $e.show();
          } else {
            $e.hide();
          }
        }
      });

    if (isShow) {
      this.$showButton.hide();
    } else {
      this.$showButton.show();
    }

    if (isShow) {
      this._initItemButton();
    } else {
      this.$itemButton.hide();
    }
  }

  _initItemButton() {
    if (window.RiseVision.Viewer.ConfigurationFactory.getId()) {
      let title;
      let url = Config.APPS_URL;

      // [AD] only add button for Template Presentations
      // Uncomment code when new Editor shows User friendly message for
      // "Cannot access this Presentation/Schedule"
      const ViewerDataInfo = window.RiseVision.Viewer.Data.ViewerDataInfo;
      if (ViewerDataInfo.isTemplate()) {
        title = `Copy ${ViewerDataInfo.getItemName()}`;
        url += `/editor/workspace/new/?copyOf=${window.RiseVision.Viewer.ConfigurationFactory.getId()}`;

        // TODO: if needed, either remove the logic below or convert it to js
        //      }
        //      else {
        //        title = "Edit " + ViewerDataInfo.getItemName();
        //        if (ConfigurationFactory.isPresentation()) {
        //          url += "/editor/workspace/" + ConfigurationFactory.getId() + "/";
        //        }
        //        else {
        //          url += "/schedules/details/" + ConfigurationFactory.getId();
        //        }
        //        url += "?cid=" + ViewerDataInfo.getItemCompanyId();
        //      }

        this.$itemButton.prop("href", url);
        this.$itemButton.find(".title").html(title);
        this.$itemButton.show();
      }
    }
  }

  show() {
    super.show();
  }

}

function _trackClick(e) {
  _trackEvent("Click", $(e.source).text());
}

function _trackEvent(action, label) {
  AnalyticsFactory.trackEvent("PreviewBar", action, label);
}

let instance;

// singleton
function _getInstance() {
  if (!instance) {
    instance = new ViewerPreviewWidget();
  }

  return instance;
}

ViewerPreviewWidget.show = () => { return _getInstance().show(); };

module.exports = ViewerPreviewWidget;
