const PlaylistItemInfo = {
  TYPE_PLAYLIST: "playlist",
  TYPE_URL: "url",
  TYPE_WIDGET: "widget",
  TYPE_PRESENTATION: "presentation",
  TYPE_HTML_TEMPLATE: "HTML Template",
  TYPE_GADGET: "gadget",
  TYPE_TEXT: "text",
  TYPE_IMAGE: "image",
  TYPE_VIDEO: "video",
  TYPE_HTML: "HTML",

  ERROR_STATUS: -1,
  UNLOADED_STATUS: 1,
  LOADING_STATUS: 2,
  ADDED_STATUS: 3,
  READY_STATUS: 4,
  ALL_READY_STATUS: 5,

  UNLOAD_TIME: 1 * 60 * 1000,

  OVERRIDE_REPLACE: "override_replace",
  SCREEN_SHARING: "screen_sharing"
};

module.exports = PlaylistItemInfo;
