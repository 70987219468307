const PopupPanel = require("./PopupPanel");
const NotificationType = require("../Info/NotificationType");

class ViewerNotificationsPanel extends PopupPanel {

  constructor() {
    super({
      autoHide: true,
      containerId: "madero-notification-div",
    });
  }

  showError(notification, fullscreen, time) {
    let delay;
    let icon;

    // get time and delay values if they exist
    if (notification instanceof NotificationType) {
      time = time ? time : notification.getTime();
      delay = notification.getDelay();
      fullscreen = notification.fullscreen;
      icon = notification.getIcon();
    }

    const _this = this;

    // clear previous interval before creating a new one
    if (_this.timer) {
      clearInterval(_this.timer);
      _this.timer = undefined;
    }

    if (time && delay) {
      let count = time;

      _this.timer = setInterval(() => {
        count -= 1;
        if (count === 0) {
          clearInterval(_this.timer);
          _this.hide();
        } else {
          _this._renderErrorMsg(_ensureStr(notification, count), fullscreen, icon);
        }
      }, delay);
    } else {
      _this._renderErrorMsg(_ensureStr(notification), fullscreen, icon);
    }
  }

  hide() {
    clearInterval(this.timer);
    this.timer = undefined;
    super.hide();
  }

  _renderErrorMsg(msg, fullscreen, icon) {
    const className = icon ? "logo" : "";
    icon = icon || "./images/alert-triangle.svg";
    if (!fullscreen) {
      super.size(800, 400);
      this.$content.removeClass("fullscreen");
    } else {
      this.$content.addClass("fullscreen");
    }
    super.show(
      `<div class="u_align-middle">
        <div class="u_margin-bottom-md">
          <img style="width: 124px;" class="${className}" src="${icon}" />
        </div>
        <span>${msg}</span>
      </div>`
    );
  }
}

function _ensureStr(notification, count) {
  let msg;
  if (typeof notification === "string") {
    msg = notification;
  } else {
    msg = notification.getMessage(count);
  }

  return msg;
}

ViewerNotificationsPanel.show = (notification) => { return _getInstance().show(notification); };
ViewerNotificationsPanel.hide = () => { return _getInstance().hide(); };
ViewerNotificationsPanel.showError = (notification, fullscreen, time) => { return _getInstance().showError(notification, fullscreen, time); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new ViewerNotificationsPanel();
  }

  return instance;
}

// Clear instance for Unit Tests
ViewerNotificationsPanel._clearInstance = () => {
  instance = null;
};

module.exports = ViewerNotificationsPanel;
