"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetToken = exports.getToken = void 0;

var _config = require("./config.js");

var _retryFn = require("./retry-fn.js");

var refreshDate = 0;
var tokenPromise = null;

var getToken = function getToken() {
  if (new Date() - refreshDate > (60 * 60 - 20) * 1000) {
    tokenPromise = null;
  }

  return tokenPromise = tokenPromise || (0, _retryFn.retry)(function () {
    return fetch(_config.GRANT_URL, {
      method: "POST"
    });
  }).then(function (resp) {
    return resp.json();
  }).then(function (json) {
    refreshDate = new Date() || refreshDate;
    return json.access_token;
  })["catch"](console.error);
};

exports.getToken = getToken;

var resetToken = function resetToken() {
  refreshDate = 0;
};

exports.resetToken = resetToken;