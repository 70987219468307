const DisplayRegisterBaseWidget = require("./DisplayRegisterBaseWidget");
const ConfigurationFactory = require("../../ConfigurationFactory");
const DisplayRegistrationController = require("../../Data/DisplayRegistrationController");
const RisePlayerController = require("../../Player/RisePlayerController");

class EnterClaimIdWidget extends DisplayRegisterBaseWidget {
  constructor() {
    super({
      containerId: "enter-claim-id-div",
    });

    this.$claimIdText = this.$content.find(".claim-id-text");
    this.$displayNameText = this.$content.find(".display-name-text");
    this.$errorLabel = this.$content.find(".error-label");
    this.$applyButton = this.$content.find(".apply-button");
    this.$cancelButton = this.$content.find(".cancel-button");
    this.$helpButton = this.$content.find(".help-button");

    this._initHandlers();
  }

  _initHandlers() {
    const _this = this;

    this.$applyButton.click(() => {
      _this.stopCountdownTimer();
      _this._registerDisplayId();
    });

    this.$cancelButton.click(() => {
      _this.stopCountdownTimer();
      _this.hide();
    });
    this.$helpButton.click(() => {
      _this.stopCountdownTimer();
      window.RiseVision.Viewer.UI.DisplayRegister.HelpFrameWidget.show();
    });
  }

  _registerDisplayId() {
    const _this = this;
    _this.newClaimId = _this.$claimIdText.val().trim();
    if (!_this.newClaimId) {
      _this.$errorLabel.show();
    } else {
      _this.$applyButton.prop("disabled", true);
        // Call CORE to validate display ID
      const displayName = _this.$displayNameText.val().trim();
      DisplayRegistrationController.registerDisplay(
          _this.newClaimId, displayName, (errorCode, errorMessage, newDisplayId) => {
            _this._resetUI();
            const isValid = errorCode === 0;
            if (!isValid) {
              _this.$errorLabel.html(`${_this.MESSAGE_NOT_VERIFIED}. ${errorMessage}`);
              _this.$errorLabel.show();
            } else {
              RisePlayerController.saveAndRestart(newDisplayId, _this.newClaimId);
            }
          });
    }
  }

  _resetUI() {
    this.$errorLabel.hide();
    this.$errorLabel.html(this.MESSAGE_NOT_VERIFIED);
    this.$applyButton.prop("disabled", false);
  }

  show(hasParent) {
    this._resetUI();
    super.show(hasParent);
    this.$claimIdText.val(ConfigurationFactory.getClaimId());
  }
}

EnterClaimIdWidget.show = () => { return _getInstance().show(); };

let instance;

function _getInstance() {
  if (!instance) {
    instance = new EnterClaimIdWidget();
  }

  return instance;
}

module.exports = EnterClaimIdWidget;
