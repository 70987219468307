const ConfigurationFactory = require("../ConfigurationFactory");
const Utils = require("../Utils");
const PlaylistItemInfo = require("../Info/PlaylistItemInfo");
const Logger = require("../Logger");

const factory = {};

const AUTH_KEY_PARAM = "CompanyAuthKey=";
const DISPLAY_ADDRESS_PARAM = "DisplayAddress=";
const DEMO_PRESENTATION_IDS = [
  "4b58a2ae-b87e-4eff-bcee-260cfc621604", // core test
  "2b95b77e-839c-4674-b020-e2198df49061"  // core prod
];

const sharedScheduleIncompatibleLayout = new RegExp([
  "rise-twitter-widget",
  "widget-financial",
  "marketwall",
  "contentfinancial2",
  "Gadget-Financial",
  "\"type\": \"video\",",
  "t\\\\\"type\\\\\": \\\\\"video\\\\\","
].join("|"), "g");

let viewerData;

let _observers = [];

// ---------------- ViewerDataParser functionality ---------------//

factory.updateViewerData = (newViewerData) => {
  let result = false;
  if (newViewerData && (!ConfigurationFactory.isDisplay() || newViewerData.signature)) {
    if (!viewerData ||
      newViewerData.signature !== viewerData.signature ||
      checkScreenSharingChanged(newViewerData)) {
      // New Viewer data or a content update
      // or Screen Sharing flag toggled
      viewerData = newViewerData;

      _rewriteS3Urls();

      if (ConfigurationFactory.isDisplay() && !location.protocol.toUpperCase().startsWith("HTTPS")) {
        _rewriteWidgetUrlProtocols();
      }

      result = true;
    } else {
      // Not a content update, refresh the display object
      viewerData.display = newViewerData.display;
    }

    _notifyObservers();
  }

  return result;
};

factory.getCompanyData = () => {
  return (viewerData && viewerData.company) || {};
};

factory.getDisplayData = () => {
  return (viewerData && viewerData.display) || {};
};

factory.isScreenSharingEnabled = () => {
  return !!_getScreenSharing().screenSharingEnabled;
};

factory.getScreenSharingMode = () => {
  if (_getScreenSharing().screenSharingMode) {
    return _getScreenSharing().screenSharingMode;
  }
  return "";
};

factory.getScreenSharingPin = () => {
  return _getScreenSharing().screenSharingPinRequired && _getScreenSharing().screenSharingPin ? _getScreenSharing().screenSharingPin : "";
};

factory.getDisplayName = () => {
  if (viewerData && viewerData.display && viewerData.display.displayName) {
    return viewerData.display.displayName;
  }
  return "";
};

factory.getDisplayAddress = () => {
  if (Utils.getFromQueryString(DISPLAY_ADDRESS_PARAM)) {
    return Utils.getFromQueryString(DISPLAY_ADDRESS_PARAM);
  }
  else if (viewerData.display && viewerData.display.displayAddress) {
    return JSON.stringify(viewerData.display.displayAddress);
  }
  return "";
};

factory.getAuthKey = () => {
  if (Utils.getFromQueryString(AUTH_KEY_PARAM)) {
    return Utils.getFromQueryString(AUTH_KEY_PARAM);
  }
  else if (viewerData.display && viewerData.display.authKey) {
    return viewerData.display.authKey;
  }

  return "";
};

// --------- ViewerDataController functionality -----------//
factory.getPollInterval = () => {
  return viewerData &&
viewerData.connection &&
viewerData.connection.pollInterval ?
viewerData.connection.pollInterval : 60;
};

factory.getPingInterval = () => {
  return viewerData &&
viewerData.connection &&
viewerData.connection.pingInterval ?
viewerData.connection.pingInterval : 30;
};

factory.hasData = () => {
  return viewerData && viewerData.content ? true : false;
};

factory.getSig = () => {
  return viewerData ? viewerData.signature : "";
};

factory.getEmbedItemData = (id, parentId) => {
// first look for the presentation
  if (id && viewerData && viewerData.content && viewerData.content.presentations) {
    for (let i = 0; i < viewerData.content.presentations.length; i += 1) {
      if (id === viewerData.content.presentations[i].id) {
// Return viewerData without the Schedule & Parent Presentation
        return _getEmbedItemData(parentId);
      }
    }
  }

  return null;
};

let _rewriteWidgetUrlProtocols = () => {
  const content = viewerData.content;

  if (!content || !content.presentations) {
    return;
  }

  Logger.logDebug("rewriting https protocol for widgets to http");

  const search = [
    RegExp("https://widgets.risevision.com/widget-", "g"),
    RegExp("https://s3.amazonaws.com/widget-", "g")
  ];

  const replace = [
    "http://widgets.risevision.com/widget-",
    "http://s3.amazonaws.com/widget-"
  ];

  search.forEach((regex, i) => {
    content.presentations.forEach(pres=>{
      if (!pres.layout) {return;}

      pres.layout = pres.layout.replace(regex, replace[i]);
    });
  });
}

let _rewriteS3Urls = () => {
  const content = viewerData.content;

  if (!content || !content.presentations) {
    return;
  }

  Logger.logDebug("rewriting s3 urls");

  const search = [
    RegExp("https?://s3.amazonaws.com/widget-", "g"),
    RegExp("https?://data-feed.digichief.com/risevision/weather/WeatherWidget.html", "gi"),
    RegExp("https?://account.testinseconds.com/WeatherWidget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/TextMarquee/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/TrafficMapWidget/widget.html", "gi"),
    RegExp("http://www.scottsdigitalsignage.com/widget/youtube-widget/demo/index.html", "gi"),
    RegExp("https://data-feed.digichief.com/risevision/NewsRadar/NewsRadarWidget.html", "gi"),
    RegExp("http://data-feed.digichief.com/risevision/News/NewsWidget.html", "gi"),
    RegExp("https://rep.smartplayds.com/plugin/facebook-widget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/CountUpWidget/widget.html", "gi"),
    RegExp("https://account.testinseconds.com/CountdownWidget/widget.html", "gi"),
    RegExp("http://data-feed.digichief.com/risevision/Sports/SportsWidget.html", "gi"),
    RegExp("http://scottsdigitalsignage.com/widget/vimeo-widget/demo/index.html", "gi")
  ];

  const replace = [
    "https://widgets.risevision.com/widget-",
    "https://widgets.risevision.com/widget-digichief-weather/WeatherWidget.html",
    "https://widgets.risevision.com/widget-computer-aid-weather/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-marquee/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-traffic/widget.html",
    "https://widgets.risevision.com/widget-youtube/demo/index.html",
    "https://widgets.risevision.com/widget-newsradar/NewsRadarWidget.html",
    "https://widgets.risevision.com/widget-news/NewsWidget.html",
    "https://widgets.risevision.com/widget-facebook/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-count-up/widget.html",
    "https://widgets.risevision.com/widget-computer-aid-count-down/widget.html",
    "https://widgets.risevision.com/widget-sports/SportsWidget.html",
    "https://widgets.risevision.com/widget-vimeo/demo/index.html"
  ];

  search.forEach((regex, i) => {
    content.presentations.forEach(pres=>{
      if (!pres.layout) {return;}

      pres.layout = pres.layout.replace(regex, replace[i]);
    });
  });
};

let _getEmbedItemData = (id) => {
  try {
// make a copy of the object
    const newObject = jQuery.extend(true, {}, viewerData);
    const content = newObject.content || newObject;

    if (content !== null) {
      delete content.schedule;
      delete content.schedules;

      if (content.presentations) {
        for (let i = 0; i < content.presentations.length; i += 1) {
          if (content.presentations[i].id === id) {
            content.presentations.splice(i, 1);
          }
        }
      }
    }

    return newObject;
  }
  catch (err) {
    Logger.logException("ViewrDataInfo.getEmbedItemData", err);
    return {};
  }
};

// --------------- ViewerDataController JAVA functionality --------//

let itemName, itemId, itemCompanyId;
let template;

const _populateConstants = (item) => {
  itemName = item.name;
  itemId = item.id;
  itemCompanyId = item.companyId;
};

const _getDummySchedule = () => {
  const schedule = {};
  const content = viewerData.content;

  schedule.id = ConfigurationFactory.getScheduleId() || ConfigurationFactory.SCHEDULE;
  schedule.timeDefined = false;
  schedule.items = [];

  if (content && content.presentations && content.presentations.length > 0) {
    if (DEMO_PRESENTATION_IDS.includes(content.presentations[0].id)) {
      schedule.isNotScheduled = true;
    } else if (content.presentations[0].name === "Missing License Presentation") {
      schedule.isMissingLicense = true;
    } else {

      let index = 0;
      for (let i = 0; i < content.presentations.length; i += 1) {
        if (content.presentations[i] != null && content.presentations[i].id === ConfigurationFactory.getId()) {
          index = i;

          break;
        }
      }

      const item = {};
      item.objectReference = content.presentations[index].id;
      item.type = PlaylistItemInfo.TYPE_PRESENTATION;
      item.presentationType = content.presentations[index].presentationType;
      item.duration = 10;
      item.playUntilDone = true;
      item.timeDefined = false;
      schedule.items.push(item);

      _populateConstants(content.presentations[index]);
      template = item.template;
    }
  } else {
    schedule.isNotAssigned = true;
  }

  return schedule;
};

const checkScreenSharingChanged = (data) => {
  return JSON.stringify(_getScreenSharing(viewerData)) !== JSON.stringify(_getScreenSharing(data));
};

const _getScreenSharing = (data = viewerData) => {
  if (data && data.display && data.display.screenSharing) {
    return data.display.screenSharing;
  }

  return {};
};

const _getScreenSharingSchedule = () => {
  const schedule = {
    id: "SCREEN_SHARING",
    scheduleType: PlaylistItemInfo.SCREEN_SHARING,
    timeDefined: false
  };

  return schedule;
};

factory.getSchedules = () => {
  const content = viewerData.content;
  const schedules = [];

  if (!content) {
    return null;
  }

  if (content.schedule) {
    _populateConstants(content.schedule);

    schedules.push(content.schedule);
  }

  if (content.schedules && content.schedules.length) {
    if (!content.schedule) {
      _populateConstants(content.schedules[0]);
    }

    schedules.push.apply(schedules, content.schedules);
  }

  if (!schedules.length && content.presentations) {
    const dummySchedule = _getDummySchedule();

    if (dummySchedule) {
      schedules.push(dummySchedule);
    }
  }

  if (factory.isScreenSharingEnabled(viewerData)) {
    schedules.unshift(_getScreenSharingSchedule());
  }

  return schedules;
};

factory.getPresentation = (id) => {
  const content = viewerData.content;

  if (content && content.presentations) {
    for (let j = 0; j < content.presentations.length; j += 1) {
      if (content.presentations[j].id === id) {
        const presentation = jQuery.extend(true, {}, content.presentations[j]);

        return presentation;
      }
    }
  }
  return null;
};

factory.getSharedScheduleIncompatible = (item) => {
  if (!item) return [];
  if (!item.objectReference) return [];

  const pres = factory.getPresentation(item.objectReference);
  if (!pres || !pres.layout) return [];

  return pres.layout.match(sharedScheduleIncompatibleLayout) || [];
};

factory.getItemName = () => { return itemName; };

factory.getItemId = () => { return itemId; };

factory.getItemCompanyId = () => { return itemCompanyId; };

factory.isTemplate = () => { return template; };


factory.addObserver = (observerCallback) => {
  return _observers.push(observerCallback);
};

factory.removeObserver = (observerCallback) => {
  const index = _observers.indexOf(observerCallback);
  if (index !== -1) {
    _observers.splice(index, 1);
  }
};

function _notifyObservers() {
  for (let i = 0; i < _observers.length; i++) {
    _observers[i]();
  }
}

module.exports = factory;
