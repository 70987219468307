const ConfigurationFactory = require("../ConfigurationFactory");
const EntryPoint = require("../EntryPoint");
const ViewerDataInfo = require("../Data/ViewerDataInfo");

const factory = {};

const PARAM_DISPLAY_ID = "displayId";
const PARAM_COMPANY_ID = "companyId";
const PARAM_DISPLAY_ADDRESS = "displayAddress";
const PARAM_COMPANY_AUTH_KEY = "companyAuthKey";
const PARAM_GADGET_ADDITIONAL_PARAMS = "additionalParams";

const gadgetMap = {};

factory.registerGadget = (htmlName, gadgetController) => {
  gadgetMap[htmlName] = gadgetController;
};

const _reportGadgetLoadEvent = (presFrame, id, gadgetXml) => {
  if (id && gadgetMap[id] && gadgetMap[id].setLoad) {
    gadgetMap[id].setLoad(presFrame, gadgetXml);

    return 1;
  }

  return 0;
};

const _reportReadyEvent = (presFrame, id, canPlay, canStop, canPause, canReportReady, canReportDone) => {
  if (id && gadgetMap[id]) {
    gadgetMap[id].setReady(presFrame, canPlay, canStop, canPause, canReportReady, canReportDone);

    return 1;
  }

  return 0;
};

const _reportErrorEvent = (presFrame, id, reason) => {
  if (id && gadgetMap[id]) {
    gadgetMap[id].setError(presFrame, reason);

    return 1;
  }

  return 0;
};

const _reportDoneEvent = (presFrame, id) => {
  if (id && gadgetMap[id]) {
    gadgetMap[id].setDone();

    return 1;
  }

  return 0;
};

const _getParam = (param, id) => {
  if (param) {
    if (param.toLowerCase() === PARAM_DISPLAY_ADDRESS.toLowerCase()) {
      return ViewerDataInfo.getDisplayAddress();
    }
    else if (param.toLowerCase() === PARAM_DISPLAY_ID.toLowerCase()) {
      return ConfigurationFactory.getDisplayId();
    }
    else if (param.toLowerCase() === PARAM_COMPANY_ID.toLowerCase()) {
      return ViewerDataInfo.getItemCompanyId();
    }
    else if (param.toLowerCase() === PARAM_COMPANY_AUTH_KEY.toLowerCase()) {
      return ViewerDataInfo.getAuthKey();
    }
    else if (param.toLowerCase() === PARAM_GADGET_ADDITIONAL_PARAMS.toLowerCase()) {
      if (id && gadgetMap[id] && gadgetMap[id].getItem()) {
        return gadgetMap[id].getItem().additionalParams;
      }
      return null;
    }
  }
  // marginal case; return nothing
  return "";
};

const _requestPlaceholderIFrameIds = (presFrame, id) => {
  let result = "";
  if (id && presFrame && presFrame.indexOf("iFrame_") !== -1) {
    const presFrameName = presFrame.substring("iFrame_".length, presFrame.length) + "_" + id;

    for (const gadgetName in gadgetMap) {
      if (gadgetName.substring(0, gadgetName.lastIndexOf("_")) === presFrameName) {
        result += "if_" + gadgetName + ",";
      }
    }

    if (result) {
      result = result.substring(0, result.length() - 1);
    }
  }

  return result;
};

window.reportGadgetLoadEvent = _reportGadgetLoadEvent;

window.reportReadyEvent = _reportReadyEvent;
window.reportErrorEvent = _reportErrorEvent;
window.reportDoneEvent = _reportDoneEvent;

window.embedPlay = EntryPoint.embedPlay;
window.embedPause = EntryPoint.embedPause;
window.embedStop = EntryPoint.embedStop;

window.getParam = _getParam;

window.requestPlaceholderIFrameIds = _requestPlaceholderIFrameIds;

module.exports = factory;
